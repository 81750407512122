import { Router, UrlTree } from '@angular/router';
import {
  AccreditationSelectionType,
  AllApplicationTypes,
  ApplicationSelectionType,
  ApplicationSort,
  AssetFinanceProductType,
  UserType
} from '@portal-workspace/grow-shared-library';

export const navigationUrlForLgRebateCalculator = () => {
  // return  router.createUrlTree(['/s', 'lg-rebate-calculator']);
  return  ['/s', 'lg-rebate-calculator'];
}

export const navigationUrlForAssetFinanceCalculator = () => {
  // return  router.createUrlTree(['/s', 'asset-finance-calculator']);
  return  ['/s', 'asset-finance-calculator'];
}

export const navigationUrlForBusinessLoansCalculator = () => {
  // return   router.createUrlTree(['/s', 'business-term-loan-calculator']);
  return   ['/s', 'business-term-loan-calculator'];
}

export const navigationUrlForOverdraftCalculator = () => {
  // return router.createUrlTree(['/s', 'business-overdraft-calculator']);
  return ['/s', 'business-overdraft-calculator'];
}

export const navigationUrlForAssetFinance = (
  router: Router,
  selectedAssetProductType: AssetFinanceProductType,
  isSwitchToEasyDoc: boolean,
  applicationId?: number,
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort,
  },
): UrlTree => {
  // return (applicationId ? ['/s', 'asset-finance', applicationId] : ['/s', 'asset-finance']);
  const url = applicationId ? ['/s', 'asset-finance', applicationId] : ['/s', 'asset-finance'];
  const qp = {
    ...(queryParams ?? {}),
    productType: selectedAssetProductType,
    isSwitchToEasyDoc,
  };
  // return { url, queryParams };
  return router.createUrlTree(url, {queryParams: qp});
}

export const navigationUrlForAssetProductSelector = (applicationId?: number) => {
  return (applicationId ? ['/s', 'asset-finance-product-selector', applicationId] : ['/s', 'asset-finance-product-selector']);
}

export const navigationUrlForAssetProductSelectorWithQueryParams = (
  router: Router,
  applicationId?: number,
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort,
  }) => {
  return router.createUrlTree(applicationId ? ['/s', 'asset-finance-product-selector', applicationId] : ['/s', 'asset-finance-product-selector'], {queryParams});
}

export const navigationUrlForCorporateLoan = (applicationId?: number) => {
  // return router.createUrlTree(applicationId ? ['/s', 'corporate-loan', applicationId] : ['/s', 'corporate-loan'])
  return (applicationId ? ['/s', 'corporate-loan', applicationId] : ['/s', 'corporate-loan']);
}

export const navigationUrlForCorporateLoanWithQueryParams = (
  router: Router,
  applicationId?: number,
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort,
  }
) => {
  return router.createUrlTree(applicationId ? ['/s', 'corporate-loan', applicationId] : ['/s', 'corporate-loan'], {queryParams});
}


export const navigationUrlForBusinessFinance = (applicationId?: number)  => {
  // return   router.createUrlTree(applicationId ? ['/s', 'business-term-loan', applicationId] : ['/s', 'business-term-loan']);
  return   (applicationId ? ['/s', 'business-term-loan', applicationId] : ['/s', 'business-term-loan']);
}

export const navigationUrlForBusinessFinanceWithQueryParams = (
  router: Router,
  applicationId?: number,
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort
  }
) => {
  return router.createUrlTree(applicationId ? ['/s', 'business-term-loan', applicationId] : ['/s', 'business-term-loan'], {queryParams});
}

export const navigationUrlForBusinessOverdraft = (applicationId?: number)  => {
  // return router.createUrlTree( applicationId ? ['/s', 'business-overdraft', applicationId] : ['/s', 'business-overdraft']);
  return ( applicationId ? ['/s', 'business-overdraft', applicationId] : ['/s', 'business-overdraft']);
}

export const navigationUrlForBusinessOverdraftWithQueryParams = (
  router: Router,
  applicationId?: number,
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort
  }
) => {
  return router.createUrlTree(applicationId ? ['/s', 'business-overdraft', applicationId] : ['/s', 'business-overdraft'], {queryParams});
}

export const navigationUrlForInsurancePremium = (applicationId?: number)  => {
  // return router.createUrlTree( applicationId ? ['/s', 'insurance-premium', applicationId] : ['/s', 'insurance-premium']);
  return ( applicationId ? ['/s', 'insurance-premium', applicationId] : ['/s', 'insurance-premium']);
}

export const navigationUrlForInsurancePremiumWithQueryParams = (
  router: Router,
  applicationId?: number,
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort
  }
) => {
  return router.createUrlTree( applicationId ? ['/s', 'insurance-premium', applicationId] : ['/s', 'insurance-premium'], {queryParams});
}

export const navigationUrlForConsumerAssetFinance = (applicationId?: number)  => {
  // return router.createUrlTree( applicationId ? ['/s', 'consumer-asset-finance', applicationId] : ['/s', 'consumer-asset-finance']);
  return ( applicationId ? ['/s', 'consumer-asset-finance', applicationId] : ['/s', 'consumer-asset-finance']);
}

export const navigationUrlForConsumerAssetFinanceWithQueryParams = (
  router: Router,
  applicationId?: number,
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort
  }
) => {
  return router.createUrlTree( applicationId ? ['/s', 'consumer-asset-finance', applicationId] : ['/s', 'consumer-asset-finance'], {queryParams});
}

export const navigationUrlForConsumerFinance = (applicationId?: number)  => {
  // return router.createUrlTree( applicationId ? ['/s', 'consumer-finance', applicationId] : ['/s', 'consumer-finance']);
  return ( applicationId ? ['/s', 'consumer-finance', applicationId] : ['/s', 'consumer-finance']);
}

export const navigationUrlForConsumerFinanceWithQueryParams = (
  router: Router,
  applicationId?: number,
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort
  }
) => {
  return router.createUrlTree( applicationId ? ['/s', 'consumer-finance', applicationId] : ['/s', 'consumer-finance'], {queryParams});
}


export const navigationUrlForCommercialFinance = (applicationId?: number) => {
  // return router.createUrlTree( applicationId ? ['/s', 'commercial-finance', applicationId] : ['/s', 'commercial-finance']);
  return ( applicationId ? ['/s', 'commercial-finance', applicationId] : ['/s', 'commercial-finance']);
}

export const navigationUrlForCommercialFinanceWithQueryParams = (
  router: Router,
  applicationId?: number,
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort
  }
) => {
  return router.createUrlTree( applicationId ? ['/s', 'commercial-finance', applicationId] : ['/s', 'commercial-finance'], {queryParams});
}


export const navigationUrlForNotificationsManagement = ()  => {
  // return router.createUrlTree( ['/s', 'notifications-management']);
  return ( ['/s', 'notifications-management']);
}

export const navigationUrlForAuditLogs = () => {
  // return router.createUrlTree( ['/s', 'audit-logs']);
  return ( ['/s', 'audit-logs']);
}

export const navigationUrlForRateCardDetails = (rateCardId: number,queryParams?: { [key: string]: string | string[] | undefined })  => {
  // return router.createUrlTree( ['/s', 'rate-card', rateCardId],{ queryParams });
  return ( ['/s', 'rate-card', rateCardId]);
}

export const navigationUrlForAddRatecard = () => {
  // return router.createUrlTree( ['/s', 'rate-card']);
  return ( ['/s', 'rate-card']);
}

export const navigationUrlForApplication = (
  applicationId: string,
  innerTabs: ApplicationSelectionType = 'app',
)  => {
  // return  router.createUrlTree(['/s', 'application', applicationId , innerTabs], {queryParams});
  return  (['/s', 'application', applicationId , innerTabs]);
}

export const navigationUrlForApplicationWithQueryParams = (
  router: Router,
  applicationId: string,
  innerTabs: ApplicationSelectionType = 'app',
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort,
  }
) => {
  return router.createUrlTree(['/s', 'application', applicationId, innerTabs], {queryParams});
}

export const navigationUrlForApplications = (
)  => {
  // return  router.createUrlTree(['/s', 'applications'],{queryParams});
  return  (['/s', 'applications']);
}

export const navigationUrlForApplicationsWithQueryParams = (
  router: Router,
  queryParams?: {
    reload?: boolean,
    limit?: number,
    offset?: number,
    search?: string,
    applicationType?: AllApplicationTypes,
    sorts?: ApplicationSort,
  }
) => {
  return  router.createUrlTree(['/s', 'applications'],{queryParams});
}

export const navigationUrlForAllApplication= ()  => {
  // return router.createUrlTree( ['/s', 'all-applications']);
  return ( ['/s', 'all-applications']);
}


export const navigationUrlForCustomers = ()  => {
  // return  router.createUrlTree(['/s', 'customers']);
  return  (['/s', 'customers']);
}

export const navigationUrlForCustomerDetails = (customerId: number) => {
  // return router.createUrlTree( ['/s', 'customer', customerId]);
  return ( ['/s', 'customer', customerId]);
}

export const navigationUrlForPismoDisplayTransactions = (customerId: number, accountId: number)  => {
  // return router.createUrlTree( ['/s', 'customer', customerId, accountId]);
  return ( ['/s', 'customer', customerId, accountId]);
}

export const navigationUrlForPismoStatementDetails = (userId: number, accountId: number, statementId: number)  => {
  // return router.createUrlTree( ['/s', 'pismo-statement-details', userId, accountId, statementId]);
  return ( ['/s', 'pismo-statement-details', userId, accountId, statementId]);
}

export const navigationUrlForUnsingedInvoices = () => {
  // return router.createUrlTree( ['/s', 'unsigned-invoices']);
  return ( ['/s', 'unsigned-invoices']);
}

export const navigationUrlForManualPayment = ()  => {
  // return router.createUrlTree( ['/s', 'manual-payment']);
  return ( ['/s', 'manual-payment']);
}

export const navigationUrlForUsers = ()  => {
  // return router.createUrlTree( ['/s', 'users']);
  return ( ['/s', 'users']);
}

export const navigationUrlForUserDetail = (userId: number, type: UserType = 'broker-user')  => {
  // return router.createUrlTree( type === 'customer-user' ? ['/s', 'user', userId, 'customer-user'] : ['/s', 'user', userId, 'broker-user']);
  return ( type === 'customer-user' ? ['/s', 'user', userId, 'customer-user'] : ['/s', 'user', userId, 'broker-user']);
}

export const navigationUrlForPendingApprovals = ()  => {
  // return router.createUrlTree( ['/s', 'pending-approvals']);
  return ( ['/s', 'pending-approvals']);
}

export const navigationUrlForPromotion = ()  => {
  // return router.createUrlTree( ['/s', 'promotion']);
  return ( ['/s', 'promotion']);
}

export const navigationUrlForPendingApprovalUserDetail = (userId: number)  => {
  // return router.createUrlTree( ['/s', 'pending-approval-user', userId]);
  return ( ['/s', 'pending-approval-user', userId]);
}

export const navigationUrlForAggregators = ()  => {
  // return router.createUrlTree( ['/s', 'aggregators']);
  return ( ['/s', 'aggregators']);
}

export const navigationUrlForAggregatorDetails = (aggregatorId: number)  => {
  // return router.createUrlTree( ['/s', 'aggregator', aggregatorId]);
  return ( ['/s', 'aggregator', aggregatorId]);
}

export const navigationUrlForCompanies= ()  => {
  // return router.createUrlTree( ['/s', 'companies']);
  return ( ['/s', 'companies']);
}

export const navigationUrlForCompanyDetail = (originatorBusinessId: number)  => {
  // return router.createUrlTree( ['/s', 'company', originatorBusinessId]);
  return ( ['/s', 'company', originatorBusinessId]);
}

export const navigationUrlForNotifications = ()  => {
  // return router.createUrlTree( ['/s', 'notifications']);
  return ( ['/s', 'notifications']);
}

export const navigationUrlForRateCards = ()  => {
  // return router.createUrlTree( ['/s', 'rate-cards']);
  return ( ['/s', 'rate-cards']);
}

export const navigationUrlForSetMaintenance = ()  => {
  // return router.createUrlTree( ['/s', 'set-maintenance']);
  return ( ['/s', 'set-maintenance']);
}

export const navigationUrlForTransactions = ()  => {
  // return router.createUrlTree( ['/s', 'transactions']);
  return ( ['/s', 'transactions']);
}

export const navigationUrlForPendingApprovalTransactions = ()  => {
  // return router.createUrlTree( ['/s', 'transactions']);
  return ( ['/s', 'pending-approval-transactions']);
}

export const navigationUrlForOauth = () => {
  // return router.createUrlTree( ['/s', 'oauth']);
  return ( ['/s', 'oauth']);
}

export const navigationUrlForUnverifiedUsers = () => {
  // return router.createUrlTree( ['/s', 'unverified-users']);
  return ( ['/s', 'unverified-users']);
}

export const navigationUrlForNewApplication = ()  => {
  // return router.createUrlTree( ['/s', 'new-application']);
  return ( ['/s', 'new-application']);
}

export const navigationUrlForProfile = () => {
  // return router.createUrlTree( ['/s', 'profile']);
  return ( ['/s', 'profile']);
}

export const navigationUrlForDashboard = ()  => {
  // return router.createUrlTree( ['/s', 'dashboard']);
  return ( ['/s', 'dashboard']);
}

export const navigationUrlForOverdraftProduct = ()  => {
  // return router.createUrlTree( ['/s', 'dashboard']);
  return ( ['/s', 'overdraft-product']);
}

export const navigationUrlForPismoAccounts = ()  => {
  // return router.createUrlTree( ['/s', 'pismo-accounts']);
  return ( ['/s', 'pismo-accounts']);
}

export const navigationUrlForPismoAccount = (pismoAccountNumber: number)  => {
  // return router.createUrlTree( ['/s', 'pismo-account', pismoAccountNumber]);
  return ( ['/s', 'pismo-account', pismoAccountNumber]);
}

export const navigationUrlForFAQ = ()  => {
  // return router.createUrlTree( ['/s', 'faq']);
  return ( ['/s', 'faq']);
}

export const navigationUrlForBankStatementsAnalysis = ()  => {
  // return router.createUrlTree( ['/s', 'bank-statements-analysis']);
  return ( ['/s', 'bank-statements-analysis']);
}

export const navigationUrlForBankStatementsStatus = ()  => {
  // return router.createUrlTree( ['/s', 'bank-statements-status']);
  return ( ['/s', 'bank-statements-status']);
}

export const navigationUrlForApplicationErrorPage = (applicationId: number,queryParams?: { [key: string]: string | string[] | undefined }): string[]  => {
  // return router.createUrlTree( ['/s', 'application-error', applicationId],{queryParams});
  return ( ['/s', 'application-error', String(applicationId)]);
}

export const navigationUrlForOpsErrorPage = (errorCode: 'e-not-operator') => {
  // return router.createUrlTree( ['/op', 'error', errorCode]);
  return ( ['/op', 'error', errorCode]);
}

export const navigationUrlForMaintenance = ()  => {
  // return router.createUrlTree( ['/m', 'maintenance']);
  return ( ['/m', 'maintenance']);
}

// export const navigationUrlForTradeCustomerForceRestPassword = (router: Router)  => {
//   // return router.createUrlTree( ['/m', 'force-reset-password']);
//   return ( ['/m', 'force-reset-password']);
// }

export const navigationUrlForLogin = ()  => {
  // return router.createUrlTree( [`/m`, 'login']);
  return ( [`/m`, 'login']);
}

export const navigationUrlForSignup = ()  => {
  //m return router.createUrlTree( ['/m', 'signup']);
  return ( ['/m', 'signup']);
}

export const navigationUrlForForgotPassword = ()  => {
  // return router.createUrlTree( ['/m', 'forgot-password']);
  return ( ['/m', 'forgot-password']);
}

export const navigationUrlForResetPassword = ()  => {
  // return router.createUrlTree( ['/m', 'reset-password']);
  return ( ['/m', 'reset-password']);
}

export const navigationUrlForAccreditation = ()  => {
  // return router.createUrlTree( ['/m', 'accreditation']);
  return ( ['/m', 'accreditation']);
}

export const navigationUrlFor2FA = ()  => {
  // return router.createUrlTree( [`/m`, '2fa']);
  return ( [`/m`, '2fa']);
}

export const navigationUrlForReset2FA = ()  => {
  // return router.createUrlTree( [`/m`, 'reset-2fa']);
  return ( [`/m`, 'reset-2fa']);
}

export const navigationUrlForReset2FAQrCode = ()  => {
  // return router.createUrlTree( [`/m`,'reset-2fa-qr-code']);
  return ( [`/m`,'reset-2fa-qr-code']);
}
export const navigationUrlForReset2FASmsVerification = ()  => {
  // return router.createUrlTree( [`/m`,'reset-2fa-sms-verification']);
  return ( [`/m`,'reset-2fa-sms-verification']);
}

export const navigationUrlForBsaSettings = ()  => {
  // return router.createUrlTree( ['/s', 'bsa-settings']);
  return (['/s', 'bsa-settings']);
}

export const navigationUrlForSendPrivacyConsent = ()  => {
  // return router.createUrlTree( ['/s', 'bsa-settings']);
  return (['/s', 'send-privacy-consent']);
}

export const navigationUrlForDocuments = ()  => {
  // return router.createUrlTree( ['/s', 'documents']);
  return ( ['/s', 'documents']);
}

export const navigationUrlForDocumentWorklist = ()  => {
  // return router.createUrlTree( ['/s', 'document-worklist']);
  return ( ['/s', 'document-worklist']);
}

export const navigationUrlForCreditWorklist = ()  => {
  // return router.createUrlTree( ['/s', 'credit-worklist']);
  return ( ['/s', 'credit-worklist']);
}

export const navigationUrlForClientServicesWorklist = ()  => {
  // return router.createUrlTree( ['/s', 'client-services-worklist']);
  return ( ['/s', 'client-services-worklist']);
}

export const navigationUrlForSettlementsWorklist = ()  => {
  // return router.createUrlTree( ['/s', 'settlements-worklist']);
  return ( ['/s', 'settlements-worklist']);
}

export const navigationUrlForRedbook = ()  => {
  // return router.createUrlTree( ['/s', 'redbook']);
  return ( ['/s', 'redbook']);
}

export const navigationUrlForBusinessAndOverdraftBorrowingEstimator = () => {
  // return router.createUrlTree(['/s', 'business-and-overdraft-borrowing-estimator']);
  return (['/s', 'business-and-overdraft-borrowing-estimator']);
}

export const navigationUrlForReporting = ()  => {
  // return router.createUrlTree( ['/s', 'reporting']);
  return ( ['/s', 'reporting']);
}

export const navigationUrlForAccreditationListing = () => {
  // return router.createUrlTree( ['/s', 'accreditation-listing']);
  return ( ['/s', 'accreditation-listing']);
}

export const navigationUrlForBanking = () => {
  // return router.createUrlTree( ['/s', 'banking']);
  return ( ['/s', 'banking']);
}

export const navigationUrlForAccreditationTabbing = (accreditationId: string)   => {
  // return router.createUrlTree( ['/s', 'accreditation', accreditationId , innerTabs]);
  return ( ['/s', 'accreditation', accreditationId]);
}

export const navigationUrlForOverdraftCustomerDashboard = () => {
  // return router.createUrlTree( ['/o', 'dashboard']);
  return ( ['/o', 'dashboard']);
}

export const navigationUrlForOverdraftCustomerNotifications = ()  => {
  // return router.createUrlTree( ['/o', 'notifications']);
  return ( ['/o', 'notifications']);
}

export const navigationUrlForOverdraftCustomerProfile = ()  => {
  // return router.createUrlTree( ['/o', 'profile']);
  return ( ['/o', 'profile']);
}

export const navigationUrlForOverdraftCustomerSpecialOffers = ()  => {
  // return router.createUrlTree( ['/o', 'special-offers']);
  return ( ['/o', 'special-offers']);
}

export const navigationUrlForUnactivatedAccount = ()  => {
  // return router.createUrlTree( ['/m', 'unactivated']);
  return ( ['/m', 'unactivated']);
}

export const navigationUrlForLockedUser = ()  => {
  // return router.createUrlTree( ['/m', 'lockeduser']);
  return ( ['/m', 'lockeduser']);
}

export const navigationUrlForUnverifiedAccount = ()  => {
  // return router.createUrlTree( ['/m', 'unverified']);
  return ( ['/m', 'unverified']);
}

export const navigationUrlForSolarPage = ()  => {
  // return router.createUrlTree( ['/d', 'Solar']);
  return ( ['/d', 'Solar']);
}

export const navigationUrlForPortalRoot = ()  => {
  // return router.createUrlTree( ['/']);
  return ( ['/']);
}

export const navigationUrlForCardOperatorDashboard = ()  => {
  // return router.createUrlTree( ['/op', 'dashboard']);
  return ( ['/op', 'dashboard']);
}

export const navigationUrlForDirectSale = ()  => {
  // return router.createUrlTree( ['/d', 'direct-sale']);
  return ( ['/d', 'direct-sale']);
}

export const navigationUrlForDirectSaleBusinessOverdraft = ()  => {
  // return router.createUrlTree( ['/d', 'direct-sale', 'business-overdraft']);
  return ( ['/d', 'direct-sale', 'business-overdraft']);
}

export const navigationUrlForDirectSaleBusinessLoans = ()  => {
  // return router.createUrlTree( ['/d', 'direct-sale', 'business-term-loan']);
  return ( ['/d', 'direct-sale', 'business-term-loan']);
}

export const navigationUrlForDirectSaleAssetFinance = ()  => {
  // return router.createUrlTree( ['/d', 'direct-sale', 'asset-finance']);
  return ( ['/d', 'direct-sale', 'asset-finance']);
}

export const navigationUrlForDirectSaleApplicationErrorPage = (applicationId: number,queryParams?: { [key: string]: string | string[] | undefined }): string[]  => {
  // return router.createUrlTree( ['/d', 'application-error', applicationId],{queryParams});
  return ( ['/d', 'application-error', String(applicationId)]);
}

export const navigationUrlForWhitelabelSettings = () => {
  return (['/s', 'whitelabel-settings']);
}

export const navigationUrlForAddWhitelabelSettingDetails = () => {
  return (['/s', 'whitelabel-settings-details']);
}

export const navigationUrlForEditWhitelabelSettingDetails = (originatorBusinessId: number) => {
  return (['/s', 'whitelabel-settings-details', originatorBusinessId]);
}
