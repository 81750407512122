import { ContactValue } from '../domain/component/select-contact-component.domain';

declare var require: any;

import {
  AccessLevel,
  Base64File,
  Customer,
  DocumentTag,
  User,
  AzureFileTagable,
  UserandPriviledges, PaymentPeriodType, NotNullable
} from '../domain/types.domain';
import {
  constants,
  INTERNAL_ACCESS_LEVEL,
  INTERNAL_EMAIL_DOMAINS,
  TIER1_ASSET_CATS,
  TIER2_ASSET_CATS,
  TIER3_ASSET_CATS,
  TIER4_ASSET_CATS,
} from '../const';
import {
  ApplicationApplicant, ApplicationApplicantVal,
} from '../domain/types.domain';
import { Moment } from 'moment';
import {
  Application, AssetFinanceProductType, ConsumerDscrCalculatorInput, ConsumerDscrCalculatorValue,
  DscrCalculatorInput,
  DscrCalculatorValue,
  Individual,
  LvrCalculatorInput,
  LvrCalculatorValue,
  RatecardDetails
} from '../domain/application-service.domain';
import numeral from 'numeral';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { DriverLicenceDetails, DriverLicenceDetailsError, NextAccreditationNumber } from '../domain/accreditation-service.domain';
import { AssetTypeRateValue } from '../domain/component/asset-type-rate-component.domain';
import { PpsrAsset, PpsrData, PpsrDocument } from '../domain/ppsr-service.domain';
import { IncomeSelectionValue } from '../domain/component/income-component.domain';
import { DeviceType, PismoAccountingCategory, PismoAccountingRecord, PismoCardReIssueReason, PismoFlexControlBody, PismoFlexControlResponse, PismoPendingTransaction, PismoPhoneBody, PismoReportAccountsBalances, PismoReportDailyBalances, PismoTransaction, PismoTransactionProcessingCode, PismoTransactionTypeForOFX, } from '../domain/pismo.domain';
import {
  Disbursement,
} from '../domain/component/ppsr-asset-component.domain';
import { SoleTraderValue } from '../domain/component/sole-trader-component.domain';
import { Address2ComponentValue } from '../domain/component/address2-component.domain';
import {DirectorValue, IndividualDirector} from '../domain/component/director-component.domain';
import { EntityGuarantor, GuarantorValue, IndividualGuarantor } from '../domain/component/guarantor-component.domain';
import { IndividualMember } from '../domain/component/member-component.domain';
import { IndividualPartner } from '../domain/component/partner-component.domain';
import { IndividualTrustee } from '../domain/component/trustee-component.domain';
import { parseJSON } from './json';
import { BankingTransaction } from '../domain/banking-service.domain';
import moment from 'moment';
import { removeUnprintableChar } from './address';
import {TransactionValue} from "../domain/component/transaction-type-component.domain";


// =================================
// === comparator
// =================================
export const compareMatch = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) {
    return true;
  }
  return _.isEqual(obj1, obj2);
}

//////////////////////////

export type ValidEmailCheckFn = (email: string) => Observable<boolean>;

/////

export const shipmentDocumentTags = (): DocumentTag[] => {
  return [
    constants.documentTypes.proForma,
    constants.documentTypes.salesAgreement,
    constants.documentTypes.purchaseOrder,
    constants.documentTypes.freightBooking,
    constants.documentTypes.commercialInvoice,
    constants.documentTypes.TransportDocument
  ]
}


// ======================================
// ============ floorplan calculation
// ======================================

// export const calculateFloorplanInterestRate = (rba: number, interest: number): number => {
//   return (rba + interest);
// }

/* export const calculateFloorplanPrice = (
  isNew: boolean,
  purchasePrice: number, opt: {
  }): {
    purchasePrice: number,
    deposit: number,
    managementFee: number,
    ppsrRegistrationFee: number,
    amountFinanced: number,
  } => {

  const deposit = (purchasePrice * (dep / 100));
  const amountFinanced = ((1 - (dep / 100)) * purchasePrice);
  let managementFee = 0;

  const totalAmountFinanced = amountFinanced + managementFee ;
  console.log('**** internal calc', isNew, dep, deposit, managementFee, totalAmountFinanced);
  return {
    purchasePrice,
    deposit,
    managementFee,
    amountFinanced: totalAmountFinanced,
  }
} */

export const skipApplicationPrivacyConsentDialog = (transaction: TransactionValue): boolean => {
  return (transaction?.type == 'Internal Re-write');
}

export const applicantsPrivacyConsent = (
  applicants: {firstName: string, lastName: string, privacyConsentObtained: boolean}[]):
  {
    atLeastOneWithPrivacyConsent: boolean,
    allHasPrivacyConsent: boolean,
    withPrivacyConsent: {firstName: string, lastName: string, privacyConsentObtained: boolean}[],
    withoutPrivacyConsent: {firstName: string, lastName: string, privacyConsentObtained: boolean}[],
  } => {
  const withPrivacyConsent: {firstName: string, lastName: string, privacyConsentObtained: boolean}[] = [];
  const withoutPrivacyConsent: {firstName: string, lastName: string, privacyConsentObtained: boolean}[] = [];
  for (const applicant of applicants) {
    if (applicant.privacyConsentObtained) {
      withPrivacyConsent.push(applicant);
    } else {
      withoutPrivacyConsent.push(applicant);
    }
  }
  return {
    atLeastOneWithPrivacyConsent: withPrivacyConsent.length > 0,
    allHasPrivacyConsent: withoutPrivacyConsent.length <= 0,
    withPrivacyConsent,
    withoutPrivacyConsent,
  };
}

export const getSchedulePaymentsFrequency = (arg:string,date:string) => {
        
  const specificDate = moment(date);

  switch (arg) {
      case 'fortnightly': {
        return  specificDate.add(15, 'days').format('YYYY-MM-DD');
      }
      case 'weekly':{
        return specificDate.add(1, 'weeks').format('YYYY-MM-DD');
      }  
      case 'monthly': {
        return specificDate.add(1, 'months').format('YYYY-MM-DD');
      }
      case 'date': {
        return date;
      }
       
      default:{
        return date;
      }
        
  }

  
} 

export const individualApplicants = (applicant: ApplicationApplicant) => {
  const r: (IndividualTrustee | IndividualMember | IndividualPartner | NotNullable<SoleTraderValue> | IndividualDirector)[] = [];
  if (applicant) {
    if (Array.isArray(applicant)) {
      for (const _applicant of (applicant ?? [])) {
        if (_applicant.type === 'Individual') {
          r.push(_applicant);
        } else if (_applicant.kind === 'Director') {
          r.push(_applicant);
        }
      }
    } else {
      if (applicant.kind === 'SoleTrader') {
        r.push(applicant);
      }
    }
  }
  return r;
}

export const isApplicationApplicantsSoleTrader = (applicantValues: ApplicationApplicant): applicantValues is SoleTraderValue => {
  if (applicantValues) {
    return (!Array.isArray(applicantValues) && applicantValues.kind === 'SoleTrader');
  }
  return false;
}

export const isApplicationApplicantIndividual = (app: ApplicationApplicantVal | null): app is (IndividualTrustee | IndividualPartner | IndividualMember | Exclude<SoleTraderValue, null> | Exclude<DirectorValue, null>[number]) => {
  if (app) {
    if (app.kind == 'SoleTrader') {
      return true;
    } else if (app.kind == 'Director') {
      return true;
    } else {
      if (app.type == 'Individual') {
        return true;
      }
    }
  }
  return false;
}

export const isIndividualGrantor = (g: IndividualGuarantor | EntityGuarantor): g is IndividualGuarantor => {
  return g.type == 'Individual';
}

export const individualGuarantors = (guarantors: (IndividualGuarantor | EntityGuarantor)[] | null): IndividualGuarantor[] => {
  const i: IndividualGuarantor[] = [];
  for (const g of (guarantors ?? [])) {
    if (g.type == 'Individual') {
      i.push(g);
    }
  }
  return i;
}




// ========================================================================
// === IncomeSelectionValue parsing
// =======================================================================
export const fromIncomeSelectionValueMonthlyIncome = (value: IncomeSelectionValue): number => {
  if (value) {
    switch (value.period.type) {
      case 'Weekly': {
        return (value.income * 4);
      }
      case 'Fortnightly': {
        return value.income * 2;
      }
      default: {  // monthly
        return value.income;
      }
    }
  }
  return 0;
}

// ========================================================================
// === text / number parsing
// =======================================================================
export const asNumber = (number: string | number, decimals: number = 2): number | null => {
  const d = decimals <= 0 ? 0 : decimals;
  // workout the format eg. 2 decimals => 0.00, 3 decimals => 0.000
  const format = d <= 0 ? '0' : (Array.from(Array(d)).reduce((acc, curr) => {
    const r = `${acc}0`;
    return r;
  }, '0.'));
  return numeral(numeral(number).format(format)).value();
}

export const textWithLineBreaksToHtml = (text: string): string => {
  const r = (text ? `<p>${text}</p>` : '')
    .replace(/\r\n\r\n/g, '</p><p>')
    .replace(/\n\n/g, '</p><p>')
    .replace(/\r\n/g, '<br/>')
    .replace(/\n/g, '<br/>')
    ;
  return r;
}

export const countWordsInText = (text: string): number => {
  const words = (text ?? '')
    .trim()
    .replace(/\r|\n/g, ' ')
    .trim()
    .split(/\n|\s{1,}/g);
  return words.length > 0 ? words.length : 0;
}

export const wordsInText = (text: string, wordsCount: number): string => {
  const words = (text ?? '')
    .trim()
    .replace(/\r|\n/g, ' ')
    .trim()
    .split(/\n|\s{1,}/g);
  return (words ?? []).slice(0, wordsCount).join(' ');
}

export const parseCurrency = (num: number) => {
  return parseFloat(num.toFixed(2));
}

export const alphanumericOnly = (input: string) => {
  if (input) {
    return input.replace(/[^\w\s]/gi, '');
  }
  return input;
}

export const patchABAAllowedChar = (s: string): string => {
  return s.replace(/[^a-zA-Z0-9()&\s]+/gi, "").trim();
}


// =================================
// === Compare / Sort
// =================================
export const filterOut = <T>(i: T[], fn: (a: T, b: T) => boolean): T[] => {
  return (i ?? []).reduce((acc: T[], current) => {
    const found = acc.find(t => fn(t, current));
    if (!found) {
      acc.push(current);
    }
    return acc;
  }, []);
}
export const filterOutUniquePrimaryContacts = (contacts: ContactValue[]): ContactValue[] => {
  return filterOut((contacts ?? []), (a, b) => {
    return (
      a.title?.type == b.title?.type &&
      a.firstName == b.firstName &&
      a.lastName == b.lastName
    );
  });
}
export const compare = (a: number | string, b: number | string, isAsc: boolean): number => {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

// ==========================
// ====  Javascript File utils
// ==========================
export const filesToBase64Files = async (files: any[]): Promise<Base64File[]> => {
  const base64Files: Base64File[] = [];
  for (const file of (files ?? [])) {
    const base64Content = await fileToBase64(file);
    const base64File = {
      name: file.name,
      size: file.size,
      type: file.type,
      base64: base64Content,
      tags: file.tags ?? [],
      metadata: file?.metadata ?? []
    };
    base64Files.push(base64File);
  }
  return base64Files;
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // let encoded = reader.result!.toString().replace(/^data:(.*,)?/, '');
      // if ((encoded.length % 4) > 0) {
      //   encoded += '='.repeat(4 - (encoded.length % 4));
      // }
      // res(encoded);

      res(reader.result as string);
    }
    reader.onerror = error => rej(error);
  });
}

export const isBase64File = (f: any): f is Base64File => {
  return (f && f.base64);
}


export const getFileTags = (file: AzureFileTagable): string[] => {
  const allTags: DocumentTag[] = Object.values(constants.documentTypes);

  if (file?.tags) {
    const tags = Object.values(file.tags);
    return tags.map(tag => {
      const matchedTag = allTags.find(t => t.value === tag);
      if (matchedTag) {
        return matchedTag.type;
      }
      return '';
    })
  }
  return [];
}





// ==========================
// ====   Display Conversion
// ==========================
export const booleanToYesNo = (bool: boolean | null | undefined): 'Yes' | 'No' => {
  return bool ? 'Yes' : 'No';
}

export const yesNoToBoolean = (s: string): boolean => {
  return s.toLowerCase() === 'yes' ? true : false;
}

export const displayApplicationDateTime = (moment: Moment | null | undefined): string => {
  return moment ? moment.format('hh:mm a DD/MM/YYYY') : '';
}

export const displaybleDate = (moment: Moment | null | undefined): string => {
  return moment ? moment.format('DD/MM/YYYY') : '';
}

export const displayableDateTime = (moment: Moment | null | undefined): string => {
  return moment ? moment.format('DD/MM/YYYY hh:mm a') : '';
}

export const notNullUndefined = (a: any): boolean => {
  return (a !== null && a !== undefined);
}


// ==========================
// ====   Patching value
// ==========================
export const patchAcn = (_acn?: string | null) => {
  if (_acn) {
    let acn = String(_acn).trim();
    if (acn.toString().length < 9) {
      while (acn.toString().length < 9) {
        acn = '0' + acn
      }
    }
    return acn;
  }
  return _acn;
}



// ======================================================================================
// === Applications utils
// ======================================================================================
export const getApplicationStatus2 = (stage: string, salesforceId?: number | null | string):
  'Draft' | 'Declined' | 'Withdrawn' | 'Approved' | 'Under Review' | 'Settled' | 'Closed' | null => {
  let decision: 'Draft' | 'Declined' | 'Withdrawn' | 'Approved' | 'Under Review' | 'Settled' | 'Closed' | null = 'Under Review';
  if (!salesforceId) {
    return 'Draft';
  } else if (stage == 'Submitted to Credit' ||
    stage == 'Waiting for Bank Statements' ||
    stage == 'More Information' ||
    stage == 'Lead'
  ) {
    decision = 'Under Review';
  } else if (stage == 'Credit Approved' ||
    stage == 'Documentation' ||
    stage == 'Docs Out' ||
    stage == 'Settlement Pending' ||
    stage == 'QA' ||
    stage == 'Settlement' ||
    stage == 'Compliance Review' ||
    stage == 'Deal Preparation'
  ) {
    decision = 'Approved';
  } else if (stage == 'Closed Won') {
    decision = 'Settled';
  } else if (stage == 'Closed Lost with Loss Reason Category Withdrawn') {
    decision = 'Withdrawn';
  } else if (stage == 'Closed Lost with non Withdrawn reason') {
    decision = 'Declined';
  } else if (stage == 'Closed Lost') {
    decision = 'Closed'
  }
  return decision;
}
export const getApplicationStage2 = (stage?: string | null): string | 'Draft' => {
  return (stage != null && stage != undefined) ? stage : 'Draft';
}
export const getApplicationStatus = (application: Application):
  'Draft' | 'Declined' | 'Withdrawn' | 'Approved' | 'Under Review' | 'Settled' | 'Closed' | null => {
  let decision: 'Draft' | 'Declined' | 'Withdrawn' | 'Approved' | 'Under Review' | 'Settled' | 'Closed' | null = null;
  if (application) {
    const stageName = application.AppInfoStageName ?? application.InteflowStatus?.StageName ?? '';
    const salesforceId = application.AppInfoSalesforceID;

    decision = getApplicationStatus2(stageName, salesforceId);

    // const AppInfo = (application.AppInfo);
    // const InteflowStatus = (application.InteflowStatus);
    // if (AppInfo && AppInfo.SalesforceId == null) {
    //   decision = 'Draft';
    // } else if (InteflowStatus && InteflowStatus.StageName === 'Closed Lost' ) {
    //   if (InteflowStatus.ec1_CreditReasoning__c != null) {
    //     decision = 'Declined';
    //   } else {
    //     decision = 'Withdrawn';
    //   }
    // } else if ((InteflowStatus &&
    //     InteflowStatus.ec1_Credit_Approval_DateTime__c != null) ||
    //   (InteflowStatus && InteflowStatus.ec1_Credit_Approval_Date__c != null)) {
    //   decision = 'Approved';
    // } else if (InteflowStatus && InteflowStatus.ec1_CreditReasoning__c != null) {
    //   decision = 'Declined';
    // } else {
    //   decision = 'Under Review';
    // }
  }
  return decision;
}

export const getApplicationStage = (application: Application): string | 'Draft' => {
  const appInfo = (application.AppInfo) ?? null;
  // const stageName = appInfo && appInfo.StageName != null ? appInfo.StageName : 'Draft';
  const stageName = getApplicationStage2(appInfo?.StageName);
  return stageName;
}

export const applicationStageAllowGenerateContract = (application: Application): boolean => {
  const stageName = getApplicationStage(application).toLowerCase();
  return !["lead", "submitted to credit", "waiting for bank statements", "more information", "closed lost", "closed won"].includes(stageName);
}

export const applicationStageAllowSettleLoan = (application: Application): boolean => {
  const stageName = getApplicationStage(application).toLowerCase();
  return ['documentation', 'docs out', 'settlement pending', 'qa'].includes(stageName);
}

export const applicationStageNotAllowDisbursementChange = (application: Application): boolean => {
  const stageName = getApplicationStage(application).toLowerCase();
  return ['settlement', 'closed won'].includes(stageName);
}

export const getOrgTypeByEntityText = (entityText: string): 'Other' | 'Trust' | 'Partnership' | 'Company' => {
  const orgType = 'Other';
  if (entityText.toLowerCase().indexOf('trust') != -1) {
    return 'Trust';
  } else if (entityText.toLowerCase().indexOf('partnership') != -1) {
    return 'Partnership';
  } else if (entityText.toLowerCase().indexOf('company') != -1) {
    return 'Company';
  }
  return orgType;
}




// ==========================================================
// ======== asset finance calculation
// ==========================================================
export const isTier1Asset = (assetCategory: string): boolean => {
  const TIER1_ASSET_CATS = ['137'];
  return TIER1_ASSET_CATS.includes(assetCategory);
}

export const isTier2Asset = (assetCategory: string): boolean => {
  const TIER2_ASSET_CATS = ['132', '130'];
  return TIER2_ASSET_CATS.includes(assetCategory);
}


export const isConsumerAssetForCar = (assetType: string): boolean => {
  const CONSUMER_ASSET_TYPES = ['1'];
  return CONSUMER_ASSET_TYPES.includes(assetType);
}

export const minDepositForAssetFinance = (invoiceAmount: number, assetCategoryIndex: string, isPropertyOwner: boolean | null, productType: AssetFinanceProductType = "FAST DOC"): number | null => {
  const isTier1 = isTier1Asset(assetCategoryIndex);
  const isTier2 = isTier2Asset(assetCategoryIndex);

  const apply20pDeposit = ((isTier1 && invoiceAmount > 75000) || (isTier2 && invoiceAmount > 100000)) && !isPropertyOwner && productType === "FAST DOC";

  if (apply20pDeposit) {
    const minDeposit = _.round(invoiceAmount * 0.2, 2); // 20% of invoiceAmount
    return minDeposit;
  } else {
    return 0;
  }
}

export const maxLoanForAssetFinance = (assetCategoryIndex: string, isPropertyOwner: boolean | null): number  => {
    const isAssetBacked = isPropertyOwner ; //is propertyOwnership true mean asset backed
    let tier = null
    if (TIER1_ASSET_CATS.includes(assetCategoryIndex)) {
        tier = 1;
    } else if (TIER2_ASSET_CATS.includes(assetCategoryIndex)) {
        tier = 2;
    } else if (TIER3_ASSET_CATS.includes(assetCategoryIndex)) {
        tier = 3;
    } else if (TIER4_ASSET_CATS.includes(assetCategoryIndex)) {
        tier = 4;
    }
    if(tier === 1 && isAssetBacked){
      return 1000000;
    }else if(tier === 1 && !isAssetBacked){
      return 250000;
    }else if(tier === 2 && isAssetBacked){
      return 1000000;
    }else if(tier === 2 && !isAssetBacked){
      return 250000;
    }else if(tier === 3 && isAssetBacked ){
      return 500000;
    }else if(tier === 3 && !isAssetBacked){
      return 250000;
    }else if(tier === 4 && isAssetBacked ){
      return 500000;
    }else if(tier === 4 && !isAssetBacked){
      return 250000;
    }
    return 250000;
}


export const minDepositForConsumerAssetFinance = (invoiceAmount: number, assetTypeIndex: string, isPropertyOwner: boolean): number | null => {
  const isCar = isConsumerAssetForCar(assetTypeIndex);

  const apply20pDeposit = isCar && invoiceAmount > 75000 && !isPropertyOwner;

  if (apply20pDeposit) {
    const minDeposit = _.round(invoiceAmount * 0.2, 2); // 20% of invoiceAmount
    return minDeposit;
  } else {
    return 0;
  }
}

export const minDepositForSolar = (invoiceAmount: number, isPropertyOwner: boolean): number | null => {
  const apply20pDeposit = !isPropertyOwner;

  if (apply20pDeposit) {
    const minDeposit = _.round(invoiceAmount * 0.2, 2); //20% of invoiceAmount
    return minDeposit;
  } else {
    return 0;
  }
}

export const maxLoanForBusinessLoan = (gstAge: number): number => {
  if (gstAge >= 24) {
    return 500000;
  } else {
    return 250000;
  }
}

export const maxLoanForBusinessOverdraft = (gstAge: number): number => {
  if (gstAge >= 24) {
    return 500000;
  } else {
    return 250000;
  }
}



// ===========================================
// === auth / user / customer
// ===========================================
export const isInternalUser = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    ['admin', 'analyst', 'internalbroker', 'operations', 'operations24', 'credit', 'settlement', 'salesBDM', 'salesAM'].includes(u.AccessLevel));
}

export const isNotAdminOrCreditOrSalesAMInternalUser = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
  return !(isCreditUser(u) || isAdmin(u) || isSalesAM(u));
}

export const isCreditUser = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    ['credit'].includes(u.AccessLevel));
}

export const isAdminOrCreditUser = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
  return isCreditUser(u) || isAdmin(u);
}

export const isAdminOrOperationUser = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
  return isOperations(u) || isAdmin(u);
}

export const isAdminOrCreditUserOrSalesAM = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
  return isCreditUser(u) || isAdmin(u) || isSalesAM(u);
}

export const isAdminOrCreditUserOrSalesAmOrSalesBDM = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
  return isCreditUser(u) || isAdmin(u) || isSalesAM(u) || isSalesBDM(u);
}

export const isAdminOrOperationsOrAnalyst = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
  return isAdmin(u) || isOperations(u) || isAnalyst(u);
}

export const isAdminOrFinance = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
  return isAdmin(u) || isFinance(u);
}

export const isOperations = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    u.AccessLevel == 'operations');
}

export const isOperations24 = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    u.AccessLevel == 'operations24');
}

export const isAdmin = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    u.AccessLevel == 'admin');
}

export const isSalesAM = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    u.AccessLevel == 'salesAM');
}

export const isSalesBDM = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    u.AccessLevel == 'salesBDM');
}

export const isAnalyst = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    (u.AccessLevel == 'analyst' || u.AccessLevel == 'operations' || u.AccessLevel == 'operations24' || u.AccessLevel == 'credit' || u.AccessLevel == 'settlement'));
}

export const isFinance = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    u.AccessLevel == 'analyst');
}

export const isDealer = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    ['dealer'].includes(u.AccessLevel));
}

export const isExternalUser = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    ['externalbroker', 'companyadmin', 'companyoperator'].includes(u.AccessLevel));
}

export const isExternalBroker = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    ['externalbroker'].includes(u.AccessLevel));
}

export const isExternalAdmin = (u?: Pick<User, 'AccessLevel'> | null): boolean => {
  return (!!u &&
    !!u.AccessLevel &&
    ['companyadmin'].includes(u.AccessLevel));
}

export const isInternalUserOrBroker = (u?: Pick<UserandPriviledges, 'priviledges'> | null): boolean => {
  return (!!u &&
    !!u.priviledges && (
      u.priviledges?.includes('admin') ||
      u.priviledges?.includes('analyst') ||
      u.priviledges?.includes('internalbroker') ||
      u.priviledges?.includes('externalbroker') ||
      u.priviledges?.includes('broker') ||
      u.priviledges?.includes('companyadmin') ||
      u.priviledges?.includes('operations') ||
      u.priviledges?.includes('operations24') ||
      u.priviledges?.includes('credit') ||
      u.priviledges?.includes('settlement') ||
      u.priviledges?.includes('salesBDM') ||
      u.priviledges?.includes('salesAM')
    )
  )
}

export const isAssociatedWithAggregator = (u?: Pick<UserandPriviledges, 'priviledges'> | null): boolean => {
  return (!!u && u.priviledges.includes('aggregator'));
}

export const toUser = <T extends User>(user: T | null, removeSensitiveInfo: boolean = true): T | null => {
  if (user) {
    if (removeSensitiveInfo) {
      delete (user as any).PasswordHash;
      delete (user as any).PasswordSalt;
    }
    if (user.ResidentialAddress) {
      user.ResidentialAddress = parseJSON(user.ResidentialAddress);
    }
    if (user.PostalAddress) {
      user.PostalAddress = parseJSON(user.PostalAddress);
    }
    if (user.RoleAccess) {
      user.RoleAccess = parseJSON(user.RoleAccess);
    }
    if (user.kycVerificationResult) {
      user.kycVerificationResult = parseJSON(user.kycVerificationResult);
    }
    if (user.kycVerificationError) {
      user.kycVerificationError = parseJSON(user.kycVerificationError);
    }
  }
  return user;
}

export const toCustomer = <T extends Customer>(customer: T): T => {
  if (customer) {
    if (customer.CustomerId) {
      customer.CustomerId = Array.isArray(customer.CustomerId) ? customer.CustomerId.filter(c => !!c)[0] : customer.CustomerId;
    }
    if (customer.Address) {
      customer.Address = parseJSON(customer.Address);
    }
    if (customer.Document) {
      customer.Document = parseJSON(customer.Document);
    }
  }
  return customer;
}

/* export const toDrawdownRequest = <T extends DrawdownRequest | PendingDrawdown2 | ApprovedDrawdown2>(d: T): T => {
  if (d) {
    if (d.shipment) {
      d.shipment = parseJSON(d.shipment);
      if (d.shipment) {
        d.shipment.preship = !!d.shipment.preship;
        d.shipment.postship = !!d.shipment.postship;
      }
    }
    if (d.shipmentDetails) {
      d.shipmentDetails = parseJSON(d.shipmentDetails);
    }
    if (d.authoriseDetails) {
      d.authoriseDetails = parseJSON(d.authoriseDetails);
    }
    if (d.disbursementInstructions) {
      d.disbursementInstructions = parseJSON(d.disbursementInstructions);
    }

    if ((d as (PendingDrawdown2 | ApprovedDrawdown2)).Address) {
      (d as (PendingDrawdown2 | ApprovedDrawdown2)).Address = parseJSON((d as (PendingDrawdown2 | ApprovedDrawdown2)).Address);
    }
    if (d.supportingDoc) {
      d.supportingDoc = parseJSON(d.supportingDoc);
    }
    if ((d as (PendingDrawdown2 | ApprovedDrawdown2)).Address) {
      (d as (PendingDrawdown2 | ApprovedDrawdown2)).Address = parseJSON((d as (PendingDrawdown2 | ApprovedDrawdown2)).Address);
    }

  }
  return d;
} */

export const toRatecardDetails = <T extends RatecardDetails>(_r: T, rbaRate: number): T => {
  if (_r) {
    let assetTypes: AssetTypeRateValue = parseJSON(_r.AssetType);
    if (assetTypes && Array.isArray(assetTypes)) {
      assetTypes = assetTypes.map(assetType => ({
        Cat: assetType.Cat,
        Type: assetType.Type.split('-')[1] ?? assetType.Type,
        Rate: assetType.Rate,
      }))
    }
    _r.AssetType = assetTypes ?? [];
    _r.ABNorGSTAge = parseJSON(_r.ABNorGSTAge);
    _r.ApplicationCategory = parseJSON(_r.ApplicationCategory);
    _r.AssetFinanceTier1 = parseJSON(_r.AssetFinanceTier1);
    _r.AssetFinanceTier2 = parseJSON(_r.AssetFinanceTier2);
    _r.AssetFinanceTier3 = parseJSON(_r.AssetFinanceTier3);
    _r.AssetFinanceTier4 = parseJSON(_r.AssetFinanceTier4);
    _r.TransactionTypeRate = parseJSON(_r.TransactionTypeRate);
    _r.AssetCategory = parseJSON(_r.AssetCategory);
    _r.Brokerage = parseJSON(_r.Brokerage);
    _r.DocFee = parseJSON(_r.DocFee);
    _r.EOTAge = parseJSON(_r.EOTAge);
    _r.LoanAmount = parseJSON(_r.LoanAmount);
    _r.LoanTerms = parseJSON(_r.LoanTerms);
    _r.CarsAndCaravans = parseJSON(_r.CarsAndCaravans);
    _r.Motorbikes = parseJSON(_r.Motorbikes);
    _r.SolarSystems = parseJSON(_r.SolarSystems);
    _r.SecurityType = parseJSON(_r.SecurityType);
    _r.rbaRate = rbaRate;
  }
  return _r;
}


export const toApplication = <T extends Application>(r: T): T => {
  if (r) {
    (r as any).ApplicantLocation = parseJSON((r as any).ApplicantLocation);
    (r as any).CompanyDetails = parseJSON((r as any).CompanyDetails);
    (r as any).AppInfo = parseJSON((r as any).AppInfo);
    (r as any).Contacts = parseJSON((r as any).Contacts);
    (r as any).CommercialEntities = (parseJSON((r as any).CommercialEntities) ?? []);
    (r as any).Individuals = (parseJSON((r as any).Individuals) ?? []);
    (r as any).Reference = parseJSON((r as any).Reference);
    (r as any).AssetSpec = parseJSON((r as any).AssetSpec);
    (r as any).Expense = parseJSON((r as any).Expense);
    (r as any).Policies = parseJSON((r as any).Policies);
    (r as any).PricingDetails = parseJSON((r as any).PricingDetails);
    (r as any).Documents = parseJSON((r as any).Documents);
    (r as any).InteflowStatus = parseJSON((r as any).InteflowStatus);
    (r as any).DocumentResult = parseJSON((r as any).DocumentResult);
    (r as any).GrowRiskRatingData = parseJSON((r as any).GrowRiskRatingData);
    (r as any).OppStatus = parseJSON((r as any).OppStatus);
    (r as any).AdditionalBrokerCorrespondent = parseJSON((r as any).AdditionalBrokerCorrespondent);
    (r as any).BrokerContact = parseJSON((r as any).BrokerContact);
    (r as any).LvrCalculatorValue = parseJSON((r as any).LvrCalculatorValue);
    (r as any).DscrCalculatorValue = parseJSON((r as any).DscrCalculatorValue);
    (r as any).ConsumerDscrCalculatorValue = parseJSON((r as any).ConsumerDscrCalculatorValue);
    (r as any).PreviousBusiness = parseJSON((r as any).PreviousBusiness);

    if ((r as any).DocumentResult) {
      for (const dRst of (r as any).DocumentResult) {
        if (dRst.result) {
          dRst.result = parseJSON(dRst.result);
        }
      }
    }
    (r as any).AuthorisedSignatory = parseJSON((r as any).AuthorisedSignatory);
    (r as any).Policies = parseJSON((r as any).Policies);
    (r as any).DocumentTypes = parseJSON((r as any).DocumentTypes);
  }
  return r;
}


export const toPpsrData = <T extends PpsrData>(r: T): T => {
  if (r) {
    if (r.PPSRResponse) {
      r.PPSRResponse = parseJSON(r.PPSRResponse);
    }
    if (r.companySummary) {
      r.companySummary = parseJSON(r.companySummary);
    }
    if (r.Grantors) {
      r.Grantors = (parseJSON(r.Grantors) ?? [])
        .map((g: PpsrData['Grantors']) => {
          if ((g as any).OrganisationNumberType && (g as any).OrganisationNumberType == 'ABN') {
            return {
              type: 'ABN',
              ...g,
            }
          } else if ((g as any).OrganisationNumberType && (g as any).OrganisationNumberType == 'ACN') {
            return {
              type: 'ACN',
              ...g,
            }
          } else {
            return {
              type: 'Individual',
              ...g,
            }
          }
        });
    }
  }
  return r;
}

export const toPpsrAsset = <T extends PpsrAsset>(r: T): T => {
  if (r) {
    if (r.AssetSFRes) {
      r.AssetSFRes = parseJSON(r.AssetSFRes);
    }
    if (r.SettlementAssetDetails) {
      r.SettlementAssetDetails = parseJSON(r.SettlementAssetDetails);
    }
    if (r.PrivateSellerDetails) {
      r.PrivateSellerDetails = parseJSON(r.PrivateSellerDetails);
    }
  }
  return r;
}






// NOTE: not used anymore
// ==========================================================
// ==== Drawdown request utils ==============================
// ==========================================================
// export const DRAWDOWN_REQUEST_TYPE_DEPOSIT = 'Deposit';
//
// export const DRAWDOWN_REQUEST_TYPE_DEPOSIT_OVERDUE = 'DepositOverdue';
// export const DRAWDOWN_REQUEST_TYPE_LEGACY = 'Legacy';
//
// export const isDrawdownRequestDeposit = <T extends DrawdownRequest>(drawdownRequest: T /* mssql recordset entry from DrawdownRequests table */) => {
//   return (drawdownRequest && drawdownRequest.type && drawdownRequest.type === DRAWDOWN_REQUEST_TYPE_DEPOSIT);
// }
// export const isDrawdownRequestLegacy = <T extends DrawdownRequest>(drawdownRequest: T /* mssql recordset entry from DrawdownRequests table */) => {
//   return (drawdownRequest && drawdownRequest.type && drawdownRequest.type === DRAWDOWN_REQUEST_TYPE_LEGACY);
// }


// =========================
// === driver licence
// =========================

export const isDriverLicenceDetailsError = (driverLicence: DriverLicenceDetails): driverLicence is DriverLicenceDetailsError => {
  return (driverLicence as any).Error;
}


// ==============================
// ==== Access Level
// ==============================
export const allowEmailAccessLevel = (email: string, accessLevel: AccessLevel): boolean => {
  const isInternalEmail = INTERNAL_EMAIL_DOMAINS.reduce((acc, currentEmailDomain) => {
    const r = (email ?? '').trim().toLowerCase().endsWith(currentEmailDomain);
    const x = (acc || r);
    return x;
  }, false);
  const isInternalAccess = INTERNAL_ACCESS_LEVEL.reduce((acc, currentAccessLevel) => {
    const r = ((accessLevel ?? '').trim().toLowerCase() == currentAccessLevel.toLowerCase());
    const x = (acc || r);
    return x;
  }, false);
  if (isInternalEmail) {
    return true;
  }
  return !isInternalAccess;
}

// ==============================
// ==== Contract
// ==============================
export const getCustomerName = (application: Application): string => {
  const applicationType = application.ApplicationType;
  let customerName = '';
  if (applicationType === 'Consumer') {
    const individuals = application.Individuals;
    const applicant = individuals.find(i => i.Role == "Applicant");
    customerName = (applicant?.GivenName ?? "") + " " + (applicant?.SurName ?? "");
  } else {
    const entities = application.CommercialEntities ?? [];
    const primaryEntity = entities.find(e => e.Type == "Primary");
    customerName = primaryEntity?.LegalName ?? "";
  }
  return customerName;
}

export const getContractFileName = (application: Application): string => {
  const applicationType = application.ApplicationType;
  let fileName = '';
  const customerName = getCustomerName(application);
  switch (applicationType) {
    case 'AssetFinance':
      const financeType = application.AppInfo?.FinanceType;
      if (financeType === "Chattel Mortgage") {
        fileName = constants.contractTemplates.chattelMortgage;
      } else {
        fileName = constants.contractTemplates.rental;
      }
      break;
    case 'Consumer':
      fileName = constants.contractTemplates.consumer;
      break;
    case 'BusinessLoans':
      fileName = constants.contractTemplates.businessLoans;
      break;
    case 'BusinessOverdraft':
      fileName = constants.contractTemplates.businessOverdraft;
      break;
    case 'CorporateLoans':
      fileName = constants.contractTemplates.corporateLoans;
      break;
    default:
      fileName = constants.contractTemplates.default;
  }

  fileName = fileName.slice(0, fileName.length - 5);
  fileName = fileName + " for " + customerName + " " + (application?.BrokerAppId ?? " ") + ".pdf";

  return fileName;
}

export const getApprovalNoticeFileName = (application: Application): string => {
  const customerName = getCustomerName(application);
  return `Approval Notice For ${customerName} (${application.BrokerAppId}).pdf`;
}

// ==============================
// ==== Numbers
// ==============================
export const getLocaleNumber = (a: number | string): string => {
  return numeral(a).format('0,0.00');
}

// ========================================================
// === Accreditation
// ========================================================
export const getAccreditationNumber = (accreditationId: number): NextAccreditationNumber => {
  const nextAccreditationNumber = accreditationId;
  const nextApplicantNumber = `A${String(100000 + nextAccreditationNumber).substring(1)}`;
  const accreditationNumber: NextAccreditationNumber = {
    accreditationNumber: nextAccreditationNumber,
    applicantNumber: nextApplicantNumber,
  };
  return accreditationNumber;
}

// ========================================================
// === Disbursement & Asset
// ========================================================
export const validateDisbursementData = (disbursementData: Disbursement[]): boolean => {
  for (const data of disbursementData) {
    if (!data?.bankDetails || !data?.bankDetails?.paymentType) {
      return false;
    }
  }
  return true;
}

export const calculateTotalAssetDeposit = (assets: {
  SettlementAssetDetails?: {
    depositAmountDynamoney?: number,
    depositAmountSupplier?: number,
  } | null,
}[]) => {
  const { totalDepositAmountDyna, totalDepositAmountSupplier } = (assets ?? []).reduce((acc, asset) => {
    const _totalDepositAmountDyna = (acc.totalDepositAmountDyna +
      (asset.SettlementAssetDetails?.depositAmountDynamoney ?? 0));
    const _totalDepositAmountSupplier = (acc.totalDepositAmountSupplier +
      (asset.SettlementAssetDetails?.depositAmountSupplier ?? 0));
    return {
      totalDepositAmountDyna: _totalDepositAmountDyna,
      totalDepositAmountSupplier: _totalDepositAmountSupplier
    };
  }, { totalDepositAmountDyna: 0, totalDepositAmountSupplier: 0 });
  return {
    totalDepositAmountDyna, totalDepositAmountSupplier
  };
}

// WEB-3702
export const validateAssetDepositAgainstApplicationDeposit = (applicationDeposit: number, assets: PpsrAsset[]): {
  totalDeposit: number,
  applicationDeposit: number,
  valid: boolean,
} => {
  const { totalDepositAmountDyna, totalDepositAmountSupplier } = calculateTotalAssetDeposit(assets);
  const totalDeposit = _.round(totalDepositAmountDyna + totalDepositAmountSupplier, 2);
  return {
    totalDeposit,
    applicationDeposit,
    valid: applicationDeposit == totalDeposit,
  };
}

// WEB-3702
export const validateAssetDeposits = (assets: PpsrAsset[]): {
  messages: string[],
  valid: boolean
} => {
  return (assets ?? []).reduce((acc: { messages: string[], valid: boolean }, asset) => {
    const total = (asset.SettlementAssetDetails?.depositAmountSupplier ?? 0) + (asset.SettlementAssetDetails?.depositAmountDynamoney ?? 0);
    if (total != asset.SettlementAssetDetails?.depositAmount) {
      acc.valid = false;
      acc.messages.push(`Asset ${asset.SettlementAssetDetails?.serialNumber ?? ''} deposit $${asset.SettlementAssetDetails?.depositAmount ?? 0} does not add up to the sum of deposit (dynamoney) $${asset.SettlementAssetDetails?.depositAmountDynamoney ?? 0} and deposit (Supplier) $${asset.SettlementAssetDetails?.depositAmountSupplier ?? 0}`);
    }
    return acc;
  }, {
    messages: [],
    valid: true,
  });
}

// ========================================================
// === Banking
// ========================================================
export const getReversalProcessingCode = (processingCode: PismoTransactionProcessingCode): PismoTransactionProcessingCode => {
  switch (processingCode) {
    case PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER:
      return PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER_REVERSAL;
    case PismoTransactionProcessingCode.BPAY:
      return PismoTransactionProcessingCode.BPAY_REVERSAL;
    case PismoTransactionProcessingCode.DOCUMENTATION_FEE:
      return PismoTransactionProcessingCode.PAYMENT;
    case PismoTransactionProcessingCode.FACILITY_FEE:
      return PismoTransactionProcessingCode.FACILITY_FEE_REVERSAL;
    case PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE:
      return PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE_REVERSAL;
    default:
      return PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER_REVERSAL;
  }
}

export const getProcessingType = (processingCode: PismoTransactionProcessingCode): string => {
  switch (processingCode) {
    case PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER:
      return 'Bank Transfer';
    case PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER_REVERSAL:
      return 'Bank Transfer Reversal';
    case PismoTransactionProcessingCode.BPAY:
      return 'BPAY';
    case PismoTransactionProcessingCode.BPAY_REVERSAL:
      return 'BPAY Reversal';
    case PismoTransactionProcessingCode.DOCUMENTATION_FEE:
      return 'Documentation Fee';
    case PismoTransactionProcessingCode.FACILITY_FEE:
      return 'Facility Fee';
    case PismoTransactionProcessingCode.FACILITY_FEE_REVERSAL:
      return 'Facility Fee Reversal';
    case PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE:
      return 'Facility Establishment Fee';
    case PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE_REVERSAL:
      return 'Facility Establishment Fee Reversal';
    case PismoTransactionProcessingCode.BROKERAGE:
      return 'Brokerage';
    case PismoTransactionProcessingCode.PAYMENT:
      return 'Payment';
    case PismoTransactionProcessingCode.PAYMENT_REVERSAL:
      return 'Payment Reversal';
    case PismoTransactionProcessingCode.ANNUAL_FEE:
      return 'Annual Fee';
    case PismoTransactionProcessingCode.ANNUAL_FEE_REVERSAL:
      return 'Annual Fee Reversal';
    case PismoTransactionProcessingCode.MERCHANT:
      return 'Merchant';
    case PismoTransactionProcessingCode.DOCUMENTATION_FEE_REVERSAL:
    case PismoTransactionProcessingCode.DOCUMENTATION_FEE:
      return 'Documentation Fee';
    case PismoTransactionProcessingCode.PAYOUT:
      return 'Payout';
    case PismoTransactionProcessingCode.PAYOUT_REVERSAL:
      return 'Payout Reversal';
    default:
      return processingCode;
  }
}

// ========================================================
// === Pismo
// ========================================================
export const encodePismoAddress = (address: Address2ComponentValue | undefined, firstName: string, businessName: string): string => {
  if (address) {
    const addrLine = address.UnitNumber ? `${address.UnitNumber}/${address.StreetNumber ?? ""} ${address.StreetName ?? ""} ${address.StreetType ?? ""}` :
      `${address.StreetNumber ?? ""} ${address.StreetName ?? ""} ${address.StreetType ?? ""}`;
    return `FIRSTNAME:${firstName};BUSNAME:${businessName};ADDRLINE:${addrLine};LOC:${address.Suburb ?? ""};` +
      `STATE:${address.State ?? ""};POSTCODE:${address.Postcode ?? ""};COUNTRY:AUSTRALIA;PRODUCTTYPE:DEFAULT`;
  } else {
    return `FIRSTNAME:${firstName};BUSNAME:${businessName};ADDRLINE:;LOC:;STATE:;POSTCODE:;COUNTRY:AUSTRALIA;PRODUCTTYPE:DEFAULT`;
  }
}

export const decodePismoAddress = (addressString: string): Address2ComponentValue => {
  const splits = addressString.split(';');
  if (addressString && splits.length) {
    let streetNumber = "", streetName = "", streetType = "", suburb = "", state = "", unitNumber = "", postcode = "";
    for (const split of splits) {
      if (split.startsWith("STNO:")) {
        streetNumber = split.slice(5, split.length);
      } else if (split.startsWith("STNAME:")) {
        const streetNameAndType = split.slice(7, split.length);
        const streetNameAndTypeSplits = streetNameAndType.split(" ");
        if (streetNameAndTypeSplits.length > 1) {
          streetType = streetNameAndTypeSplits[streetNameAndTypeSplits.length - 1];
          streetName = streetNameAndTypeSplits.slice(0, streetNameAndTypeSplits.length - 1).join(" ");
        } else {
          streetName = streetNameAndType;
        }
      } else if (split.startsWith("LOC:")) {
        suburb = split.slice(4, split.length);
      } else if (split.startsWith("STATE:")) {
        state = split.slice(6, split.length);
      } else if (split.startsWith("POSTCODE:")) {
        postcode = split.slice(9, split.length);
      }
    }
    return {
      address: `${streetNumber} ${streetName} ${streetType}, ${suburb}, ${state} ${postcode}`,
      StreetNumber: streetNumber,
      StreetName: streetName,
      StreetType: streetType,
      Suburb: suburb,
      State: state,
      UnitNumber: unitNumber,
      Postcode: postcode,
    }
  } else {
    return null;
  }
}

export const pismoPhoneNumber = (phoneNumber: string): PismoPhoneBody => {
  return {
    number: phoneNumber.substring(2), // remove 04 from phone number
    type: "MOBILE",
    extension: null!,
    active: true,
    country_code: '061',
    area_code: '4'
  }
}

export const patchFlexControlBody = (flexControl: PismoFlexControlBody): PismoFlexControlBody => {
  if (flexControl?.max_limit != undefined) {
    return {
      ...flexControl,
      max_limit: flexControl.max_limit * 100
    }
  }
  return flexControl;
}

export const patchFlexControlResponse = (flexControl: PismoFlexControlResponse): PismoFlexControlResponse => {
  if (flexControl?.max_limit != undefined) {
    return {
      ...flexControl,
      max_limit: Math.round(100 * flexControl.max_limit / 100) / 100
    }
  }
  return flexControl;
}

export const getTransactionPricipalAmount = (transaction: PismoTransaction): number => {
  const amountObj = transaction.amount.find(obj => obj.type === 'PRINCIPAL');
  if (amountObj) {
    return amountObj.value;
  }
  return 0;
}

export const convertBankingTransactionToPismoPendingTransaction = (transaction: BankingTransaction): PismoPendingTransaction => {
  return {
    type: 'Pending Payments',
    account_id: transaction.PismoAccountId,
    category: 'payment',
    tenant_account_timestamp: '',
    data: {
      item: {
        contract_amount: transaction.amount,
        response_code: '',
        validation_results: [],
        merchant_city: '',
        dcc: true,
        org_operation: {
          processing_code: transaction.processingCode
        },
        program_id: 0,
        entry_mode: '',
        number_of_installments: 1,
        principal_amount: transaction.amount,
        settlement_currency_literal: '',
        currency_code: '036',
        cardholderbilling_amount: 0,
        ledger_update_id: '',
        settlement_currency_code: '036',
        network: '',
        pre_authorization: false,
        merchant_category_group: '',
        soft_descriptor:
          transaction.processingCode === PismoTransactionProcessingCode.BPAY ?
            `BPAY to ${transaction.accountTitle}(BillerCode: ${transaction.billerCode} CRN: ${transaction.crn})` :
            transaction.processingCode === PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER ?
              `Bank Transfer to ${transaction.accountTitle} (BSB: ${transaction.bsb}, AccNo: ${transaction.accountNumber})` :
              transaction.processingCode === PismoTransactionProcessingCode.PAYOUT ?
                `Payout to ${transaction.accountTitle}` :
                `Overdraft Payment from ${transaction.accountTitle} (BSB: ${transaction.bsb}, AccNo: ${transaction.accountNumber})`,
        installments: [{
          date: transaction.date,
          amount: transaction.amount,
        }],
        currency_literal: '',
        authorization_code: '',
        cash_back_amount: 0,
        airport_tax: 0,
        interest_rate: 0,
        postings: [],
        id: transaction.id,
        mti: '',
        installment_amount: 0,
        to_name: '',
        merchant_category_code: '',
        rates: {
          conversion_rate: 0,
          cardholder_conversion_rate: 0,
          intl_purchase_fx_rate: 0
        },
        settlement_currency_rate: 0,
        merchant_state_or_country: '',
        card_type: '',
        card_id: 0,
        cardholder_currency_rate: 0,
        local_amount: transaction.amount,
        card_name: '',
        entry_mode_literal: '',
        customer_id: transaction.PismoCustomerId,
        settlement_currency_amount: 0
      }
    },
    correlation_id: `${transaction.id}`,
    timestamp: transaction.createdTime ?? '',
    processing_code: transaction.processingCode,
    metadata: {
      OD_PAYMENT_TIME: transaction.createdTime,
      OD_PAYMENT_COLLECTED_BY: "DYNAMONEY MOBILE",
      AccountName: transaction.accountTitle ?? '',
      BSB: transaction.bsb ?? '',
      AccountNumber: transaction.accountNumber ?? '',
      CRN: transaction.crn ?? '',
      BillerCode: transaction.billerCode ?? '',
      Description: transaction.reference ?? ''
    }
  }
}

export const getPismoAccountingRecordCategory = (record: PismoAccountingRecord): PismoAccountingCategory => {
  if ([
    PismoTransactionProcessingCode.DOCUMENTATION_FEE,
    PismoTransactionProcessingCode.DOCUMENTATION_FEE_REVERSAL,
    PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE,
    PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE_REVERSAL,
    PismoTransactionProcessingCode.FACILITY_FEE,
    PismoTransactionProcessingCode.FACILITY_FEE_REVERSAL,
    PismoTransactionProcessingCode.CARD_REISSUE_FEE,
    PismoTransactionProcessingCode.CARD_REISSUE_FEE_REVERSAL,
    PismoTransactionProcessingCode.PAYMENT_DISHONOUR_FEE,
    PismoTransactionProcessingCode.PAYMENT_DISHONOUR_FEE_REVERSAL,
    PismoTransactionProcessingCode.LATE_FEE,
    PismoTransactionProcessingCode.CAN_LATE_FEE,
    PismoTransactionProcessingCode.UNDO_CANC_LATE_FEE,
    PismoTransactionProcessingCode.OVERLIMIT_FEE,
    PismoTransactionProcessingCode.CAN_OVERLIMIT_FEE,
    PismoTransactionProcessingCode.UNDO_CANC_OVERLIMIT_FEE,
    PismoTransactionProcessingCode.ANNUAL_FEE,
    PismoTransactionProcessingCode.ANNUAL_FEE_REVERSAL,
    PismoTransactionProcessingCode.ANNUITY,
    PismoTransactionProcessingCode.CAN_ANNUITY,
    PismoTransactionProcessingCode.SECURITY_REGISTRATION_AND_LEGAL_FEE,
    PismoTransactionProcessingCode.SECURITY_REGISTRATION_AND_LEGAL_FEE_REVERSAL,
  ].includes(record.processingCode)) {
    return 'fees';
  } else if ([
    PismoTransactionProcessingCode.INSTALLMENT_WITHOUT_INTEREST,
    PismoTransactionProcessingCode.INSTALLMENT_WITHOUT_INTEREST_REVERSAL,
    PismoTransactionProcessingCode.INTEREST,
    PismoTransactionProcessingCode.INTEREST_REVERSAL,
    PismoTransactionProcessingCode.INTEREST_ON_REVOLVING_CREDIT,
    PismoTransactionProcessingCode.INTEREST_ON_REVOLVING_CREDIT_REVERSAL,
    PismoTransactionProcessingCode.UNDO_CANC_INTEREST_ON_REVOLVING_CREDIT,
    PismoTransactionProcessingCode.LATE_PAYMENT_INTEREST,
    PismoTransactionProcessingCode.CAN_LATE_PAYMENT_INTEREST,
    PismoTransactionProcessingCode.UNDO_CANC_LATE_PAYMENT_INTEREST
  ].includes(record.processingCode)) {
    return 'interest';
  } else if ((record.processingCode === PismoTransactionProcessingCode.PAYMENT && record.description === 'Payment') ||
    record.processingCode === PismoTransactionProcessingCode.MINIMUM_PAYMENT_REVERSAL
  ) {
    return 'systemRepayment'
  } else if (
    (record.processingCode === PismoTransactionProcessingCode.PAYMENT && record.description === 'Manual Payment') ||
    record.processingCode === PismoTransactionProcessingCode.PAYMENT_REVERSAL
  ) {
    return 'manualRepayment'
  } else if ([
    PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER,
    PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER_REVERSAL,
    PismoTransactionProcessingCode.BPAY,
    PismoTransactionProcessingCode.BPAY_REVERSAL,
    PismoTransactionProcessingCode.PAYOUT,
    PismoTransactionProcessingCode.PAYOUT_REVERSAL
  ].includes(record.processingCode)) {
    return 'redrawWithTheBank';
  } else if ([
    PismoTransactionProcessingCode.TRANSFERRED_BALANCE,
    PismoTransactionProcessingCode.TRANSFERRED_BALANCE_REVERSAL,
  ].includes(record.processingCode)) {
    return 'others';
  } else if ([
    PismoTransactionProcessingCode.CREDIT_ADJUSTMENT
  ].includes(record.processingCode)) {
    return 'creditAdjustment'
  } else if ([
    PismoTransactionProcessingCode.DEBIT_ADJUSTMENT
  ].includes(record.processingCode)) {
    return 'debitAdjustment'
  } else {
    return 'creditCard';
  }
}

export const getTransactionTypeForOFX = (transaction: PismoTransaction): PismoTransactionTypeForOFX => {
  const processingCode = (transaction as PismoTransaction).processing_code
  if ([
    PismoTransactionProcessingCode.DOCUMENTATION_FEE,
    PismoTransactionProcessingCode.DOCUMENTATION_FEE_REVERSAL,
    PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE,
    PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE_REVERSAL,
    PismoTransactionProcessingCode.FACILITY_FEE,
    PismoTransactionProcessingCode.FACILITY_FEE_REVERSAL,
    PismoTransactionProcessingCode.CARD_REISSUE_FEE,
    PismoTransactionProcessingCode.CARD_REISSUE_FEE_REVERSAL,
    PismoTransactionProcessingCode.PAYMENT_DISHONOUR_FEE,
    PismoTransactionProcessingCode.PAYMENT_DISHONOUR_FEE_REVERSAL,
    PismoTransactionProcessingCode.LATE_FEE,
    PismoTransactionProcessingCode.CAN_LATE_FEE,
    PismoTransactionProcessingCode.UNDO_CANC_LATE_FEE,
    PismoTransactionProcessingCode.OVERLIMIT_FEE,
    PismoTransactionProcessingCode.CAN_OVERLIMIT_FEE,
    PismoTransactionProcessingCode.UNDO_CANC_OVERLIMIT_FEE,
    PismoTransactionProcessingCode.ANNUAL_FEE,
    PismoTransactionProcessingCode.ANNUAL_FEE_REVERSAL,
    PismoTransactionProcessingCode.ANNUITY,
    PismoTransactionProcessingCode.CAN_ANNUITY,
    PismoTransactionProcessingCode.PAYOUT,
  ].includes(processingCode)) {
    return 'FEE';
  } else if ([
    PismoTransactionProcessingCode.INSTALLMENT_WITHOUT_INTEREST,
    PismoTransactionProcessingCode.INSTALLMENT_WITHOUT_INTEREST_REVERSAL,
    PismoTransactionProcessingCode.INTEREST,
    PismoTransactionProcessingCode.INTEREST_REVERSAL,
    PismoTransactionProcessingCode.INTEREST_ON_REVOLVING_CREDIT,
    PismoTransactionProcessingCode.INTEREST_ON_REVOLVING_CREDIT_REVERSAL,
    PismoTransactionProcessingCode.UNDO_CANC_INTEREST_ON_REVOLVING_CREDIT,
    PismoTransactionProcessingCode.LATE_PAYMENT_INTEREST,
    PismoTransactionProcessingCode.CAN_LATE_PAYMENT_INTEREST,
    PismoTransactionProcessingCode.UNDO_CANC_LATE_PAYMENT_INTEREST
  ].includes(processingCode)) {
    return 'INT';
  } else if ((
    processingCode === PismoTransactionProcessingCode.PAYMENT ||
    processingCode === PismoTransactionProcessingCode.MINIMUM_PAYMENT_REVERSAL ||
    processingCode === PismoTransactionProcessingCode.PAYMENT_REVERSAL)
  ) {
    return 'DEP'
  } else if ([
    PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER,
    PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER_REVERSAL,
    PismoTransactionProcessingCode.BPAY,
    PismoTransactionProcessingCode.BPAY_REVERSAL,
  ].includes(processingCode)) {
    return 'PAYMENT';
  } else if (transaction.transaction_type.credit) {
    return 'CREDIT';
  } else {
    return 'DEBIT';
  }
}

export const getAccountBalance = (balances: PismoReportAccountsBalances[], accountId: number): number => {
  const filteredBalances = balances.filter(balance => balance.Account_ID === accountId);
  return filteredBalances.reduce((a, b) => a + Number(b.Balance), 0);
}

export const getAccountDailyBalance = (balances: PismoReportDailyBalances[], accountId: number): number => {
  const filteredBalances = balances.filter(balance => balance.Account_id === accountId);
  return _.round(filteredBalances.reduce((a, b) => a + Number(b.Balance), 0), 2);
}

export const displayCardReissueReason = (reason: PismoCardReIssueReason): string => {
  switch (reason.type) {
    case "BROKEN":
      return "Card is Damaged";
    case "CLIENT_ORDER":
      return "Card is Expired";
    case "LOST":
      return "Card is Lost";
    case "UNRECEIVED":
      return "I didn't Receive the Card";
    case "DAMAGED":
      return "Card isn’t Working";
    case "THEFT":
      return "Card is Stolen";
    default:
      return reason.description;
  }
}

// creatinoDate: YYYY-MM-DD
export const getUnpaidFacilityFeeTerms = (creationDate: string, terms: number): number => {
  const creation = moment(creationDate);
  const daysInCreationMonth = creation.daysInMonth();
  const dateOfCreationMonth = creation.date();
  const endOfCreationMonth = creation.endOf('month');

  const today = moment();
  const hourOfToday = today.hour();
  const dateOfToday = today.date();
  const daysInThisMonth = today.daysInMonth();
  const endOfThisMonth = today.endOf('month');

  let chargedFacilityFeeThisMonth = false;
  if (daysInThisMonth === 29) {
    if (daysInThisMonth - dateOfToday <= 1) {
      // charged this month
      chargedFacilityFeeThisMonth = true;
    } else if (daysInThisMonth - dateOfToday == 2 && hourOfToday > 13) {
      chargedFacilityFeeThisMonth = true;
    }
  } else {
    if (daysInThisMonth - dateOfToday == 0) {
      // charged this month
      chargedFacilityFeeThisMonth = true;
    } else if (daysInThisMonth - dateOfToday == 1 && hourOfToday > 13) {
      chargedFacilityFeeThisMonth = true;
    }
  }

  // calculate terms
  const paidTerms =
    (daysInCreationMonth - dateOfCreationMonth + 1) / daysInCreationMonth +
    Math.round(endOfThisMonth.diff(endOfCreationMonth, 'months')) -
    (chargedFacilityFeeThisMonth ? 0 : 1);
  return Math.max(0, terms - paidTerms);
}

export const getUnpaidFacilityFee = (creationDate: string, terms: number, creditLimit: number, facilityFeePercentage: number): number => {
  return _.round(getUnpaidFacilityFeeTerms(creationDate, terms) * creditLimit * facilityFeePercentage / 1200, 2);
}


// =========================================================
// === Application Challenger LVR & DSCR Calculator     ====
// =========================================================

export const normaliseToMonthlyAmount = (paymentPeriodAmount: number, paymentPeriod: PaymentPeriodType): number => {
  switch (paymentPeriod) {
    case 'Monthly': {
      return paymentPeriodAmount;
    }
    case 'Weekly': {
      // assume 4 weeks in a month
      // return paymentPeriodAmount * 4;
      return (paymentPeriodAmount * 52 / 12);
    }
    case 'Fortnightly': {
      // assume 2 fortnight in a month
      // return paymentPeriodAmount * 2;
      return (paymentPeriodAmount * 26 / 12);
    }
    case 'Quarterly': {
      // each quater is 3 months
      return paymentPeriodAmount / 3;
    }
  }
  return paymentPeriodAmount;
}

export const normaliseFromMonthyAmount= (monthlyAmount: number, paymentPeriod: PaymentPeriodType) => {
  switch(paymentPeriod) {
    case 'Monthly': {
      return monthlyAmount;
    }
    case 'Weekly': {
      return monthlyAmount / 4;
    }
    case 'Fortnightly': {
      return monthlyAmount / 2;
    }
    case 'Quarterly': {
      return monthlyAmount * 3;
    }
  }
  return monthlyAmount;
}

export const calcApplicationLvrCalculatorValue = (input: LvrCalculatorInput): LvrCalculatorValue => {
  const totalAssets = (input.assets ?? []).reduce((total, asset) => {
    const t = total + asset.value;
    return t;
  }, 0);

  const totalLiabilities = (input.liabilities ?? []).reduce((total, liability) => {
    const t = total + liability.value;
    return t;
  }, 0);

  let proposedLoan = input.proposedLoan;
  let currentLvr: number | null = (totalLiabilities / totalAssets) * 100;
  let proposedLvr: number | null = ((totalLiabilities + proposedLoan) / totalAssets) * 100;
  if (_.isNaN(currentLvr) || !_.isFinite(currentLvr)) {
    currentLvr = null;
  } else {
    currentLvr = _.round(currentLvr, 3);
  }
  if (_.isNaN(proposedLvr) || !_.isFinite(currentLvr)) {
    proposedLvr = null;
  } else {
    proposedLvr = _.round(proposedLvr, 3);
  }
  const v: LvrCalculatorValue = {
    ...input,
    totalAssets,
    totalLiabilities,
    proposedLoan,
    currentLvrPercent: currentLvr,
    proposedLvrPercent: proposedLvr,
  }
  return v;
}

// NOTE: not used
// export const calcApplicationConsumerDscrCalculatorValue = (input: ConsumerDscrCalculatorInput): ConsumerDscrCalculatorValue => {
//   // NOTE: currently there are no calculations
//   return {
//     ...input,
//   }
// }

export const calcApplicationDscrCalculatorValue = (input: DscrCalculatorInput): DscrCalculatorValue => {
  const marginAdoptedPercent = (input.industryEbitda?.percentage ?? 0) + input.adjustmentPercent
  const cashAvailableForDebtServiceMonthly = input.averageMonthlySalesAdopted * (marginAdoptedPercent / 100)
  const totalFinancingCommitmentsMonthly = input.existingDebtCommitmentsMonthly + input.atoPaymentPlanMonthly + input.additionalCommitments + input.proposedCommitmentsMonthly
  const debtServiceCoverRatio = cashAvailableForDebtServiceMonthly / totalFinancingCommitmentsMonthly
  const averageMonthlySalesAdoptedAfterAdjustment = input.averageMonthlySalesAdopted + input.salesAdjustment;
  const proposedLoanAmount = input.loanAmount / averageMonthlySalesAdoptedAfterAdjustment;
  const v: DscrCalculatorValue = {
    ...input,
    marginAdoptedPercent,
    cashAvailableForDebtServiceMonthly,
    totalFinancingCommitmentsMonthly,
    debtServiceCoverRatio,
    proposedLoanAmount
  }
  return v;
}

export const parseString = (text: string): string => {
  return (removeUnprintableChar(text) as string).trim().toLowerCase();
}

export const getDeviceType = (id: string): string | undefined => {
  const deviceTypes: DeviceType = {
    '00': 'Card',
    '01': 'Mobile Network Operator (MNO) controlled removable secure element (SIM or UICC) personalized for use with a mobile phone or smartphone',
    '02': 'Key Fob',
    '03': 'Watch using a contactless chip or a fixed (non-removable) secure element not controlled by the MNO',
    '04': 'Mobile Tag',
    '05': 'Wristband',
    '06': 'Mobile Phone Case or Sleeve',
    '07': 'Mobile phone or smartphone with a fixed (non-removable) secure element controlled by the MNO, for example, code division multiple access (CDMA)',
    '08': 'Removable secure element not controlled by the MNO, for example, memory card personalized for used with a mobile phone or smartphone',
    '09': 'Mobile Phone or smartphone with a fixed (non-removable) secure element not controlled by the MNO',
    '10': 'MNO controlled removable secure element (SIM or UICC) personalized for use with a tablet or e-book',
    '11': 'Tablet or e-book with a fixed (non-removable) secure element controlled by the MNO',
    '12': 'Removable secure element not controlled by the MNO, for example, memory card personalized for use with a tablet or e-book',
    '13': 'Tablet or e-book with fixed (non-removable) secure element not controlled by the MNO',
    '14': 'Mobile phone or smartphone with a payment application running in a host processor',
    '15': 'Tablet or e-book with a payment application running in a host processor',
    '16': 'Mobile phone or smartphone with a payment application running in the Trusted Execution Environment (TEE) of a host processor',
    '17': 'Tablet or e-book with a payment application running in the TEE of a host processor',
    '18': 'Watch with a payment application running in the TEE of a host processor',
    '19': 'Watch with a payment application running in a host processor',
    '20': 'Card',
    '21': 'Phone Mobile phone',
    '22': 'Tablet/e-reader Tablet computer or e-reader',
    '23': 'Watch/Wristband Watch or wristband, including a fitness band, smart strap, disposable band, watch add-on, and security/ID band',
    '24': 'Sticker',
    '25': 'PC PC or laptop',
    '26': 'Device Peripheral Mobile phone case or sleeve',
    '27': 'Tag Key fob or mobile tag',
    '28': 'Jewelry Ring, bracelet, necklace, and cuff links',
    '29': 'Fashion Accessory Handbag, bag charm, and glasses',
    '30': 'Garment Dress',
    '31': 'Domestic Appliance Refrigerator, washing machine',
    '32': 'Vehicle Vehicle, including vehicle attached devices',
    '33': 'Media/Gaming Device Media or gaming device, including a set top box, media player, and television',
    '34': 'Reserved for future form factors'
    // '35' - '99': Reserved for future form factors. Additional descriptions can be added here.
  };

  return deviceTypes[id];
}


// =========================================================================
// === Business and overdraft Preliminary Borrowing Estimation Calculator
// ==========================================================================
export type BusinessPreliminaryBorrowerEstimationInput = {
  monthlyTurnover: number,
  overdueAtoDebt: number,
  numOfMissedPayments: number,
  numOfNonBankCashflowLenders: number,
};
export type BusinessPreliminaryBorrowerEstimationResult = { type: 'failed', } | { type: 'success', min: number, max: number };
export const calcBusinessPreliminaryBorrowingEstimation = (opts: BusinessPreliminaryBorrowerEstimationInput): BusinessPreliminaryBorrowerEstimationResult => {
  const calculatedMax = opts.monthlyTurnover * 1.5;

  if (
    (opts.overdueAtoDebt > opts.monthlyTurnover) ||
    (opts.numOfMissedPayments > 3) ||
    (opts.numOfNonBankCashflowLenders > 1) ||
    (calculatedMax < constants.products.business.minBorrowing)
  ) {
    return {
      type: 'failed',
    }
  }

  const max = _.min([calculatedMax, constants.products.business.maxBorrowing])!;
  const min = (calculatedMax >= constants.products.overdraft.maxBorrowing) ?  // we capped it
    (max - 100000) :
    _.max([opts.monthlyTurnover, constants.products.business.minBorrowing])!;

  return {
    type: 'success',
    min,
    max
  };
}

export type OverdraftPreliminaryBorrowingEstimationInput = {
  monthlyTurnover: number,
  overdueAtoDebt: number,
  numOfMissedPayments: number,
  numOfNonBankCashflowLenders: number,
}
export type OverdraftPreliminaryBorrowingEstimationResult = { type: 'failed' } | { type: 'success', min: number, max: number };
export const calcOverdraftPreliminaryBorrowingEstimation = (opts: OverdraftPreliminaryBorrowingEstimationInput): OverdraftPreliminaryBorrowingEstimationResult => {
  const calculatedMax = (opts.monthlyTurnover * 1.5) - opts.overdueAtoDebt;

  if (
    (opts.overdueAtoDebt > opts.monthlyTurnover) ||
    (opts.numOfMissedPayments > 1) ||
    (opts.numOfNonBankCashflowLenders > 1) ||
    (calculatedMax < constants.products.business.minBorrowing)
  ) {
    return {
      type: 'failed',
    }
  }

  const max = _.min([calculatedMax, constants.products.overdraft.maxBorrowing])!;
  const min = (calculatedMax >= constants.products.overdraft.maxBorrowing) ?  // we capped it
    max - 100000 :
    _.max([opts.monthlyTurnover, constants.products.overdraft.minBorrowing])!;

  return {
    type: 'success',
    min,
    max
  };
}

export const bankTransactionCatogory = (category: string) => {
  // || categories.includes('')
  const categories = category ? category.split(',').map(c => _.toLower(c.trim().replace(/\s/g, '-'))) : [];
  if (categories.includes('wages') || categories.includes('wages-paid-out') || categories.includes('eftpos-terminals')) {
    return 'type-1';
  } else if (categories.includes('loans') || categories.includes('non-sacc-loans')) {
    return 'type-2';
  } else if (categories.includes('other-debits') || categories.includes('uncategorised-debits') || categories.includes('third-party-payment-providers')) {
    return 'type-3';
  } else if (categories.includes('tax') || categories.includes('atm') || categories.includes('refunds-and-returns') || categories.includes('other-income') || categories.includes('government-and-council-services') || categories.includes('subscription-tv') || categories.includes('travel')) {
    return 'type-4';
  } else if (categories.includes('online-retail-and-subscription-services')) {
    return 'type-5';
  } else if (categories.includes('external-transfers')) {
    return 'type-6';
  } else if (categories.includes('superannuation')) {
    return 'type-7';
  } else if (categories.includes('fees')) {
    return 'type-8';
  } else if (categories.includes('utilities')) {
    return 'type-9';
  } else if (categories.includes('groceries')) {
    return 'type-10';
  } else if (categories.includes('dining-out') || categories.includes('education') || categories.includes('automotive') || categories.includes('health') || categories.includes('department-stores') || categories.includes('retail') || categories.includes('home-improvement') || categories.includes('entertainment') || categories.includes('internal-transfer') || categories.includes('pet-care')) {
    return 'type-11';
  } else if (categories.includes('insurance')) {
    return 'type-12';
  } else if (categories.includes('telecommunications')) {
    return 'type-13';
  } else if (categories.includes('transport')) {
    return 'type-14';
  } else if (categories.includes('all-other-credits')) {
    return 'type-15';
  } else {
    return ''
  }
}

export const encodeReferenceForBankstatement =(reference: string, type: 'Individual' | 'Business') => {
  const data = {
    reference,
    type
  }
  return btoa(JSON.stringify(data))
}

export const decodeReferenceForBankstatement = (encodedReference: string): {reference: string, type: 'Individual' | 'Business'} => {
  const data = JSON.parse(atob(encodedReference))
  return data;
}
