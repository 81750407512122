<div class="consumer-app-component">
  <mat-card appearance="outlined">
    <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
      <mat-tab label="APPLICANT"><div *ngTemplateOutlet="applicantTab;"></div></mat-tab>
      <mat-tab label="PARTNER"><div *ngTemplateOutlet="spouseTab"></div></mat-tab>
      <!-- <mat-tab label="GUARANTORS"><div *ngTemplateOutlet="guarantorTab"></div></mat-tab> -->
      <mat-tab label="QUOTE"><div *ngTemplateOutlet="quoteTab"></div></mat-tab>
      <mat-tab label="REFERENCE"><div *ngTemplateOutlet="referenceTab"></div></mat-tab>
    </mat-tab-group>
  </mat-card>
</div>

<ng-template #applicantTab>
  @if (applicationStatus) {
    <div class="row separator">
      @if (applicationStatus==='Under Review') {
        <div class="col-md-3 under-review">
          <status-box type="under-review"></status-box>
        </div>
      }
    </div>
  }

  <div class="section">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Application ID</div>
          <div body>{{getBrokerApplicationId(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Salesforce ID</div>
          <div body>{{getAppSalesforceId(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Broker name</div>
          <div body>{{getAppInfoBrokerEntityName(application)}}</div>
        </cell>
      </div>
    </div>
    @if (isInternalUser()) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Submitter's name</div>
            <div body>
              <user-selection
                [getFunc]="getUsersInCompanyFn"
                (events)="emitChangeSubmitterEvent($event)"
                [companySalesforceId]="application.AppInfoBrokerSalesforceID"
                [userId]="application.UserId">
              </user-selection>
            </div>
          </cell>
        </div>
        @if (application.AdditionalBrokerCorrespondent) {
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Additional Correspondent's name</div>
              <div body>
                <user-selection
                  [getFunc]="getUsersInCompanyFn"
                  (events)="emitChangeAdditionalCorrespondentEvent($event)"
                  [companySalesforceId]="application.AppInfoBrokerSalesforceID"
                  [userId]="application.AdditionalBrokerCorrespondent.UserId">
                </user-selection>
              </div>
            </cell>
          </div>
        }
      </div>
    }
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Broker Contact</div>
          <div body>{{getBrokerContact(application) }}</div>
        </cell>
      </div>
      @if (getInterestRate(application)) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Interest rate</div>
            <div body>{{getInterestRate(application)}}<span class="mdi mdi-percent"></span></div>
          </cell>
        </div>
      }
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Amount financed</div>
          <div body><span class="mdi mdi-currency-usd"></span>{{getAmountFinance(application) | looseCurrency:'':''}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>LVR</div>
          <div body>{{getLVR(application)}}<span class="mdi mdi-percent"></span></div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
  </div>

  <div class="section">
    <div class="row">
      <div class="col-12">
        <risk-analysis [application]="application"></risk-analysis>
      </div>
    </div>
  </div>


  <div class="section">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Finance type</div>
          <div body>{{getFinanceType(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Asset category</div>
          <div body>{{getApplicationAssetCategory(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Asset type</div>
          <div body>{{getAssetType(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      @if (getAssetMake(application)) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Make</div>
            <div body>{{getAssetMake(application)}}</div>
          </cell>
        </div>
      }
      @if (getAssetFamily(application)) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Family</div>
            <div body>{{getAssetFamily(application)}}</div>
          </cell>
        </div>
      }
      @if (getAssetVehicle(application)) {
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Model</div>
            <div body>{{getAssetVehicle(application)}}</div>
          </cell>
        </div>
        <mat-divider [inset]="true"></mat-divider>
      }
    </div>
    <div class="row">
      @if (getAssetYear(application)) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Year</div>
            <div body>{{getAssetYear(application)}}</div>
          </cell>
        </div>
      }
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Asset condition</div>
          <div body>{{getAssetCondition(application)}}</div>
        </cell>
      </div>
      @if (getAssetDescription(application)) {
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Description</div>
            <div body>{{getAssetDescription(application)}}</div>
          </cell>
        </div>
      }
    </div>
    <mat-divider [inset]="true"></mat-divider>
    @if (getAssetAvgRetail(application)) {
      <div class="row">
        @if (getAssetAvgRetail(application)) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Average Retail</div>
              <div body><span class="mdi mdi-currency-usd"></span>{{getAssetAvgRetail(application) | looseCurrency: '':''}}</div>
            </cell>
          </div>
        }
        @if (getAssetGoodRetail(application)) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Good Retail</div>
              <div body><span class="mdi mdi-currency-usd"></span>{{getAssetGoodRetail(application) | looseCurrency: '':''}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }
  </div>



  <div class="section">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Invoice amount</div>
          <div body><span class="mdi mdi-currency-usd"></span>{{getInvoiceAmount(application) | looseCurrency:'':''}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Loan term (in months)</div>
          <div body>{{getLoanTerms(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Repayment Type</div>
          <div body>{{getRepaymentType(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Balloon Payment(Residual)</div>
          <div body><span class="mdi mdi-currency-usd"></span>{{getBalloonPayment(application) | looseCurrency:'':''}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Transaction Type </div>
          <div body>{{getTransactionType(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Equifax Score above 600</div>
          <div body>{{getEquifaxScoreAboveThreshold(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Adverse on file</div>
          <div body>{{getAdverseOnFile(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Property owner</div>
          <div body>{{getPropertyOwner(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Brokerage</div>
          <div body><span class="mdi mdi-currency-usd"></span>
        {{getBrokerageAmount(application) ? getBrokerageAmount(application) : getBrokerage(application) * getLoanAmount(application) / 100 | looseCurrency:'':''}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Doc Fee financed</div>
        <div body>{{getDocFeeFinanced(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Repayment frequency</div>
        <div body>{{getRepaymentFrequency(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Deposit</div>
        <div body><span class="mdi mdi-currency-usd"></span>{{getDeposit(application) | looseCurrency:'':''}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Loan amount</div>
        <div body><span class="mdi mdi-currency-usd"></span>{{getLoanAmount(application) | looseCurrency:'':''}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  @if (getPropertyOwner(application) === 'No') {
    <div class="row" >
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Any commercial loan in the past 3 years</div>
          <div body>{{getPreviousLoan(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
  }
</div>


<div class="section">
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Title</div>
        <div body>{{getApplicantTitle(application)?.name}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>First name</div>
        <div body class=" ellipsis" matTooltip="{{getApplicantFirstName(application)}}">{{getApplicantFirstName(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    @if (getApplicantMiddleName(application)) {
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Middle name</div>
          <div body class=" ellipsis" matTooltip="{{getApplicantMiddleName(application)}}">{{getApplicantMiddleName(application)}}</div>
        </cell>
      </div>
    }
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Last name</div>
        <div body class=" ellipsis" matTooltip="{{getApplicantLastName(application)}}">{{getApplicantLastName(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  @if (getApplicantIdentification(application)) {
  <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Id Type</div>
          <div body class=" ellipsis" matTooltip="{{idTypeValueToLabel(application)}}">{{idTypeValueToLabel(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>{{idNumberLabel}}</div>
          <div body class=" ellipsis" matTooltip="{{getApplicantIdentification(application)?.idNumber}}">{{getApplicantIdentification(application)?.idNumber}}</div>
        </cell>
      </div>
    </div>
  <mat-divider [inset]="true"></mat-divider>
  }

  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Gender</div>
        <div body>{{getApplicantGender(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Date of birth</div>
        <div body>{{displayableDate(getApplicantDob(application))}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row">
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Residential address</div>
        <div body>{{getApplicantResidentialAddress(application)?.address}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Property owner</div>
        <div body>{{booleanToYesNo(getApplicantPropertyOwnerWithAddress(application)?.propertyOwner)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Privacy consent obtained</div>
        <div body>{{booleanToYesNo(getApplicantPrivacyConsent(application))}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
</div>


<div class="section">
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Email address</div>
        <div body>{{getApplicantEmail(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Mobile number</div>
        <div body>{{getApplicantMobile(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Marital status</div>
        <div body>{{getApplicantMaritalStatus(application)?.name}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Number of dependants</div>
        <div body>{{getApplicantNumberOfDependants(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Employment status</div>
        <div body>{{getApplicantEmploymentStatus(application)?.name}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Applicant regular income (after tax)</div>
        <div body>{{getApplicantRegularIncome(application)?.income}} / {{getApplicantRegularIncome(application)?.period?.name}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>


  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Employer</div>
        <div body>{{getApplicantEmployer(application)?.currentEmployer}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Employer contact</div>
        <div body>{{getApplicantEmployer(application)?.currentEmployerContact}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>


  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Time at current employment</div>
        <div body>{{getApplicantEmployer(application)?.yearsAtCurrentEmployer}} years {{getApplicantEmployer(application)?.monthsAtCurrentEmployer}} months</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Guarantor</div>
        <div body>{{booleanToYesNo(getApplicantGuarantor(application))}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
</div>

</ng-template>

<ng-template #spouseTab>
  <individual-details type="spouse-only" [application]="application"></individual-details>
</ng-template>

<!-- <ng-template #guarantorTab>
  <individual-details type="guarantors-only" [application]="application"></individual-details>
</ng-template> -->

<ng-template #quoteTab>
  <consumer-quote [application]="application"
    [amortizationScheduleData]="amortizationScheduleData"
    [repaymentEstimationData]="repaymentEstimationData"
    [paymentChartData]="paymentChartData"
    [amortizationChartData]="amortizationChartData"
    [calculationLog]="calculationLog">
  </consumer-quote>
</ng-template>

<ng-template #referenceTab>
  <references-details [application]="application"></references-details>
</ng-template>
