import { Observable } from 'rxjs';
import {
  CreateCustomerUserData,
  CustomerUser,
  GetUserByEmailFn,
  RoleAccess,
} from './auth-service.domain';
import { Application, GetRbaRateFn } from './application-service.domain';
import { DigitalIdGetApplicationIndividualsFn, GetRateCardDetailsFn } from './component/generate-contract-component.domain';
import {
  ApiResponse,
  CustomerAccessLevel,
  CustomerType,
  PaginablePayloadApiResponse,
  PayloadApiResponse,
  User,
  UserandPriviledges
} from './types.domain';
import { BankingTransaction, NewBPayPaymentBody, NewDirectPaymentBody, NewTransactionResponse } from './banking-service.domain';
import { ValidEmailCheckFn } from '../utils/utils';
import { SyncBankDetailsToSfFn } from './component/ppsr-asset-component.domain';
import { KycStatus } from './digital-id-service.domain';
import { RadioOptionValue } from './component/radio-options-component.domain';
import { BpayComponentValue, GetBillerNameFn } from './bpay.domain';
import { GetInstitutionNameFn, LocalBankAccountDetailValue } from './direct-credit.domain';
import { OverdraftStatementTemplateData } from './docusign-service.domain';
import { CustomerAccessLevelAndRoleAccessValue } from './component/customer-access-level-and-role-access-component.domain';
import { EnableOverdraftUserFn } from './overdraft-user-payee.domain';
import { EnrichedTransaction } from './basiq.domain';
export type GetCustomerFn = (customerId: number) => Observable<CustomerUser | null>;
export type ServiceBusRecordStatus =
  | 'received'
  | 'processed'
  | 'processed-failed';
export interface PismoProgram {
  programId: number;
  name: string;
}
export interface PismoCardMapping {
  id: number,
  userId: number,
  pismoCustomerNumber: number,
  pismoAccountNumber: number,
  pismoCardNumber: number,
  annualFee: number,
  activationTime: string | null,
  lastAnnualFeeChargingTime: string | null,
  addToEmbossing: boolean,
  reissueAddToEmbossing: boolean,
}
export const ANNUAL_FEE_PLASTIC_CARD = 10;
export const ANNUAL_FEE_METAL_CARD = 50;
export type PismoPrograms = PismoProgram[];
export interface PismoIUser {
  roles: (
    | 'account-holder'
    | 'account-server'
    | 'crm-operator'
    | 'crm-server'
    | 'onboarding-server'
    | 'owner'
  )[];
  refresh: string; // refresh token
  token: string; // access token
  tenant: string; // organisationId /tenantId
  email: string;
}

export interface ServerAuthenticationResponse {
  server_key: string;
  tenant: string;
  program_ids: string[];
  roles: string[];
  token: string;
  refresh: string;
}

export interface PismoApplication {
  program_id: number; // static
  due_date: number; // dynamic
  submit: boolean; // true
  engine: string; // "BYPASS"
  applicant: PismoApplicant;
}

export interface PismoApplicant {
  document_number: string;
  birth_date?: string;
  personal?: PismoPersonal;
  company?: PismoCompany;
  account?: PismoAccount;
  addresses: PismoAddress[];
  custom_fields?: PismoCustomFeilds;
  phones?: { phone: string, extension?: string, phone_type: 'RESIDENTIAL' | 'COMMERCIAL' | 'MOBILE' }[];
}

export interface PismoTransactionFlow {
  has_next: boolean;
  items: PismoTransactionFlowItems[];
}

export interface PismoTransactionFlowItems {
  transaction_type_id: number,
  processing_code: string,
  key: string,
  custom_key?: string
}

export interface PismoPersonal {
  external_id?: string;
  name?: string;
  email?: string;
  gender?: string;
  printed_name?: string;
  nickname?: string;
  social_name?: string;
  country_of_birth?: string;
  state_of_birth?: string;
  city_of_birth?: string;
  registration?: Object;
}

export interface PismoCompany {
  external_id?: string;
  name?: string;
  email?: string;
  company_name?: string;
  registration_number?: string;
  activity?: string;
  company_type?: string;
  company_format?: string;
  company_constitution_date?: string;
  occupation?: string;
  income?: number;
  net_worth?: number;
  annual_revenues?: number;
  type?: string;
  number_of_partners?: number;
  perc_ownership?: number;
  fiscal_situation?: string;
  debt?: number;
  partner?: Object;
}

export interface PismoAccount {
  granted_limit?: number;
  limit?: number;
  exchange_mode?: string;
  external_id?: string;
  parent_account_id?: number;
  overdraft_limit?: number;
  account_name?: string;
}

export interface PismoAddress {
  address: string;
  number: number;
  neighborhood: string;
  complementary_address?: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  address_type: 'RESIDENTIAL' | 'COMMERCIAL' | 'OTHER';
  mailing_address?: boolean;
}

export interface PismoPhone {
  phone: string;
  extension?: string;
  phone_type: 'RESIDENTIAL' | 'COMMERCIAL' | 'MOBILE';
}

export interface PismoCustomFeilds {
  [propertyName: string]: any;
}
export interface PismoCreateAccountBody {
  application: PismoApplication;
}

export interface CreateOverdraftAccountBody {
  customer: CustomerUser;
  data: CreateOverdraftAccountData;
  opportunityId: string; // opportunity salesforce id
  payout: Payout[];
}

export interface OverdraftAccountPostFeeBody {
  fee: OverdraftAccountPostFeeType;
  pismoAccountMapping: PismoAccountMapping,
  pismoAccountDetails: { account_id: number, custom_fields: PismoAccountCustomFields }, // PismoGetAccountResponse;
  customer: CustomerUser;
  // only for transferred balance
  accountBalanceType?: OverdraftAccountBalanceType;
  accountBalance?: number;
}

export type OverdraftAccountPostFeeType = 'facility establishment fee' | 'brokerage' | 'documentation fee' | 'documentation fee reversal' | 'transferred balance';

export interface CreateOverdraftUserBody {
  customerUser: CreateCustomerUserData;
  pismoAccountNumber: number;
  monthlyLimit?: number | null;
  entityName: string;
  cardType?: PismoPhysicalCardType;
}

export interface CreateOverdraftUserResponse {
  existingUser: boolean;
  pismoCreateCustomerResponse: PismoCreateCustomerResponse;
}

export interface PismoAccountAndOpportunityResponse {
  pismoAccountDetails: PismoGetAccountResponse;
  opportunityDetails: CustomerOpportunity | null;
}

export interface PismoCreateAccountResponse {
  application_id: number;
  status: number;
  status_name: string;
  account_id: number;
  customer_id: number;
  entity_id: number;
  program_id: number;
}

export interface PismoGetAccountResponse {
  acquisition_id: number; // ID of the application form that originated this account.
  document_number: string; // Document number of the account holder.
  entity_type: 'FISICA' | 'JURIDICA'; // Type of entity this account belongs to. Can be FISICA for natural persons or JURIDICA for legal persons.
  name: string; // Account holder's name.
  email: string; // Account holder's email address.
  org: string; //Tenant ID to which this account belong.
  program_name: string; //Name of the program for which this account was created.
  program_type:
  | 'CREDITO'
  | 'DEBITO'
  | 'PRE-PAGO'
  | 'CONTA-CORRENTE'
  | 'ALIMENTACAO'
  | 'MERCHANT'; //Type of the program for which this account was created.
  // status:
  //   | 'NORMAL'
  //   | 'ACCOUNT_CANCELLATION'
  //   | 'BLOCK_JUDICIAL_BLOCKED'
  //   | 'CANCELLED'
  //   | 'DEFINITIVE_CANCELLATION'
  //   | 'FULL_CANCELLATION'; //Account's current status
  status:
  | 'NORMAL'
  | 'BLOCKED'
  | 'CANCELLED';  //Account's current status
  status_reason_id: number | null; // ID of the reason why the account is in its current status.
  status_reason_description: string | null; // Description of the reason why the account is in its current status.
  collections_status: 'NORMAL' | 'OVERDUE'; // Account's financial status for credit programs.
  open_due_date: string; // Currently open statement's due date, formatted as yyyy-MM-dd.
  current_cicle: number; // Current statement cycle for credit programs.
  current_balance: number; // Current balance for credit programs.
  available_withdrawal_credit: number; // Amount available for withdrawal in credit programs.
  available_monthly_credit: number; //Monthly amount available in credit programs.
  available_total_installment_credit: number; // Amount available for installments in credit programs.
  withdrawal_credit_limit: number; // Amount limit for withdrawal in credit programs.
  monthly_credit_limit: number; // Monthly credit limit in credit programs.
  total_installment_credit_limit: number; // Amount limit for installments in credit programs.
  total_credit_limit: number; // Total credit limit as set by the user. This can be any value up to the max_credit_limit.
  max_credit_limit: number; // Maximum credit amount approved for this account.
  available_limit: number; // Amount available for this account.
  blocked_amount: number | null; // Amount that was blocked in this account.
  postal_address_type: number; // ID of the mailing address registered in this account.
  social_name: string | null; // Customer social name.
  exchange_mode: 'SAME_DAY' | 'CLOSING'; // Whether any foreign transaction should be charged by the exchange rate at the day it occurred (SAME_DAY) or at the statement's closing date (CLOSING)
  customer_id: number; // Customer ID
  external_id: string; // Key that links a Pismo account to your account ID
  creation_date: string; // Account date creation
  program_due_date_id: number; // ID of the due date for this program
  account_id: number; // Account ID
  account_status_date: string | null;
  program_id: number; // ID of the program associated with this account
  parent_account_id: number | null; // ID of the associated parent account
  account_name: string | null;
  custom_fields: PismoAccountCustomFields;
  overdue_amount: number;
  minimum_payment: number; // need this on the product selector screen
}

export interface PismoEntity {
  name: string;
  document_number: string;
  registration?: string;
  birth_date?: string;
  gender?: 'F' | 'M';
  monthers_name?: string;
  marital_status?: string;
  id?: number;
}

export interface PismoCustomer {
  is_owner: boolean;
  printed_name?: string;
  social_name?: string;
  nickname?: string;
  email?: string;
  customer_id?: number;
}

export interface PismoUpdateCustomerBody {
  firstName?: string,
  middleName?: string,
  lastName?: string,
  dob?: string, // YYYY-MM-DD
  mobileNumber?: string,
  email?: string,
  accessLevel?: CustomerAccessLevelAndRoleAccessValue
}

export interface PismoUpdateCustomerResponse {
  portalUpdateUserResponse: UserandPriviledges | null;
  pismoUpdateCustomerInformationResponses: PismoUpdateCustomerInformationResponse[];
  pismoUpdatePhoneResponses: PismoCreatePhoneResponse[];
}

export interface PismoCreateCustomerBody {
  account_id: number;
  entity?: PismoEntity;
  customer?: PismoCustomer;
  monthlyLimit?: number;
  mobileNumber?: string;
}

export interface PismoCreateCustomerResponse {
  accountId: number;
  customerId: number;
  entityId: number;
  message: string;
  data: {
    org_id: string;
    entity?: PismoEntity;
    customer?: PismoCustomer;
  };
}

export interface PismoNewCardDetails {
  name: string;
  printed_name: string;
  type: 'PLASTIC' | 'VIRTUAL' | 'RECURRING' | 'TEMPORARY';
  cvv_rotation_interval_hours?: number; // REQUIRED for a virtual card. Virtual card CVV rotation interval in hours
  transaction_limit?: number; // Optional for virtual cards. Maximum amount allowed per transaction. Set up to the approved limit. If not set, account limit is used.
  metadata?: PismoCustomFeilds;
  embossing_group?: string; // Embossing group name. Clients can work with multi-embossers. You can use this field to notify an embosser about a card request. If this parameter is not passed, and you work with multi-embossers, the default embosser is used.
  embossing_custom_field: string; // to store Address
  contactless_enabled?: boolean; // For physical cards. Card enabled for contactless transactions flag. Default is true. With contactless transactions, you hold or tap the card on contactless-enabled card reader to complete a transaction. This requires that both the card and the terminal have Near Field Communication (NFC) technology. Most embossers can create plastic contactless cards.
  abu_enabled?: boolean; // Card enabled for ABU (automatic billing updater) flag. Mastercard only. Default is true. When a card is created or reissued, or its account credentials change, Mastercard is notified if this feaure is enabled. Notification updates are sent to Mastercard on a daily basis
  template_id?: string; // Template ID. Templates are used to implement the BIN override feature.
  mode_type?: 'SINGLE' | 'COMBO'; // Mode type enum - single or combo. A combo card can have both a credit and debit mode, meaning it is associated with both a credit and debit program and account. A single card has one mode - credit or debit.
}

export interface PismoUpdateCardInfoBody {
  name?: string;
  printed_name?: string;
  transaction_limit?: number;
  contactless_enabled?: boolean;
  abu_enabled?: boolean;
  metadata?: PismoCustomFeilds;
}

export interface PismoCardCreationResponse {
  id: number; // card id
  name: string; // card alias name
  status: PismoCardStatus;
  type: PismoCardType;
  cvv_rotation_interval_hours?: number; // REQUIRED for a virtual card. Virtual card CVV rotation interval in hours
  transaction_limit?: number; // Optional for virtual cards. Maximum amount allowed per transaction. Set up to the approved limit. If not set, account limit is used.
  metadata?: PismoCustomFeilds;
  embossing_custom_field?: string; // For physical cards. Additional information for embossing company, e.g., tracking number or whether the card should be plastic or metal.
  contactless_enabled?: boolean; // For physical cards. Card enabled for contactless transactions flag. Default is true. With contactless transactions, you hold or tap the card on contactless-enabled card reader to complete a transaction. This requires that both the card and the terminal have Near Field Communication (NFC) technology. Most embossers can create plastic contactless cards.
  abu_enabled?: boolean; // Card enabled for ABU (automatic billing updater) flag. Mastercard only. Default is true. When a card is created or reissued, or its account credentials change, Mastercard is notified if this feaure is enabled. Notification updates are sent to Mastercard on a daily basis
  mode_type?: 'SINGLE' | 'COMBO'; // Mode type enum - single or combo. A combo card can have both a credit and debit mode, meaning it is associated with both a credit and debit program and account. A single card has one mode - credit or debit.
}

export type PismoCardType = 'PLASTIC' | 'VIRTUAL' | 'RECURRING' | 'TEMPORARY';
export type PismoPhysicalCardType = 'plastic' | 'metal';

export interface PismoCreateCardBody {
  accountId: number;
  customerId: number;
  // cardDetails: PismoNewCardDetails;
  cardType: PismoPhysicalCardType;
  monthlyLimit: number | null;
}

export interface PismoCardDetails {
  id: number; // card id
  hash: string; // Encrypted PAN (primary account number)
  status: PismoCardStatus;
  stage: 'CREATED' | 'BLOCKED' | 'UNBLOCKED'; // Card status stage
  printed_name: string;
  name: string; // Card alias name
  type: PismoCardType;
  bin: string; // Bank identification number
  brand: string; // Network brand, i.e., Visa, Mastercard
  template_id?: string; // Template ID. Templates are used to implement the BIN override feature. For more information, see the BIN override guide.
  program_id: string; // Program ID
  issuing_date: string; // Date/time when card was issued in RFC3339 format, i.e., 2019-07-03T17:23:18Z
  transaction_limit?: number; // For virtual cards. Maximum amount allowed per transaction.
  expiration_date: string; // Card expiration date in RFC3339 format, i.e., 2022-07-03T00:00:00Z
  last_4_digits: string; // Last four PAN digits
  track_number: number; // Sequential counter incremented every time card is reissued.
  reissued_card_id: number; // Last issued Card Id
  contactless_enabled: boolean; // For physical cards. Card enabled for contactless transactions flag. Default is true. With contactless transactions, you hold or tap the card on contactless-enabled card reader to complete a transaction. This requires that both the card and the terminal have Near Field Communication (NFC) technology. Most embossers can create plastic contactless cards.
  abu_enabled: boolean; // Card enabled for ABU (automatic billing updater) flag. Mastercard only. Default is true. When a card is created or reissued, or its account credentials change, Mastercard is notified if this feaure is enabled. Notification updates are sent to Mastercard on a daily basis
  dual_message_debit: boolean; // For Mastercard - if card is DMC, where the debit has authorization and confirmation at different times, base I and base II, respectively. Unlike Mastercard's Maestro debit, where authorization and confirmation take place at the same time (base I). Default is false.
  mode_type: 'SINGLE' | 'COMBO'; // Mode type enum - single or combo. A combo card can have both a credit and debit mode, meaning it is associated with both a credit and debit program and account. A single card has one mode - credit or debit.
  mode: 'CREDIT' | 'DEBIT';
  embossing_custom_field?: string; // For physical cards. Additional information for embossing company, e.g., tracking number or whether the card should be plastic or metal.
  metadata?: PismoCustomFeilds;
}

export interface PismoCardReponse {
  status: boolean;
  cards: PismoCardDetails[];
}

export interface PismoCardDetailsWithAccountId extends PismoCardDetails {
  accountId: number;
  customerId: number;
  cardlimit: PismoFlexControlResponse[];
  spending?: PismoCurrentCardSpending;
  userId?: number,
  pismoCustomerNumber?: number,
  pismoAccountNumber?: number,
  pismoCardNumber?: number,
  annualFee?: number,
  activationTime?: string | null,
  lastAnnualFeeChargingTime?: string | null,
  addToEmbossing?: boolean,
  reissueAddToEmbossing?: boolean,
}

export interface PismoCardDetailsWithAccountIdMobileNumber extends PismoCardDetailsWithAccountId {
  MobileNumber?: string;
}

export interface PismoReissueCardDetails {
  reason_id: number; // reason id
  printed_name: string; // Printed name on the card
  cvv_rotation_interval_hours?: number; //REQUIRED for a virtual card. Virtual card CVV rotation interval in hours
  embossing_group?: string; // Embossing group name. Clients can work with multi-embossers
  embossing_custom_field?: string; // For physical cards. Additional information for embossing company, e.g., tracking number or whether the card should be plastic or metal.
  contactless_enabled: boolean; //For physical cards. Card enabled for contactless transactions flag.
  metadata?: PismoCustomFeilds;
  abu_enabled: boolean; // Card enabled for ABU (automatic billing updater) flag. Mastercard only. Default is true.
  template_id?: string;
  mode_type?: 'SINGLE' | 'COMBO';
  update_tokens?: boolean;
}

export interface PismoFlexControlBody {
  type: 'spending_limit' | 'usage_limit' | 'restriction';
  name: string; // flex control name
  account_id: number;
  processing_codes?: string[]; // List of processing codes for evaluating flex control conditions.
  rule_reference_id?: string; // If rule_reference_id is set, this flex control is controlled by the program-level flex control.
  currency_code?: string; // Flex control currency code (ISO 4217).
  max_limit?: number | null;
  // Note that this value is an integer. Since global currencies vary in the number of digits that are allowed after the decimal point, you must convert the actual maximum spending limit into an integer value to use in this field. To do this, use the following formula
  // value = floatNumber * 10^x, where x is the number of digits for the currency as defined in ISO 4217.
  // For example, if you want to set the value 499.99 for BRL currency transactions, you must set 49999 = 499.99 * 10^2.
  limit_duration?: string; // Flex control limit period duration (ISO 8601 format).
  time_zone?: string; // IANA Time Zone database, such as "America/New_York". This attribute is used to evaluate the flex control using the desired time zone for condition attributes such as week_day, month_day, time_now and reset_period. If time_zone is empty, the control is evaluated using the UTC time zone.
  reset_period?: {
    // Allows the specification of reset periods for accumulators based on the duration in limit_duration. Note: When reset_period changes, the avaliable_limit will be reset.
    month_day?: number;
    week_day?: string; // monday to sunday
    time?: string;
    utc_time?: string;
  };
  card_ids?: number[]; // List of card identifiers.
  conditions?: {
    id?: string;
    attribute: string; // balance amount merchant_category_code week_day month_day time_now entry_mode merchant_id currency_code
    operator: 'eq' | 'neq' | 'lt' | 'lte' | 'gt' | 'gte' | 'in' | 'nin';
    value: string;
  }[];
  active?: boolean; // If not specified, defaults to 'true' and the flex control is active. If set to 'false', the flex control is skipped during evaluation.
  deny_code?: string; // Deny code related to restriction. This code is returned when the evaluation does not meet the conditions.
}

export interface PismoFlexControlResponse {
  id: string;
  type: 'spending_limit' | 'usage_limit' | 'restriction';
  processing_codes?: string[]; // List of processing codes for evaluating flex control conditions.
  currency_code: string; // Flex control currency code (ISO 4217).
  rule_reference_id?: string; // If rule_reference_id is set, this flex control is controlled by the program-level flex control.
  name?: string; // flex control name
  account_id: number;
  customer_id?: number;
  card_ids?: number[];
  country_codes?: string[];
  max_limit?: number;
  limit_duration?: string; // Flex control limit period duration (ISO 8601 format).
  time_zone?: string;
  reset_datetime?: string;
  reset_period?: {
    // Allows the specification of reset periods for accumulators based on the duration in limit_duration. Note: When reset_period changes, the avaliable_limit will be reset.
    month_day?: number;
    week_day?: string; // monday to sunday
    time?: string;
    utc_time?: string;
  };
  available_limit: number;
  conditions?: {
    id?: string;
    attribute: string; // balance amount merchant_category_code week_day month_day time_now entry_mode merchant_id currency_code
    operator: 'eq' | 'neq' | 'lt' | 'lte' | 'gt' | 'gte' | 'in' | 'nin';
    value: string;
  }[];
  active?: boolean; // If not specified, defaults to 'true' and the flex control is active. If set to 'false', the flex control is skipped during evaluation.
  deny_code?: string; // Deny code related to restriction. This code is returned when the evaluation does not meet the conditions.
}

export interface PismoCustomerWithAccountId extends PismoCustomer {
  account_id: number;
}

export interface PismoListAccountCustomersResponse {
  current_page: number;
  per_page: number;
  items: PismoCustomerWithAccountId[];
}

export interface PismoListAccountCustomersResponse {
  program_id: number;
  account_id: number;
  entity: PismoEntity;
  customer: {
    id: number;
    is_owner: boolean;
    printed_name?: string;
    social_name?: string;
    nickname?: string;
    email?: string;
    customer_id?: number;
    addresses: {
      address_id: number;
      type: 'RESIDENTIAL' | 'COMMERCIAL' | 'OTHER';
    }[];
    phones: {
      phone_id: number;
      type: 'RESIDENTIAL' | 'COMMERCIAL' | 'MOBILE';
    }[];
  };
}

export interface PismoCardActivatedResponse {
  message: string;
}

export interface PismoActivateCardBody {
  accountId: number;
  customerId: number;
  cardId: number;
  cardNumber: string;
}

export type PismoCardStatus =
  | 'CREATED'
  | 'UNBOUND'
  | 'NORMAL'
  | 'REISSUED'
  | 'BLOCKED'
  | 'PENDING'
  | 'WARNING'
  | 'LOST'
  | 'ROBBED'
  | 'EXPIRED'
  | 'DAMAGED'
  | 'FRAUD'
  | 'CANCELED'
  | 'DELETED'
  | 'BROKEN'
  | 'THEFT'
  | 'DEFECT';

export interface PismoUpdateCardStatusResponse {
  program: { id: string };
  account: { id: string };
  customer: { id: string };
  org: { id: string };
  id: number; // Update ID
  hash: string; // Encrypted PAN (primary account number)
  last_4_digits: string; // Last 4 PAN digits
  first_4_digits: string; // First 4 PAN digits
  status: PismoCardStatus;
  stage: 'CREATED' | 'BLOCKED' | 'UNBLOCKED'; // Card status stage
  issuing_date: string; // Date/time when card was issued in RFC3339 format, i.e., 2019-07-03T17:23:18Z
  expiration_date: string; // Card expiration date (format:yymm)
  printed_name: string; // Printed/embossed card name.
  name: string; // Card alias name
  tracking: number; // File sent to embosser tracking ID
  track_number: number; // Sequential counter incremented every time card is reissued.
  transaction_limit?: number; // Optional for virtual cards. Maximum amount allowed per transaction. Set up to the approved limit. If not set, account limit is used
  embossing_custom_field: string; // For physical cards. Additional information for embossing company, e.g., tracking number or whether the card should be plastic or metal.
  contactless_enabled: boolean; // For physical cards. Card enabled for contactless transactions flag. Default is true. With contactless transactions,
  //you hold or tap the card on contactless-enabled card reader to complete a transaction. This requires that both the card and the terminal have Near
  // Field Communication (NFC) technology. Most embossers can create plastic contactless cards.
  abu_enabled: boolean; // Card enabled for ABU (automatic billing updater) flag. Mastercard only. Default is true.
  reissued_card_id: string; // Reissued card ID
  type: PismoCardType;
  metadata?: PismoCustomFeilds;
  template_id?: string; // Template ID. Templates are used to implement the BIN override feature. For more information, see the BIN override guide.
  valid_until: string; // Card expiration in UTC time (format:yyyy-MM-ddThh:mm:ssZ). For TEMPORARY virtual cards, which have a limited time frame,
  // typically 24 hours, which is why the date/time here is more granular than expiration_date. TEMPORARY cards are being DEPRECATED,
  // and it is suggested you use regular VIRTUAL cards instead.
  bulk_id: string; // Noname bulk/batch ID
  external_id: string; // Client internal tracking ID
  mode: 'CREDIT' | 'DEBIT' | 'MULTIPLE' | 'COMBO';
  dual_message_debit: boolean; // Flag to indicate if debit card is using dual message debit or not.
}

export interface PismoTransaction {
  id: number; // Transaction ID
  program_id: number; // Program ID
  account_id: number; // Account ID
  statement_id: number; // Statement ID
  installment: number; // Transaction installment number
  number_of_installments: number; // Total number of installments
  soft_descriptor: string; // Soft descriptor of transaction. This is the description that shows on the customer's statement after it is authorized, but before it is settled.
  processing_code: PismoTransactionProcessingCode; // Processing code used to create the transaction.
  processing_description: string; // Processing code description
  customer_id: string; // Customer ID
  user_category: string; // User category
  transaction_group: string; // Transaction group
  amount: {
    type: string;
    currency: string;
    value: number;
  }[];
  tax: {
    type: string;
    value: number;
  }[];
  event_date: string; // Event date in UTC (ISO 8601)
  payment_date: string; // Payment date and time informed by user, in ISO 8601
  created_at: string; // Transaction creation date (ISO 8601)
  transaction_type: PismoTransactionType;
  card: {
    id: number;
    name: string;
  };
  authorization: PismoAuthorization;
  merchant?: PismoMerchant;
  metadata?: PismoCustomFeilds;
  enrichedTransaction?: EnrichedTransaction;
}

export interface PismoPendingTransaction {
  type: string,
  account_id: number,
  category: string,
  isCredit?: boolean,
  tenant_account_timestamp: string,
  enrichedTransaction?: EnrichedTransaction,
  isPending?: boolean;
  data: {
    item: {
      contract_amount: number,
      response_code: string,
      validation_results: any[];
      merchant_city: string,
      dcc: boolean,
      org_operation: {
        processing_code: PismoTransactionProcessingCode
      },
      program_id: number,
      entry_mode: string,
      number_of_installments: number,
      principal_amount: number,
      settlement_currency_literal: string,
      currency_code: string,
      cardholderbilling_amount: number,
      ledger_update_id: string,
      settlement_currency_code: string,
      network: string,
      pre_authorization: boolean,
      merchant_category_group: string,
      soft_descriptor: string,
      installments: [
        {
          date: string,
          amount: number
        }
      ],
      currency_literal: string,
      authorization_code: string,
      cash_back_amount: number,
      airport_tax: number,
      interest_rate: number,
      postings: any[];
      id: number;
      mti: string;
      installment_amount: number;
      to_name: string;
      merchant_category_code: string,
      rates: {
        conversion_rate: number,
        cardholder_conversion_rate: number,
        intl_purchase_fx_rate: number
      },
      settlement_currency_rate: number,
      merchant_state_or_country: string,
      card_type: string,
      card_id: number,
      cardholder_currency_rate: number,
      local_amount: number,
      card_name: string,
      entry_mode_literal: string,
      customer_id: number,
      settlement_currency_amount: number,
      token_information?: {
        network_requestor_id: string,
        network_token: string
      }
    }
  };
  correlation_id: string,
  timestamp: string,
  metadata?: PismoCustomFeilds,
  processing_code?: PismoTransactionProcessingCode
}

export type PismoTimeline = PismoPendingTransaction;

export type PismoServiceBusNetworkTransaction = {
  account_id: number;
  customer_id: number;
  card: {
    id: number;
  };
  soft_descriptor: string;
  created_at: string;
  event_date: string;
  authorization: {
    id: number;
  };
  merchant: {
    category: {
      code: string;
    },
    name: string
  };
  amount: { value: number }[];
  processing_code: string;
  currency?: string;
}

export type PismoServiceBusPaymentTransaction = {
  account_id: number;
  descriptor: string;
  created_at: string;
  authorization: {
    id: number;
  };
  amount: { value: number }[];
  processing_code: string;
  metadata: {
    AccountName: string;
    AccountNumber: string;
    BSB: string;
    BillerCode: string;
    CRN: string;
    Description: string;
    OD_PAYMENT_COLLECTED_BY: string;
    OD_PAYMENT_TIME: string;
  },
  tracking_id?: string;
}

export type PismoGenericTransaction = PismoTransaction | PismoPendingTransaction | PismoServiceBusNetworkTransaction | PismoServiceBusPaymentTransaction;

export type PismoTransactionsIncludingPending = (PismoTransaction | PismoPendingTransaction)[]

export type PismoTransactionIncludingPending = PismoTransaction | PismoPendingTransaction;

export interface PismoTransactionType {
  id: number; // Transaction type ID
  description: string; // Transaction type description
  credit: boolean; // Indicates if the transaction type is a credit or debit operation.
  posted_transaction: boolean; // Indicates whether transactions are posted on statements (true) or not (false).
  balance_impact_id: number; // Balance impact ID
  category: string; // Transaction type category
}

export interface PismoAuthorization {
  id: number; // Authorization ID
  type: 'PLATFORM' | 'NETWORK'; // Authorization's type
  code: string; // Authorization code or Network Approval Code
  payment_method_id: string; // Payment method
  network: 'VISA' | 'MASTERCARD'; // Visa or Mastercard network
  tracking_id: string; // Idempotency tracking ID
}

export interface PismoMerchant {
  id: string; // Merchant ID
  type: string; // Merchant type
  name: string; // Merchant name
  city: string; // Merchant City
  state: string; // Merchant state
  category: {
    code: string; // Merchant category code (MCC). Provided by the network (Visa or Mastercard).
    description: string; // Merchant category description
    group_name: string; // Merchant category group name
    network_group: string; // Merchant category group name sent by network
  };
}

export interface PismoListTransactionsResponse {
  has_next: boolean;
  items: PismoTransaction[];
}

export interface PismoPendingTransactionResponse {
  has_next: boolean;
  next_cursor: string;
  count: number;
  items: PismoPendingTransaction[];
}

export interface PismoPaymentBody {
  from: [PismoPaymentMethodAccount | PismoPaymentMethodExternal | PismoPaymentMethodCard]; // (PismoPaymentMethodAccount | PismoPaymentMethodCard | PismoPaymentMethodExternal | PismoPaymentMethodMerchant)[];
  to: [PismoPaymentMethodAccount | PismoPaymentMethodExternal | PismoPaymentMethodCard]; // (PismoPaymentMethodAccount | PismoPaymentMethodCard | PismoPaymentMethodExternal | PismoPaymentMethodMerchant)[];
  tracking_id?: string; // Unique, 36-character, transaction ID. If not provided, a random one is generated.
  entry_mode?: string; // Payment entry mode.
  location?: PismoLocation; // Request latitude and longitude object.
  metadata?: PismoCustomFeilds;
  capture?: boolean; // If set to false, the operation will be a pre-authorization and won't generate a transaction until the Capture pre-authorization endpoint is called. Works only for cash-out flows. Defaults to true.
  token?: string; // Validation token used for payment-requests transfers.
  // NOTE: can pass it in either YYYY-MM-DD or YYYY-MM-DD hh:mm:ss format
  payment_datetime?: string; // Value date (effective date) of the payment in UTC-0 (RFC3339) format. Payments can be backdated but future dates are not allowed. example: '2020-01-02T15:14:00.218Z'
  descriptor?: string; // Optional description for created transactions.
  timeout?: number; // Maximum time (in seconds) a payment can take. Optional field forwarded to acquirers.
}

export interface PismoPaymentMethodAccount {
  account: {
    id: number;
  };
  amount: number; // Credit or debit amount.
  processing_code?: string; // Optional for use with private acceptance/acquiring merchant networks.
  //Can be used to define a different settlement condition (MDR, days to settle, and so on). For details, see acquiring/marketplace documentation.
  currency?: string; // Currency code of the requested operation, in ISO 4217.
  beneficiary_id?: string; // Unique string identifier for the beneficiary associated with the account.
}

export interface PismoPaymentMethodCard {
  card: {
    id: string; // Card id on file (in digital wallet) ID.
    account_id: number;
    acquirer_id?: string; // Acquirer ID for operation validation.
    installments?: {
      number: number; // Number of installments.
      deffered_months: number; // Number of months before first installment.
    };
    acquirer?: {
      business_application_identifier: string; // Business application type ID (for REDE acquirer ONLY).
      sub_merchant: {
        merchant_category_code: string; // 4-digit merchant category code (ISO 18245) based on goods or services the merchant sells the most (for REDE acquirer ONLY).
      };
      wallet?: {
        id: string; // Card's wallet ID (for REDE acquirer ONLY).
        sender_tax_identification: string; // Document number of wallet owner (for REDE acquirer ONLY).
        processing_type: number; // Identifies the type of the operation (for REDE acquirer ONLY).
      };
      capture?: boolean; // Should authorization be auto-captured flag. Default is true. (For REDE acquirer ONLY.)
      credential?: {
        code: string; // UUID key REDE acquirer uses to get authentication information. Only validated if passed.
      };
    };
  };
  amount: number; // Credited or debited amount.
  processing_code?: string; // Optional for use with private acceptance/acquiring merchant networks.
  // Can be used to define a different settlement condition (MDR, days to settle, etc).
  // For details, see acquiring/marketplace documentation.
  currency?: string; // Currency code of the requested operation, in ISO 4217.
}
export interface PismoForcePaymentBody {
  tracking_id?: string;
  account_id?: number;
  amount?: number;
  processing_code?: string;
  currency?: string;
  descriptor?: string;
  metadata?: PismoCustomFeilds;
}
export interface PismoForcePaymentResponse {
  authorization_id: number;
  event_date: string;
  tracking_id: string;
  error?: string;
  message?: string;
}
export interface PismoPaymentMethodExternal {
  custom_info: {
    external_id: string; // Custom information ID
    type: string; // Custom information type
    name?: string; // Custom information name.
    description?: string; // Custom information description.
    origin?: string; // Origin used in migrations.
    accounting_date?: string; // Accounting date used in migrations.
  };
  amount: number; // Credited or debited amount.
  processing_code?: string; // Optional for use with private acceptance/acquiring merchant networks.
  // Can be used to define a different settlement condition (MDR, days to settle, etc).
  // For details, see acquiring/marketplace documentation.
  currency?: string; // Currency code of the requested operation, in ISO 4217.
}

export interface PismoPaymentMethodMerchant {
  tracking_id: string; // Unique, 36-character, transaction ID. If not provided, a random one is generated.
  entry_mode?: string; // Payment entry mode.
  location?: PismoLocation;
  metadata?: PismoCustomFeilds;
  capture?: boolean; // If set to false, the operation will be a pre-authorization and won't generate a transaction until the Capture pre-authorization endpoint is called. Works only for cash-out flows. Defaults to true.
  token?: string; // Validation token used for payment-requests transfers.
  payment_datetime?: string; // Value date (effective date) of the payment in UTC-0 (RFC3339) format. Payments can be backdated but future dates are not allowed.
  // example: '2020-01-02T15:14:00.218Z'
  descriptor?: string; // Optional description for created transactions.
  timeout?: number; // Maximum time (in seconds) a payment can take. Optional field forwarded to acquirers.
}

export interface PismoLocation {
  lat: number; // Request latitude.
  lng: number; // Request longitude.
}

export interface PismoUpdateCardPin {
  pinblock: string;
  key_id: string;
}

export interface PismoCardReIssueReason {
  id: number;
  description: string;
  type: string;
  must_charge: false;
  must_block: false;
  cost: string;
}

export interface PismoChangeCardStatus {
  status: PismoCardStatus;
}

export type PismoPaymentResponse = {
  statusCode: number;
  body: PismoPaymentSuccessResponse | PismoErrorResponse;
}

export interface PismoPaymentSuccessResponse {
  authorization_ids: {
    // Credit and debit authorization IDs.
    credit: number; // Credit authorization ID.
    debit: number; // Debit authorization ID.
  };
  authorization_id: number;
  event_date: string; // Transaction datetime in UTC-0 (RFC3339) format.
  tracking_id: string; // Transfer ID. If none provided in the request, a random one is generated.
  available_credit_limit: number; // Available credit limit after transaction.
}

export interface PismoErrorResponse {
  message: string;
  error_code: string;
}

export interface PismoCancelPaymentBody {
  authorization_id: number; // Credit or debit authorization ID (authorization_ids.credit or authorization_ids.debit) returned in the response of the original transfer from the Transfer funds endpoint.
  // Either this field or the original_tracking_id field is REQUIRED to identify the original transfer that needs to be canceled with this request.
  tracking_id?: string; // Unique 36-char ID of the pending cancellation. If not provided, the Pismo platform generates the UUID randomly. This field is useful for tracking and searching transaction information.
  original_tracking_id?: string; // Unique 36-character ID for the original operation to be cancelled, which is returned from the Transfer funds endpoint as tracking_id.
  amount: number; // Full or partial amount to cancel, which cannot be more than the original transaction amount. Maximum of two decimal places.
  cancel_fees?: boolean; // Indicates whether to cancel associated fees when canceling a transfer operation.
  // When set to true, if a total or partial cancellation of an operation with a fee occurs, the platform automatically cancels the associated fee also.
  processing_code?: {
    // This optional field identifies the processing codes to be used in the cancellation operation.
    // Depending on the type of transaction you want to cancel, this object can contain only one subfield of string type: credit or debit.
    credit: string;
    debit: string;
  };
  acquirer?: {
    credential: {
      code: string;
    };
  };
  metadata?: PismoCustomFeilds;
  descriptor?: string;
}

export interface PismoCancelPaymentResponse {
  tracking_id: string;
  authorization_ids: {
    credit: number;
    debit: number;
  };
  metadata?: PismoCustomFeilds;
}

export interface PismoCurrentStatement {
  id: number; // Statement ID
  cycle: number; // Statement cycle
  due_date: string; // Statement due date, format = yyyy-mm-dd
  best_transaction_date: string; // Statement best transaction date, format = yyyy-mm-dd
  minimum_payment_amount: number; // Statement minimum payment amount
}

// statementId: 440222888
export interface PismoStatement {
  statement_id: number; // Statement ID
  cycle?: number; // Cycle number
  credits: number; // Statement total credit amount (does not include credits from previous statements)
  debits: number; // Statement total debit amount (does not include debits from previous statements)
  current_balance: number; // Current statement balance = previous_balance + (debits - credits)
  previous_balance: number; // Previous statement balance
  total_due_amount: number; // Statement total debit amount (does not include debits from previous statements).
  min_upfront_amount: number; // Statement total debit amount (does not include debits from previous statements).
  due_date: string; // Statement due date, format = yyyy-mm-dd
  cycle_closing_date: string; // Statement cycle closing date, format = yyyy-mm-dd
  cycle_opening_date: string; // Statement cycle opening date, format = yyyy-mm-dd
  posted_date: string; // Statement posting date, format = yyyy-mm-dd
  month_due_date: string; // Statement's month of reference
  month_index_number: number; // Statement's month index number (1-12)
  year_due_date: number; // Statement's year of reference
  payment_status: 'PAID' | 'NOT_PAID'; // Statement payment status. Possible values are PAID and NOT_PAID
  international_debts: number; // Total amount of debts obtained from international currency transactions
  exchange_rate: number; // Current exchange rate for international debts
  eligible_for_installments: boolean; // Are statements on this account eligible for installment payments flag
  max_number_of_installments: number; // Maximum number of installments allowed for this account
  minimum_payment?: number; // Minimum payment
  calendar: PismoCalendar;
}

export interface PismoCalendar {
  calendar_id: number; // Calendar ID
  program_due_date_id: number; // Program due date ID
  program_id: number; // Program ID
  cycle_closing_date: string; // Cycle closing date
  due_date: string; // Due date
  real_due_date: string; // Real due date
}

export interface PismoListStatementsResponse {
  items: PismoStatement[];
  current_page: number; // Current page number.
  per_page: number; // Number of items requested. The default is 10 if nothing is passed.
  total_items: number; // Total elements found for request
  items_on_page: number; // Total elements existing on this page
  pages: number; // Total pages for request
  is_last_page: boolean; // Is current page the last page flag
}

export interface MobileAppHomeData {
  cards: PismoCardDetails[];
  account: PismoGetAccountResponse;
  transactions: PismoTransaction[];
}

export type PismoEventBusMessage =
  | PismoEventBusMessageNewBucketObject
  | PismoEventBusMessageRotateCvv
  | PismoEventBusMessageNetworkTransaction
  | PismoEventBusMessageAuthorizationEvent
  | PismoEventBusMessageCycleClosingEvent
  | PismoEventBusMessageCardUpdateEvent
  | PismoEventBusMessageDelinquencyBucketCreatedEvent
  | PismoEventBusMessageDelinquencyBucketUpdatedEvent
  | PismoEventBusMessageAccountingEntryCreationEvent
  | PismoEventBusMessageNeworkAuthorizationClearing
  | PismoEventBusMessageTokenActivationEvent

export interface GenericPismoEventBusMessage<D> {
  event_id: string;
  org_id: string;
  schema_version: number;
  cid: string;
  timestamp: string;
  data: D;
}

export interface PismoEventBusMessageNewBucketObject
  extends GenericPismoEventBusMessage<{
    bucket_name: string;
    object_name: string;
    object_size: number;
  }> {
  event_type: 'new_bucket_object';
  domain: 'dataplatform';
}

export interface PismoEventBusMessageRotateCvv
  extends GenericPismoEventBusMessage<{
    account_id: number;
    program_id: number;
    card_id: number;
    external_id: number | null;
    bulk_id: number | null;
    card_name: string;
    card_printed_name: string;
    card_type: PismoCardType;
    card_last_four_numbers: string;
    card_hash: string;
    card_status: PismoCardStatus;
    transaction_limit?: number | null;
    embossing_custom_fields: string | null;
    issuing_date: string;
    expiration_date: string;
    embossed: string | null;
    status: string;
    reason: string | null;
    customer_id: number;
    contactless_enabled: boolean;
    abu_enabled: boolean;
    metadata: PismoCustomFeilds | null;
    cvv_rotation_type: string;
    cvv_next_rotation_date: string;
    reissued_card_id: string | null;
    reissued_card_block: string | null;
    migration_id: number | null;
    card_bin: string | null;
    card_brand: string | null;
    template_id?: string | null;
    mode: 'CREDIT' | 'DEBIT' | 'MULTIPLE' | 'COMBO' | null;
    operation_type: string | null;
    embossing_group: string | null;
    dual_message_debit: boolean | null;
    track_number: number | null;
    card_previous_status: string | null;
  }> {
  event_type: 'rotate_cvv';
  domain: 'cards';
}

export interface CreateOverdraftAccountDialogData {
  customerId: number;
  overdraftCustomerSfRecordId: string; // sf overdraft customer recordId
  overdraftSfOpportunityStage: string; // sf opportunity stage to show in dropdown
  // customer: CustomerUser | null;
  existingPismoAccountMappings: PismoAccountMapping[];
  getOpportunitiesForCustomerFn: GetOpportunitiesForCustomerFn;
  getPismoProgramDueDate: GetPismoProgramDueDate;
  getAccountDetailsFromSfFn: GetAccountDetailsFromSfFn;
  createOverdraftAccountFn: CreateOverdraftAccountFn;
  getPismoProgramsFn: getPismoProgramsFn;
  syncBankDetailsToSfFn: SyncBankDetailsToSfFn;
  getRbaRateFn: GetRbaRateFn;
  getCustomerFn: GetCustomerFn;
  getBillerNameFn: GetBillerNameFn;
  getInstitutionNameFn: GetInstitutionNameFn;
  // getPismoAccountDetailsFn: GetPismoAccountDetailsFn;
}
export interface PismoEventBusMessageNetworkTransaction
  extends GenericPismoEventBusMessage<{
    authorization_category: 'AUTHORIZATION' | 'DECLINED';
    authorization_type: 'NETWORK';
    org_id: string;
    cid: string;
    unique_cid: string;
    event_date_hour: string;
    event_date_hour_utc: string;
    authorization_date_hour: string;
    original_authorization_date_hour: string;
    original_event_date_hour_utc: string;
    account_id: number;
    card_id: number;
    program_id: number;
    org_operation_id: number;
    authorization_id: number;
    customer_id: number;
    type: 'AUTH_EVENT';
    caller: 'MASTERCARD';
    mti: string;
    pan: string;
    nsu: string;
    retrieval_reference_number: string;
    authorization_code: string;
    authorization_response_code: '00';
    processing_code: string;
    principal_amount: number;
    contract_amount: number;
    installment_amount: number;
    local_amount: number;
    settlement_amount: number;
    number_of_installments: number;
    airport_tax: null | any;
    local_currency_code: string;
    cardholder_currency_code: string;
    settlement_currency_code: string;
    authorization_status: number;
    custom_response_code: string;
    acquirer_bin: any | null;
    merchant_category_id: string;
    merchant_name: string;
    merchant_city: string;
    merchant_state_or_country: string;
    merchant_zip_code: boolean;
    soft_descriptor: string;
    card_acceptor_id: string;
    point_of_sale: { entry_mode: string };
    transaction_status: any | null;
    password_present: boolean;
    cvv_present: boolean;
    track1_present: boolean;
    track2_present: boolean;
    arqc_present: boolean;
    advice_reason_code: boolean;
    advice_detail_code: boolean;
    incoming_response_code: boolean;
    incoming_authorization_code: boolean;
    postings: { cash_back_amount: number };
    rates: { conversion_rate: number; cardholder_conversion_rate: number };
    installments_information: any[];
    tid: boolean;
    ledger_update_id: string;
    conciliation_type: string;
    token_information: null;
    metadata: null;
    validation_results: any;
    conciliation_information: null;
    origin: string;
    file_date: string;
    atc: null;
    anti_fraud_execution_time: 0;
    labels: string[];
    cancelled_by_elapsed_time: boolean;
    created_from_authorization_not_found: boolean;
    anti_fraud_url: null;
    authorization_url: null;
  }> {
  event_type: 'network-authorization';
  domain: 'networktransactions';
}

export interface PismoEventBusMessageAuthorizationEvent
  extends GenericPismoEventBusMessage<{
    amount: number;
    contract_amount: number;
    event_date: string;
    authorization: {
      id: number;
      code: string;
      descriptor: string;
      processing_code: string;
      operation_description: string;
      account: {
        id: number;
      };
      card: any;
      custom: any;
      merchant: any;
      available_change: any;
    };
    nsu: string;
    currency: string,
    destination_currency: string;
    tracking_id: string;
    correlation_id: string;
    location: any;
    installments: any;
    metadata: any
  }> {
  event_type: 'authorization-event';
  domain: 'authorization';
}

export interface PismoEventBusMessageCycleClosingEvent
  extends GenericPismoEventBusMessage<{
    due_date: string;
    cycle_closing_date: string;
    real_due_date: string;
    opening_date: string;
    credits: number;
    debits: number;
    balance: number;
    minimum_payment: number;
    account_id: number;
    statement_id: number;
    cycle: number;
    statement_cycle: number;
    program_id: number;
    program_name: string;
    accruable: string;
  }> {
  event_type: 'cycle_closing';
  domain: 'statements';
}

export interface PismoEventBusMessageCardUpdateEvent
  extends GenericPismoEventBusMessage<{
    account_id: number,
    program_id: number,
    card_id: number,
    external_id: any,
    bulk_id: any,
    card_name: string,
    card_printed_name: string,
    card_type: string,
    card_last_four_numbers: string,
    card_hash: string,
    card_status: string,
    transaction_limit?: any,
    embossing_custom_fields: any,
    issuing_date: string,
    expiration_date: string,
    embossed: any,
    status: string,
    reason: any,
    customer_id: number,
    contactless_enabled: boolean,
    abu_enabled: boolean,
    metadata?: any,
    cvv_rotation_type: any,
    cvv_next_rotation_date: any,
    reissued_card_id: number,
    reissued_card_block: any,
    migration_id: any,
    card_bin: string,
    card_brand: string,
    template_id?: any,
    mode: string,
    operation_type: string,
    embossing_group: any,
    dual_message_debit: boolean,
    update_tokens: any,
    new_card_id: any,
    track_number: number,
    card_previous_status: string,
    cvv_rotation_interval_hours: any,
    card_previous_type: any,
    password_tries: number
  }> {
  event_type: 'update';
  domain: 'cards';
}

export interface PismoEventBusMessageDelinquencyBucketCreatedEvent
  extends GenericPismoEventBusMessage<{
    bucket_balance: number,
    overdue_amount: number,
    account_id: number,
    bucket_amount: number,
    cycle_closing_date: string,
    program_id: number,
    delinquency_bucket_id: number,
    due_date: string,
    created_at: string,
    statement_id: number,
  }> {
  event_type: 'statements_delinquency_bucket_created';
  domain: 'balance';
}

export interface PismoEventBusMessageDelinquencyBucketUpdatedEvent
  extends GenericPismoEventBusMessage<{
    bucket_balance: number,
    discharge_amount: number,
    overdue_amount: number,
    account_id: number,
    bucket_amount: number,
    updated_at: string,
    program_id: number,
    delinquency_bucket_id: number,
    credit_transaction_id: number,
    created_at: string,
    statement_id: number,
  }> {
  event_type: 'statements_delinquency_bucket_updated';
  domain: 'balance';
}

export interface PismoEventBusMessageAccountingEntryCreationEvent
  extends GenericPismoEventBusMessage<{
    transaction_id: string,
    amount: number,
    authorization_id: string,
    program_id: number,
    credit_cost_center: string,
    created_at: string,
    credit_account: string,
    description: string,
    accounting_event_id: number,
    debit_account: string,
    debit_cost_center: string,
    authorization_date: string,
    account_id: number,
    installment_number: number,
    event_date: string,
    accounting_entry_id: number,
    entry_type_id: number,
  }> {
  event_type: 'entry_creation';
  domain: 'accounting';
}

export interface PismoEventBusMessageNeworkAuthorizationClearing
  extends GenericPismoEventBusMessage<{
    raw_message: {
      acquirerReferenceDate: {
        acquirersBin: string,
        acquirersSequenceNumber: string,
        checkDigitNumeric: string,
        julianProcessingDate: string,
        mixedUse: string,
      },
      additionalData: {
        businessActivity: string,
        currencyExponents: string,
        originatingMessageFormat: string,
        p0001: string,
        p0002: string,
        p0003: string,
        p0015: string,
        p0052: string,
        p0058: string,
        p0059: string,
        p0159: string,
        p0170: string,
        p0177: string,
        p0207: string,
        p0208: string,
        settlementIndicator: string,
        terminalType: string,
        transactionFeeAmounts: {
          feeAmount: string,
          feeCurrencyCode: string,
          feeProcessingCode: string,
          feeReconciliationAmount: string,
          feeReconciliationCurrencyCode: string,
          feeSettlementIndicator: string,
          feeTypeCode: string,
        }[]
      },
      approvalCode: string,
      authorizationCode: string,
      businessActivity: {
        accountLevelManagementAccountCategoryCode: string,
        businessCycle: string,
        businessServiceArrangementTypeCode: string,
        businessServiceIdCode: string,
        cardAcceptorClassificationOverrideIndicator: string,
        cardProgramIdentifier: string,
        centralSiteBusinessDate: string,
        corporateIncentiveRatesApplyIndicator: string,
        interchangeRateDesignator: string,
        masterPassIncentiveIndicator: string,
        mastercardAssignedIdOverrideIndicator: string,
        productClassOverrideIndicator: string,
        rateIndicator: string,
        specialsConditionsIndicator: string,
      },
      caller: string, //"Mastercard",
      cardAcceptorBusinessCode: string,
      cardAcceptorIdCode: string,
      cardAcceptorNameLocation: string,
      cardAcceptorTerminalId: string,
      cardholderBillingAmount: string,
      cardholderBillingConversionRate: string,
      cardholderBillingCurrencyCode: string,
      category: string | null,
      clearingType: string, // "CREDIT",
      correlationId: string,
      endpoint: string,
      file_date: string,
      forwardingInstitutionIdCode: string,
      functionCode: number,
      instituionId: string,
      localTransactionDateTime: {
        date: string,
        dateRaw: string,
        time: string,
        timeRaw: string,
      },
      messageNumber: string,
      mti: string,
      network: string, //"Mastercard",
      orgId: string,
      origin: string | null,
      posEntryMode: string,
      primaryAccountNumber: string,
      processingCode: {
        codeFromAccountTypeCode: string,
        codeToAccountTypeCode: string,
        transactionTypeCode: string,
      },
      receivingInstitutionIdCode: string,
      reconciliationAmount: string,
      reconciliationConversionRate: string,
      reconcilliationCurrencyCode: string,
      settlementData: {
        reconciliationCycle: string,
        reconciliationDate: string,
        settlementCycle: string,
        settlementDate: string,
        settlementForeignExchangeRateClassCode: string,
        settlementServiceIdCode: string,
        settlementServiceLevelCode: string,
        settlementServiceTransferAgentAccount: string,
        settlementServiceTransferAgentIdCode: string,
      },
      transactionAmount: number,
      transactionAmountRaw: string,
      transactionCurrencyCode: string,
      transactionDestinationInstitutionIdCode: string,
      transactionLifeCycleId: string,
      transactionOriginatorInstitutionIdCode: string,
      zeroBalanceUrl: string | null,
    },
    file_type: string, //"T112",
    network: string, //"Mastercard",
    account_id: number,
    card_id: number,
    program_id: number,
  }> {
  event_type: 'clearing';
  domain: 'networktransactions';
}

export interface PismoEventBusMessageTokenActivationEvent
  extends GenericPismoEventBusMessage<{
    card_id: number;
    token_id: number;
    account_id: number;
    pismo_response: {
      response_code: string;
    },
    send_challenge: {
      otp_method: "OTPEMAIL" | "OTPSMS",
      otp_value: string
    } | null;
    network_call_method: "AC";
    network_data: {
      de104_transaction_description: any;
      de105_multi_use_transaction_identification_data: any;
      de10_conversion_rate_cardholder_billing: any;
      de112_additional_data_national_use: any;
      de11_stan: string;
      de120_record_data: any;
      de122_additional_acceptor_data: any;
      de124_member_defined_data: {
        mdes_data: {
          activation_code: {
            sf1_correlation_id: string;
            sf2_activation_code: string; //2fa activation code
            sf3_activation_code_expiration_date_time: string;
            sf4_consumer_activation_pref_method: string;
          }
          sf1_message_type: "AC"
        }
      }
      de126_switch_private_data: any;
      de14_date_expiration: string;
      de15_date_settlement: string;
      de16_date_conversion: string;
      de18_merchant_type: string;
      de22_pos_entry_mode: {
        sf1_pos_terminal_pan_entry_mode: string;
        sf2_pos_terminal_pin_entry_mode: string;
      };
      de28_amount_transaction_fee: any;
      de29_amount_settlement_fee: any;
      de2_primary_account_number: string;
      de30_amount_transaction_processing_fee: any;
      de31_amount_settlement_processing_fee: any;
      de32_acquiring_institution_id_code: string;
      de37_retrieval_reference_number: any;
      de3_processing_code: {
        sf1_cardholder_transaction_type_code: string;
        sf2_cardholder_from_account_type_code: string;
        sf3_cardholder_to_account_type_code: string;
      };
      de42_card_acceptor_id_code: string;
      de43_card_acceptor_name_location_for_all_transactions: {
        sf1_card_acceptor_name: string;
        sf2_space: string;
        sf3_card_acceptor_city: string;
        sf4_space: string;
        sf5_card_acceptor_state_or_country_code: string;
      };
      de48_additional_data_private_user: {
        de48SubElements: string[];
        de48_size: string;
        de48_start: string;
        de48_tcc: string;
        se06_device_related_data: any;
        se07_transaction_related_data1: any;
        se08_transaction_related_data2: any;
        se11_key_exchange_block_data: any;
        se13_mastercard_hosted_mobile_phone_top_up_request_data: any;
        se15_authorization_system_advice_date_and_time: any;
        se18_service_parameters: any;
        se22_multi_purpose_merchant_indicator: any;
        se23_payment_initiation_channel: {
          sf1_device_type: string;
        };
        se25_mastercard_cash_program_data: any;
        se26_wallet_program_data: {
          sf1_wallet_identifier: string;
        };
        se27_transaction_analysis: any;
        se33_pan_mapping_file_information: {
          sf1_account_number_indicator: string;
          sf6_token_requestor_id: string;
        };
        se34_atc_information: any;
        se36_visa_mmv: any;
        se37_additional_merchant_data: any;
        se40_eletronic_commerce_merchant_cardholder_certificate_serial_number: any;
        se41_eletronic_commercer_certificate_qualifying_information: any;
        se42_eletronic_commerce_indicator: any;
        se43_ucaf: any;
        se48_digital_commerce_solutions_indicators: any;
        se49_time_validation_information: any;
        se51_merchant_on_behalf_services: any;
        se55_merchant_fraud_scoring_data: any;
        se57_security_services_additional_data_for_acquirers: any;
        se58_atm_additional_data: any;
        se59_original_serial_switch_number: any;
        se61_pos_data_extended_condition_codes: any;
        se64_transit_program: any;
        se65_terminal_compliant_indicator: any;
        se66_authentication_data: any;
        se67_money_send_information: any;
        se74_additional_processing_information: any;
        se75_fraud_scoring_data: any;
        se78_payment_service_indicator: any;
        se79_chip_cvr_or_tvr_bit_error_results: any;
        se93_fleet_card_id_request_data: any;
      };
      de49_currency_code_transaction: string;
      de4_amount_transaction: string;
      de50_currency_code_settlement: string;
      de51_currency_code_cardholder_billing: string;
      de55_integrated_circuit_card: any;
      de5_amount_settlement: string;
      de60_advice_reason_code: any;
      de61_pos_data: {
        sf10_cardholder_activated_terminal_level: string;
        sf11_pos_card_data_terminal_input_capability_indicator: string;
        sf12_pos_authorization_life_cycle: string;
        sf13_pos_country_code_or_submerchant: string;
        sf14_postal_code_or_submerchant: string;
        sf1_pos_terminal_attendance: string;
        sf2_reserved_for_future_use: string;
        sf3_pos_terminal_location: string;
        sf4_pos_cardholder_presence: string;
        sf5_pos_card_presence: string;
        sf6_pos_card_capture_capabilities: string;
        sf7_pos_transaction_status: string;
        sf8_pos_transaction_security: string;
        sf9_reserved_for_future_use: string;
      };
      de63_network_data: {
        sf1_financial_network_code: string;
        sf2_banknet_reference_number: string;
      };
      de6_amount_cardholder_billing: string;
      de7_tranmission_date_and_time: {
        sf1_date: string;
        sf2_time: string;
      };
      de90_original_data_elements: any;
      de94_service_indicator: any;
      de95_replacement_amounts: any;
      de97_amount_net_settlement: any;
      de9_conversion_rate_settlement: {
        sf1_decimal_indicator: string;
        sf2_conversion_rate: string;
      };
      start: number;
    }
  }> {
  event_type: 'notification';
  domain: 'cards-tokenization';
}

export interface PismoCardPciInformation {
  org_id: string; // Organization/tenant ID
  card_id: {
    cardId: string;
  };
  service_code: number; //A 3 digit value encoded into the magnetic stripe on the back of credit and debit cards. Not to be confused with the CVV. Each number in the service code has a well-defined meaning, known to the card networks and other components of the interchange networks. The purpose of the service code is to tell merchant terminals and acquiring networks about usage restrictions the issuer has placed on this card.
  correlation_id: string; // Correlation ID used in request x-cid header. This is used to link related API requests and events. For example, if a user makes an authorization request with 10 installments, this generates 1 authorization event and 10 transaction events all containing the same correlation ID. The x-cid can help the Pismo engineering team track everything related to a call. If this field isn't passed, one is generated. You can find the x-cid field in the response header from an API endpoint call.
  account_id: number; // Pismo account ID
  type: PismoCardType; // Card type enum:
  printed_name: string; // Printed/embossed card name.
  valid_until: string; // Card expiration in UTC time (format:yyyy-MM-ddThh:mm:ssZ). For TEMPORARY virtual cards, which have a limited time frame, typically 24 hours, which is why the date/time here is more granular than expiration_date. TEMPORARY cards are being DEPRECATED, and it is suggested you use regular VIRTUAL cards instead.
  expiration_date: string; // Card expiration date (format:yymm)
  card_number: string; // Card embossed number - PAN
  issuing_date: string; // Date/time when card was issued in RFC3339 format, i.e., 2019-07-03T17:23:18Z
  cvv: string; // Card Verification Value (CVV) is a three- or four-digit security code that is printed on payment cards to help verify that the cardholder is the one making a transaction.
  cvv_next_rotation_date: string; // For virtual cards. Card CVV2/CVC2 next rotation UTC date/time - format:yyyy-MM-ddThh:mm:ssZ
  cvv_rotation_interval_hours: number; //  REQUIRED for a virtual card. Virtual card CVV rotation interval in hours.
  password_updated: boolean; // Has password been updated flag
}

export interface CreateOverdraftAccountDialogResult { }

export interface FirstOverdraftUserDialogData {
  validEmailCheckFn: ValidEmailCheckFn;
  getUserByEmailFn: GetUserByEmailFn;
  application: Application | null;
  pismoAccountNumbers: number[];
  customer: CustomerUser;
  getApplicationIndividualsFn: DigitalIdGetApplicationIndividualsFn;
  createOverdraftUserFn: CreateOverdraftUserFn;
  pismoCheckEmailExistsFn: PismoCheckEmailExistsFn
}

export interface FirstOverdraftUserDialogResult { }

export type GetOpportunitiesForCustomerFn = (
  salesforceId: string
) => Observable<CustomerOpportunity[]>;
export type GetAccountDetailsFromSfFn = (
  salesforceId: string
) => Observable<SfAccountDetails>;
export type CreateOverdraftAccountFn = (
  customer: CustomerUser,
  customFields: CreateOverdraftAccountData,
  opportunityId: string,
  payout: Payout[],
) => Observable<PayloadApiResponse<PismoCreateAccountResponse>>;
export type CreateOverdraftUserFn = (
  body: CreateOverdraftUserBody
) => Observable<PayloadApiResponse<CreateOverdraftUserResponse>>;
export type getPismoProgramsFn = () => Observable<PismoProgram[]>;
export type GetPismoProgramDueDate = (
  programId: number
) => Observable<PismoProgramDueDateResponse>;
export type PostOverdraftAccountFeeFn = (
  body: OverdraftAccountPostFeeBody
) => Observable<ApiResponse>;
export type PismoChangeCardStatusFn = (
  accountId: number,
  customerId: number,
  cardId: number,
  body: PismoChangeCardStatus
) => Observable<ApiResponse>;
export type PismoUpdateCustomerFn = (
  portalUserId: number,
  body: PismoUpdateCustomerBody
) => Observable<PismoUpdateCustomerResponse>;
export type PismoUpdateAccountFn = (
  pismoAccountId: number,
  body: PismoAccountCustomFields
) => Observable<ApiResponse>;
export type PismoUpdateAccountLimitFn = (
  pismoAccountId: number,
  body: PismoAccountUpdateLimits
) => Observable<PismoUpdateLimitsResponse>;
export type GetPismoAccountStatusReasonsFn = (
  accountStatusId: number,
  accountStatusName: string,
) => Observable<PismoGetAccountStatusReasonsResponse[]>;
export type PismoUpdateAccountStatusFn = (
  accountId: number,
  body: UpdateAccountStatus
) => Observable<ApiResponse>;
export type GetAllPismoAccountStatusFn =
  () => Observable<PismoGetAccountStatusResponse[]>
export type PismoRollbackAccountStatusFn = (
  accountId: number,
  body: UpdateAccountStatus
) => Observable<ApiResponse>;

export type GetPismoTransactionTypesResponseFn = () => Observable<PismoGetTransactionTypesResponse>;
export type CreateAccountInterestRateFn = (accountId: number) => Observable<ApiResponse>;
export type UpdateUserKycStatusFn = (kycStatus: KycStatus, userId: number) => Observable<ApiResponse>;
export type GetCustomerPismoAccountMappingsFn = (customerId: number) => Observable<PismoAccountMappingAndApplication[]>;
export type GetPismoAccountMappingFn = (pismoAccountNumber: number) => Observable<PismoAccountMappingAndApplication>;
export type GetPismoCustomerForAccountFn = (
  pismoAccountNumbers: number[], opts: {
    page: { offset: number, limit: number, },
    sorts?: { prop: 'pismoCustomerNumber' | 'name' | 'access' | 'email' | 'dob' | 'mobileNumber' | '', dir: 'ASC' | 'DESC' }[]
  }, pismoCustomerStatus: 'all' | 'enabled' | 'disabled'
) => Observable<{ total: number, payload: UserWithPismoMapping[] }>;
export type GetPismoCardsForCustomerFn = (accountId: number, customerId: number) => Observable<PismoCardDetailsWithAccountId[]>;
export type GetPismoTransactionTypeByIdFn = (transactionTypeId: number) => Observable<PismoTransactionType>
export type PostManualTransactionFn = (pismoAccountId: number, body: PismoPostManualTransactionBalance) => Observable<ApiResponse>;
export type GetPismoTransactionFlowFn = () => Observable<PismoTransactionFlowItems[]>;
export type PismoGetCardsForAccountFn = (pismoAccountId: number) => Observable<PismoCardDetails[]>;
export type PismoGetTransactionTypesFn = () => Observable<PismoGetTransactionType[]>;
// WEB-4399 added optional pismoAccountNumber
export type PismoCheckEmailExistsFn = (
  email: string, pismoAccountNumber?: number,
) => Observable<PayloadApiResponse<{ status: boolean }>>;
export type PismoGetCardReissueReasonsFn = (accountId: number) => Observable<PismoCardReIssueReason[]>;
export type PismoReissueCardFn = (accountId: number, customerId: number, cardId: number, data: PismoReissueCardDetails) => Observable<PismoReissueCardResponse>;
export interface CustomerOpportunity {
  attributes: {
    type: string;
    url: string;
  };
  Name: string;
  SalesforceId: string;
  ProductType: string;
  BrokerSalesforceId: string;
  Amount: number;
  Rate: number;
  DocFee: number;
  DocFeeFinanced: boolean;
  FacilityEstablishmentFee: number;
  Term: number;
  Brokerage: number;
  MonthlyFacilityFee: number;
  MonthlyFacilityFeePercentage: number;
  RecordTypeId: string;
  Stage: string;
  FinancialInstitutionName: string;
  BankAccountName: string;
  BankAccountBsb: string;
  BankAccountNumber: string;
  Margin: number;
  ABN: string;
  AccountId: string;
  Id: string;
}

export interface SfAccountDetails {
  Name: string;
  ABN: string;
  ACN: string;
  Email: string;
  Id: string;
  AccountName: string;
  AccountNumber: string;
  BSB: string;
  InstitutionName: string;
}

export interface SfOportunityDetails {
  Id: string;
  AccountId: string;
  AccountName: string;
  AccountNumber: string;
  BSB: string;
  InstitutionName: string;
}

export interface SfContactBankDetails {
  Id: string;
  AccountName: string;
  AccountNumber: string;
  BSB: string;
  InstitutionName: string;
}

export interface CreateOverdraftAccountData {
  overdraftLimit: number;
  pismoProgramId: number;
  pismoDueDate: PismoProgramDueDate;
  rate: number;
  margin: number;
  docFee: number;
  docFeeFinanced: boolean;
  facilityEstablishmentFee: number;
  term: number;
  brokerage: number;
  monthlyFacilityFee: number;
  monthlyFacilityFeePercentage: number;
  customerAccountName: string;
  customerBsb: string;
  customerAccountNumber: string;
  customerFinancialInstitutionName: string;
  brokerName: string;
  brokerAccountName: string;
  brokerBsb: string;
  brokerAccountNumber: string;
  brokerFinancialInstitutionName: string;
  address: string; // in Pismo's format
  rawAddress: string;
  // post account initial balance
  accountBalanceExistence: boolean;
  accountBalanceType: OverdraftAccountBalanceType | null;
  accountBalance: number | null;
  // post security & valuation fee
  securityValuationFeeExistence: boolean;
  securityValuationFee: number | null;
}

export interface PismoAccountUpdateLimits {
  max_credit_limit?: number;
  total_credit_limit?: number;
  total_overdraft_limit?: number;
  percentage_over_limit?: number;
  total_installment_credit_limit?: number;
}

export interface PismoUpdateLimitsResponse {
  message: string;
}

export interface PismoRollbackAccountStatus {
  status: string;
  reason_id: number;
  description: string;
}
export interface PismoRollbackAccountStatusResponse {
  message: string;
}

export interface PismoAccountCustomFields {
  rate: number;
  margin: number;
  paymentLimit: number;
  outstandingBalance?: number;
  repayment?: number;
  flagOnTime?: string;
  flagOffTime?: string;
  monthlyFacilityFeePercentage?: number;
  repaymentUpdatedTime?: string;
}

export interface PismoPhoneMapping {
  id: number,
  userId: number,
  pismoAccountNumber: number,
  pismoPhoneId: number,
}

export interface UpdateAccountStatus {
  status: string;
  reason_id?: number | null;
  description: string | null;
  update_children?: boolean;
}

export interface PismoAccountMapping {
  id: number;
  customerId: number;
  pismoAccountNumber: number;
  opportunityId: string;
  facilityEstablishmentFeeStatus: boolean;
  brokerageStatus: boolean;
  documentationFeeStatus: boolean;
  documentationFeeReversalStatus: boolean;
  saveAccountInterestRateStatus: boolean;
  postBalanceStatus: boolean;
  creationDate: string;
  programId: number;
  requireMonthlyFixedInstallment: boolean;
}

export interface PismoAccountMappingAndApplication extends PismoAccountMapping {
  application: Application | null;
}

export interface UserWithPismoMapping extends User, PismoCustomerMapping {
  // id: number;
  // userId: number;
  // pismoCustomerNumber: number;
  // pismoAccountNumber: number;
  // cardType: PismoPhysicalCardType;
  // isPismoCustomerDisabled: boolean;
}

export type UserAndPismoCustomerMapping = {
  user: User,
  pismoCustomerMapping: PismoCustomerMapping
}

export interface PismoCustomerMapping {
  id: number;
  userId: number;
  pismoCustomerNumber: number;
  pismoAccountNumber: number;
  cardType: PismoPhysicalCardType;
  isPismoCustomerDisabled: boolean;
  customerAccessLevel?: CustomerAccessLevel,
  RoleAccess: RoleAccess | null,
}

export interface CreateOverdraftAccountResponse
  extends PismoCreateAccountResponse {
  portalApplication: Application | null;
}
export type OverdraftAccountSchedulePaymentFrequency = 'fortnightly' | 'weekly' | 'monthly' | 'date';
export interface PismoOverdraftAccountSchedulePaymentBody {
  date: string; // ddmmyyyy
  amount: number;
  userId: number; // which user performs the repayment
  customerId: number; // portal customer Id
  pismoCustomerId: number; // which pismo customer performs the repayment
  pismoAccountId: number;
  customerAccountName: string;
  customerBsb: string;
  customerAccountNumber: string;
  customerFinancialInstitutionName: string;
  confirmation?: boolean;
  paymentFrequency: OverdraftAccountSchedulePaymentFrequency
  paymentDate: string // YYYY-mm-dd
}

export interface PismoOnetimeRepaymentBody {
  date: string; // ddmmyyyy
  amount: number;
  userId: number; // which user performs the repayment
  customerId: number; // portal customer Id
  pismoCustomerId: number; // which pismo customer performs the repayment
  pismoAccountId: number;
  reference?: string;
  // customer bank details
  customerAccountName: string;
  customerBsb: string;
  customerAccountNumber: string;
  customerFinancialInstitutionName: string;
}

export interface PaymentApiResponse {
  //pismoResponse: PismoPaymentResponse;
  newTransactionResponse: NewTransactionResponse;
  addToPendingApprovalTable?: boolean;
}

export const PAYMENT_DISHONOUR_FEE = 30;
export const CARD_REISSUE_FEE = 15;

export enum PismoTransactionProcessingCode {
  EXTERNAL_BANK_TRANSFER = '00003',
  EXTERNAL_BANK_TRANSFER_REVERSAL = '10003',
  BPAY = '00004',
  BPAY_REVERSAL = '10004',
  DOCUMENTATION_FEE = '00005', //Sandbox: '88888',
  DOCUMENTATION_FEE_REVERSAL = '10005', //Sandbox: 18888',
  FACILITY_FEE = '00006',
  FACILITY_FEE_REVERSAL = '10006',
  FACILITY_ESTABLISHMENT_FEE = '00007',
  FACILITY_ESTABLISHMENT_FEE_REVERSAL = '10007',
  BROKERAGE = '9999999',
  PAYMENT = '004000',
  PAYMENT_REVERSAL = '104000', // for manual payment reversal
  MINIMUM_PAYMENT_REVERSAL = '104001', // for system direct debit reversal
  ANNUAL_FEE = '219755',
  ANNUAL_FEE_REVERSAL = '219756',
  MERCHANT = '00',
  PAYMENT_DISHONOUR_FEE = '00009',
  PAYMENT_DISHONOUR_FEE_REVERSAL = '10009',
  CARD_REISSUE_FEE = '00008',
  CARD_REISSUE_FEE_REVERSAL = '10008',
  PURCHASE = '00',
  PURCHASE_REVERSAL = '0001',
  BAD_DEBT_WRITE_OFF = '00010', // ??
  BAD_DEBT_WRITE_OFF_REVERSAL = '10010', // ??
  MERCHANT_REVERSAL = '003100',
  INSTALLMENT_WITHOUT_INTEREST = '003800',
  INSTALLMENT_WITHOUT_INTEREST_REVERSAL = '003800',
  CREDIT_BY_INSTALLMENTS = '004990', // ??
  CREDIT_BY_INSTALLMENTS_REVERSAL = '004995', // ??
  REFINANCING_AGREEMENT = '004991',
  REFINANCING_AGREEMENT_REVERSAL = '004996',
  COMPULSORY_INSTALLMENT_CREDIT = '004992',
  COMPULSORY_INSTALLMENT_CREDIT_REVERSAL = '004997',
  CREDIT_ADJUSTMENT = '009100',
  DEBIT_ADJUSTMENT = '009200',
  INTEREST = '0555',
  INTEREST_REVERSAL = '0555R',
  CREDIT_VOUCHER = '20',
  INTEREST_ON_REVOLVING_CREDIT = '219734',
  INTEREST_ON_REVOLVING_CREDIT_REVERSAL = '219735',
  UNDO_CANC_INTEREST_ON_REVOLVING_CREDIT = '219736',
  LATE_PAYMENT_INTEREST = '219737', //balance_impact = -1
  CAN_LATE_PAYMENT_INTEREST = '219738', //1
  UNDO_CANC_LATE_PAYMENT_INTEREST = '219739', // -1\
  LATE_FEE = '219740', // -1
  CAN_LATE_FEE = '219741', // 1
  UNDO_CANC_LATE_FEE = '219742',
  TAXES = '219743',
  CAN_TAXES = '219744',
  UNDO_CANC_TAXES = '219745',
  OVERLIMIT_FEE = '219746',
  CAN_OVERLIMIT_FEE = '219747',
  UNDO_CANC_OVERLIMIT_FEE = '219748',
  DEBIT_EXCHANGE_DIFFERENCE = '219749',
  CAN_DEBIT_EXCHANGE_DIFFERENCE = '219750',
  UNDO_CANC_DEBIT_EXCHANGE_DIFFERENCE = '219751',
  CREDIT_EXCHANGE_DIFFERENCE = '219752',
  CAN_CREDIT_EXCHANGE_DIFFERENCE = '219753',
  UNDO_CAN_CREDIT_EXCHANGE_DIFFERENCE = '219754',
  ANNUITY = '219755',
  CAN_ANNUITY = '219756',
  UNDO_CAN_ANNUITY = '219757',
  TRANSFERRED_BALANCE = '30000',
  TRANSFERRED_BALANCE_REVERSAL = '30001',
  PAYOUT = '30002',
  PAYOUT_REVERSAL = '30003',
  SECURITY_REGISTRATION_AND_LEGAL_FEE = '30004',
  SECURITY_REGISTRATION_AND_LEGAL_FEE_REVERSAL = '30005',
}

export enum PismoPaymnetFailureContactEmail {
  BROKERAGE_FINANCE = 'fintres@dynamoney.com',
  BROKERAGE_SETTLEMENTS = 'settlements@dynamoney.com',
  DOCUMENTATION_FEE = 'collections@dynamoney.com',
}

export interface PismoGetPinAsPinblockResponse {
  card_id: string;
  account_id: string;
  org_id: string;
  correlation_id: string;
  pinblock: string;
  pan: string;
  key_id: string;
}

export interface PismoUpdateCustomerInformationBody {
  name?: string;
  nickname?: string;
  printed_name?: string;
  social_name?: string;
  email?: string;
  pep?: boolean;
  gender?: string;
  marital_status?: string;
  nationality?: string;
  city_of_birth?: string;
  state_of_birth?: string;
  country_of_birth?: string;
  document_type?: string;
  other_id_number?: string;
  document_issued_by?: string;
  document_issued_at?: string;
  fathers_name?: string;
  mothers_name?: string;
  occupation?: string;
  income?: number;
  net_worth?: number;
  assets?: number;
  birth_date?: string; // DD/MM/YYYY
  document_issued_date?: string;  // DD/MM/YYYY
}

export interface PismoUpdateCustomerInformationResponse {
  name?: string;
  nickname?: string;
  printed_name?: string;
  social_name?: string;
  pep?: boolean;
  gender?: string;
  marital_status?: string;
  nationality?: string;
  city_of_birth?: string;
  state_of_birth?: string;
  country_of_birth?: string;
  document_type?: string;
  other_id_number?: string;
  document_issued_by?: string;
  document_issued_at?: string;
  fathers_name?: string;
  mothers_name?: string;
  occupation?: string;
  income?: number;
  net_worth?: number;
  assets?: number;
  document_number?: string;
  type_id: number;
  entity_type: string;
  birth_date?: string;
  document_issued_date?: string;
}
export interface PismoCurrentCardSpending {
  transactions: PismoTransaction[];
  spending: number;
}

export interface PismoPhoneBody {
  number: string;
  type: string;
  extension: string;
  active: boolean;
  country_code: string;
  area_code: string;
}

export interface PismoCreatePhoneResponse {
  id: number;
}

export interface PismoGetPhoneInfo {
  id: number;
  account_id: number;
  area_code: string;
  number: string;
  type: string;
  extension: string;
  active: boolean;
  org_id: string;
  country_code: string;
}
export interface PismoProgramDueDateResponse {
  pagination: {
    items: PismoProgramDueDate[];
    current_page: number;
    is_last_page: boolean;
    items_on_page: number;
    pages: number;
    per_page: number;
    total_items: number;
  };
}

export interface PismoProgramDueDate {
  program_due_date_id: number;
  program_id: number;
  day: number;
  exists_account: boolean;
  active: boolean;
}

export interface PismoReissueCardResponse {
  program: { id: number },
  account: { id: number },
  customer: { id: number },
  org: { id: string },
  id: number, // new card id
  status: PismoCardStatus,
  stage: 'CREATED' | 'BLOCKED' | 'UNBLOCKED',
  issuing_date: string,
  expiration_date: string,
  printed_name: string,
  name: string,
  mode: 'CREDIT' | 'DEBIT' | 'MULTIPLE' | 'COMBO',
  type: PismoCardType,
  reissueAddToEmbossing?: boolean
}

export interface PismoReissueRetryResponse {
  reissueAddToEmbossing?: boolean
}

export interface NewPismoStatementData {
  accountId: number;
  statementId: number;
  rate: number;
  margin: number;
  creditLimit: number;
  availableCredit: number;
  dueDate: string;
  cycleClosingDate: string;
  realDueDate: string;
  openingDate: string;
  cycle: number;
  statementCycle: number;
  programId: number;
  programName: string;
  minimumPayment: number;
}

export interface PismoStatementData extends NewPismoStatementData {
  id: number;
}

export interface PismoAccountTransactionsDialogData {
  items: PismoTransaction[];
}

export interface PismoAccountTransactionsDialogResult {

}

export interface PismoActivateOrDeactivateCardDialogData {
  tokenInfos: PismoCardTokenInfo[];
  cardDetails: PismoCardDetailsWithAccountId;
  pismoChangeCardStatus: PismoChangeCardStatusFn;
  pismoGetCardReissueReasonsFn: PismoGetCardReissueReasonsFn;
  pismoReissueCardFn: PismoReissueCardFn;
}

export interface PismoActivateOrDeactivateCardDialogResult {
}

export interface PismoPostManualTransactionDialogData {
  // item: PismoGetTransactionTypesResponse;
  // cards: PismoCardDetails[];
  pismoAccountId: number
  getPismoTransactionTypeByIdFn: GetPismoTransactionTypeByIdFn;
  postManualTransactionFn: PostManualTransactionFn;
  getPismoTransactionFlowFn: GetPismoTransactionFlowFn;
  pismoGetCardsForAccountFn: PismoGetCardsForAccountFn;
  pismoGetTransactionTypesFn: PismoGetTransactionTypesFn;
  pismoGetBuiltInTransactionTypesFn: PismoGetBuiltInTransactionTypesFn;
}

export interface PismoPostManualTransactionDialogResult {

}

export interface PismoPostManualTransactionBalance {
  amount: number;
  card: PismoCardDetails | null;
  description: string;
  transactionLevel: RadioOptionValue;
  processingCode: string;
  transactionType: string;
}

export interface PismoEditAccountDialogData {
  accountDetails: PismoGetAccountResponse,
  pismoUpdateAccountFn: PismoUpdateAccountLimitFn
  pismoGetAllStatusFn: GetAllPismoAccountStatusFn
  pismoGetAccountStatusReasonsFn: GetPismoAccountStatusReasonsFn
  pismoUpdateAccountStatusFn: PismoUpdateAccountStatusFn
  pismoRollbackAccountStatusFn: PismoRollbackAccountStatusFn
}

export interface EditMonthlyFacilityFeePercentageDialogData {
  creditLimit: number;
  monthlyFacilityFeePercentage: number;
}

export interface EditMonthlyFacilityFeePercentageDialogResult {
  readyForSubmission: boolean;
  monthlyFacilityFeePercentage: number;
}

export interface EnablePismoCustomerAccountDialogData {
  userId: number,
  pismoAccountNumber: number,
  enableOverdraftUserFn: EnableOverdraftUserFn
}

export interface EnablePismoCustomerAccountDialogResult {
  valid: boolean;
}

export interface PismoEditAccountDialogResult {

}

export interface PismoEditCustomerDialogData {
  item: UserWithPismoMapping;
  pismoUpdateCustomer: PismoUpdateCustomerFn

}

export interface PismoEditCustomerDialogResult {

}

export interface PismoReissueCardDialogData {
  pismoGetCardReissueReasonsFn: PismoGetCardReissueReasonsFn;
  pismoReissueCardFn: PismoReissueCardFn;
  cardDetails: PismoCardDetailsWithAccountId;
}

export interface PismoReissueCardDialogResult {

}

export interface PismoGetAccountStatusResponse {
  status_id: number,
  name: string,
  description: string,
  state: string,
  is_final: boolean,
  allowed_operations: string,
}

export interface PismoGetAccountStatusReasonsResponse {
  code: string,
  reason_id: number,
  description: string,
  show_user: boolean,
  account_status_id: number
}
export interface CheckPismoCustomerExistancePayload {
  email: string,
  isExistingPismoCustomer: boolean,
}

export interface PismoGetTransactionTypesResponse {
  pagination: {
    current_page: number,
    per_page: number,
    total_items: number,
    items_on_page: number,
    pages: number,
    is_last_page: boolean,
    items: PismoGetTransactionType[]
  }
}

export interface PismoGetTransactionType {
  transaction_type_id: number,
  program_id: number,
  transaction_category_id: number,
  transaction_type_description: string,
  program_transaction_type_id: number,
  charge_order: number
}

export interface CompanyStatement {
  name: string;
  abn: string;
  acn: string;
}

export interface StatementData {
  amtDue: string;
  avaliableCredit: string;
  closing: string;
  creditLimit: string;
  credits: string;
  cycleClosingDate: string;
  cycleOpeningDate: string;
  debits: string;
  dueDate: string;
  interest: string;
  minPymt: string;
  monthlyFacilityFee: string;
  opening: string;
  period: string;
  rate: string;
}

export interface TransactionStatement {
  date: string;
  cardLast4Digits: string;
  credit: boolean;
  amt: string;
  description: string;
}
export interface PismoStatementDetail {
  company: CompanyStatement;
  contact: {
    name: string;
  };
  customer: {
    address: string;
  };
  dd: {
    acct: string;
    bsb: string;
  };
  pismo: {
    account: number;
  };
  stmt: StatementData;
  transactions: TransactionStatement[];
}


export interface PismoSaveAccountInterestRateBody {
  transaction_category_id: number; //Transaction category ID, must be associated with the passed program_id and org_id.
  description?: string; // Transaction category description
  refinancing_rate_after_due_date: number; // Interest rate used in transactions after its statement due date.
  default_rate: number; // Interest charged when payment occurs after statement due date. This field is a monthly value, meaning it is divided by 30 before being applied to the transaction.
  fine_rate: number; // Fine rate, charged only one time, by transaction, when transaction is due.
  overdue_rate_after_due_date: number; // Overdue rate used in transactions after its statement due date. If null, refinancing_rate_after_due_date is used.
  overdraft_rate?: number;
  refinancing_rate_after_due_date_multiplier_percent?: number; // Multiplier percent applied to transaction category refinancing rate after due date. 1.5 = 150% of the transaction rate
  default_rate_multiplier_percent?: number; // Multiplier percent applied to transaction category default rate. 1.7 = 170% of the transaction rate
  fine_rate_multiplier_percent?: number; // Multiplier percent applied to transaction category fine rate. 2 = 200% of the transaction rate
  overdue_rate_after_due_date_multiplier_percent?: number; // Multiplier percent applied to transaction category overdue rate after due date. 0.7 = 70% of the transaction rate
  start_cycle?: number; // Account cycle this interest rate should start. Must be greater than or equal to the current account cycle. Enter either this field or start_date, but not both.
  start_date: string; // When interest rate should start. Enter either this field or start_cylce but not both. Date must be greater than current date. e.g: "2021-08-20"
}

export interface PismoSaveAccountInterestRateResponse {
  account_transaction_category_id: number; // Account transaction category ID
  account_id: number; // Account ID
  program_id: number; // Program ID
  transaction_category_id: number; //Transaction category ID, must be associated with the passed program_id and org_id.
  org_id: string; // Organization ID
  description: string; // Transaction category description
  refinancing_rate_after_due_date: number; // Interest rate used in transactions after its statement due date.
  default_rate: number; // Interest charged when payment occurs after statement due date. This field is a monthly value, meaning it is divided by 30 before being applied to the transaction.
  fine_rate: number; // Fine rate, charged only one time, by transaction, when transaction is due.
  overdue_rate_after_due_date: number; // Overdue rate used in transactions after its statement due date. If null, refinancing_rate_after_due_date is used.
  overdraft_rate?: number;
  refinancing_rate_after_due_date_multiplier_percent: number; // Multiplier percent applied to transaction category refinancing rate after due date. 1.5 = 150% of the transaction rate
  default_rate_multiplier_percent: number; // Multiplier percent applied to transaction category default rate. 1.7 = 170% of the transaction rate
  fine_rate_multiplier_percent: number; // Multiplier percent applied to transaction category fine rate. 2 = 200% of the transaction rate
  overdue_rate_after_due_date_multiplier_percent: number; // Multiplier percent applied to transaction category overdue rate after due date. 0.7 = 70% of the transaction rate
  start_cycle: number; // Account cycle this interest rate should start. Must be greater than or equal to the current account cycle. Enter either this field or start_date, but not both.
  start_date: string; // When interest rate should start. Enter either this field or start_cylce but not both. Date must be greater than current date. e.g: "2021-08-20"
  updated_at: string; // When this interest rate was updated, format = yyyy-mm-ddThh:mm:ss
  cancelled_at: string; // When this interest rate was cancelled, format = yyyy-mm-ddThh:mm:ss
  created_at: string; // When this interest rate was created, format = yyyy-mm-ddThh:mm:ss
}

export interface PismoSetOverdraftRateBody {
  transaction_category_id: number; //Transaction category ID, must be associated with the passed program_id and org_id.
  accrual_type: string; // "OVERDRAFT_INTEREST"
  period_to_calculate: string; // "UNTIL_DUE_DATE"
  default_rate: number;
  rate_if_overdue: number;
  validity_to_calculate: string; // "IMMEDIATE"
}

export interface PismoSetOverdraftRateResponse {
  account_accrual_type_rate_id: number;
  account_id: number;
  transaction_category_id: number;
  accrual_type: string;
  period_to_calculate: string;
  validity_to_calculate: string;
  default_rate: number;
  rate_if_overdue: number;
  created_at: string;
  deleted_at: string;
}

export interface PismoSetInterestRateResponse {
  saveAccountInterestRateResponse: PismoSaveAccountInterestRateResponse;
  setOverdraftRateResponse: PismoSetOverdraftRateResponse;
}

export type SearchOverdraftCustomerResult = {
  customerId: number,
  entityName: string,
  users: {
    userId: number,
    pismoAccountNumber: number,
    pismoCustomerNumber: number,
    email: string,
    name: string,
    // phoneNumber: string,
    mobileNumber: string,
    dob: string, // YYYY-MM-DD
    cards: {
      pismoCardNumber: number,
      annualFee: number | null,
      activationTime: string | null,
      lastAnnualFeeChargingTime: string | null,
    }[],
  }[]
}[];
export type OverdraftAccountBalanceType = 'debit' | 'credit';

export interface TransferredbalanceDialogData {

}

export interface TransferredBalanceDialogResult {
  balanceType: OverdraftAccountBalanceType;
  balanceAmount: number;
}

export interface TransactionsState {
  transactions: PismoTransactionsIncludingPending;
  isLoading: boolean;
  errorMessage: string;
}

export type PismoTransactionTypeIds = 'BankTransfer' | 'BankTransferReversal' | 'Payment' | 'Bpay' | 'BpayReversal' |
  'MonthlyFacilityFee' | 'DocumentationFee' | 'FacilityEstablishmentFee' | 'other' | 'PaymentReversal' | 'CardAnnualFee' |
  'Merchant' | 'DishonourFee' | 'CardReissueFee' | 'Purchase' | 'PurchaseReversal';

export type PismoTransactionCategory =
  'Transfer' |
  'Reversals' |
  'BPay' |
  'Fees' |
  'Payment' |
  'Merchant' |
  'Adjustments' |
  'Interests' |
  'Voucher' |
  'Taxes' |
  'Exchange' |
  'Annuity' |
  'Security' |
  'Unknown';


export type GetPismoAccountAndOpportunityDetailsFn = (pismoAccountNumbers: number[]) => Observable<PismoAccountAndOpportunityResponse[]>;

export type UpdatePismoAccountPaymentLimitFn = (accountId: number, paymentLimit: number) => Observable<ApiResponse>

export type GetPismoTransactionsFn = (accountId: number, opts: {
  page: { limit: number, offset: number }
}) => Observable<PaginablePayloadApiResponse<PismoTransactionsIncludingPending>>;

export type GetPismoStatementsFn = (accountId: number) => Observable<PismoStatement[]>;

export type PismoDownloadStatementFn = (accountId: number, statementId: number) => Observable<Blob>;

export type GetPismoStatementDetailsFn = (accountId: number, statementId: number) => Observable<OverdraftStatementTemplateData>;

export type GetTransactionDetailsFn = (accountId: number, transactionId: number) => Observable<PismoTransaction>;

export type GetTokenInfoFn = (accountId: number, cardId: number) => Observable<PismoCardTokenInfo[]>;

export type GetTransactionFromTimelineFn = (accountId: number, transactionId: number, event_date: string) => Observable<PismoPendingTransaction | null>;

export type GetPismoAccountDetailsFn = (pismoAccountNumbers: number[]) => Observable<PismoGetAccountResponse[]>;

export type GetCardFn = (accountId: number, customerId: number, cardId: number) => Observable<PismoCardDetails | null>;

export type PismoUpdateUserAccessFn = (userId: number, pismoAccountNumber: number, pismoCustomerNumber: number, body: UpdatePismoUserAccessBody) => Observable<ApiResponse>;

export type PismoProgramType = 'overdraft' | 'debit' | 'thirdParty';

export type GetPismoAccountTimelineFn = (accountId: number) => Observable<PismoTimeline[]>;

export interface SearchPismoAccountMapping extends PismoAccountMapping {
  EntityName: string,
  PismoProgramName: string,
  PismoProgramType: PismoProgramType,
}
export type SearchPismoAccountMappingsResult = { total: number, payload: SearchPismoAccountMapping[] };


export interface Payout {
  type: 'bpay' | 'bank-transfer',
  amount: number,
  reference: string,
  bankTransfer: LocalBankAccountDetailValue | null,
  bpay: BpayComponentValue | null,
}

export type PismoReportType = 'accounting_events' | 'accounts_balances' | 'accounts_limits' | 'transactions_balance' | 'transactions_denied' | 'portfolio_balance' | 'authorization_clearing';

export type PismoReportData = PismoReportAccountingEvents2 | PismoReportAccountingEvents | PismoReportAccountsBalances | PismoReportAccountsLimits | PismoReportTransactionDenied | PismoReportTransactionsBalance | PismoPortfolioBalance;
export interface PismoReportAccountsBalances {
  id: number,
  Year: number,
  Month: number,
  Day: number,
  Org_ID: string,
  RefDate: string,
  Account_ID: number,
  Program_ID: string,
  DueDays: string,
  Type: string,
  AccountStatus: string,
  Balance: string,
  ReportId: number,
}

export interface PismoReportDailyBalances {
  dte: string;
  Account_id: number;
  Balance: number;
}

export interface PismoReportAccountingEvents2 {
  id: number,
  transaction_id: string,
  amount: number,
  authorization_id: string;
  program_id: number,
  credit_cost_center: string;
  created_at: string;
  credit_account: string;
  description: string;
  accounting_event_id: number;
  debit_account: string;
  debit_cost_center: string;
  authorization_date: string;
  account_id: number;
  installment_number: number;
  event_date: string;
  accounting_entry_id: number;
  entry_type_id: number;
  processing_code: PismoTransactionProcessingCode;
  processing_description: string;
  soft_descriptor: string;
  last_updated_time: string;
}

export interface PismoReportAccountingEvents {
  id: number,
  Year: number,
  Month: number,
  Day: number,
  Org_ID: string,
  CreatedAt: string,
  EventDate: string,
  DebitAccount: string,
  CreditAccount: string,
  EntryType_ID: string,
  Description: string,
  Account_ID: string,
  Amount: string,
  Program_ID: string,
  ProgramName: string,
  BrandName: string,
  Transaction_ID: number,
  Authorization_ID: number,
  RefDate: string,
  ProcessingCode: string,
  ProcessingCodeDescription: string,
  Event_ID: number,
  AccountEntry_ID: number,
  DebitCostCenter: string,
  CreditCostCenter: string,
  AuthorizationDate: string,
  ReportId: number,
}

export interface PismoReportAccountsLimits {
  id: number,
  Year: number,
  Month: number,
  Day: number,
  Org_ID: string,
  RefDate: string,
  Account_ID: number,
  ProgramName: string,
  Program_ID: string,
  AccountStatus: string,
  MaxCreditLimit: string,
  AvailableCreditLimit: string,
  TotalCreditLimit: string,
  TotalOverDraftLimit: string,
  AvailableSavingsAccountLimit: string,
  PercentageOverLimit: string,
  HeldFunds: string,
  ReportId: number,
}

export interface PismoReportTransactionsBalance {
  id: number,
  Year: number,
  Month: number,
  Day: number,
  Org_ID: string,
  RefDate: string,
  Account_ID: string,
  Statement_ID: string,
  Balance: string,
  CicleClosingDate: string,
  DueDate: string,
  DueDays: string,
  Type: string,
  Program_ID: string,
  ProgramName: string,
  ReportId: number,
}

export interface PismoReportTransactionDenied {
  id: number,
  Year: number,
  Month: number,
  Day: number,
  Org_ID: string,
  RefDate: string,
  EventDateHour: string,
  Account_ID: number,
  Card_ID: string,
  EntryMode: string,
  MerchantName: string,
  OperationName: string,
  NumberOfInstallments: number,
  LocalCurrencyCode: string,
  LocalCurrencyTransactionAmount: number,
  SettlementCurrencyCode: string,
  PrincipalAmount: number,
  MerchantCategory_ID: string,
  AuthorizationResponseCode: string,
  ReportId: number,
}

export interface PismoPortfolioBalance {
  id: number,
  Year: number,
  Month: number,
  Day: number,
  Org_ID: string,
  RefDate: string,
  Account_ID: string,
  Program_ID: string,
  DueDays: string,
  AccountStatus: string,
  BalanceType: string,
  Balance: string,
  ReportId: number,
}

export interface PismoReportRecord {
  id: number,
  Year: number,
  Month: number,
  Day: number,
  ReportType: string,
  FilePath: string,
  FileName: string,
  LoadedTime: string,
}

export interface BankTransferDialogData {
  data: BankingTransaction;
  userId: number;
}

export interface BankTransferDialogResult {
  readyForSubmission: boolean;
  result: NewDirectPaymentBody;
}

export interface DirectDebitDialogData {
  data: BankingTransaction;
  userId: number;
}

export interface DirectDebitDialogResult {
  readyForSubmission: boolean;
  result: PismoOnetimeRepaymentBody;
}

export interface BpayDialogData {
  getBillerNameFn: GetBillerNameFn;
  data: BankingTransaction;
  userId: number;
}

export interface BpayDialogResult {
  readyForSubmission: boolean;
  result: NewBPayPaymentBody;
}

export interface SFTPConfig {
  host: string;
  username: string;
  port: number,
  privateKey: string;
  passphrase: string;
}

export interface PismoProcessingCodeDetails {
  org_operation_id: number;
  processing_code: string;
  description: string;
  balance_impact: number;
  balance_impact_id: number;
  operation_type: string;
  is_external: boolean;
  undo_org_operation_id?: number;
  undo_processing_code?: string;
  undo_description?: string;
  undo_balance_impact?: number;
  undo_balance_impact_id?: number;
}

export interface PismoAccountingRecord {
  pismoAccountNumber: number;
  processingCode: PismoTransactionProcessingCode;
  description: string;
  amount: number;
  period: string;
  balanceImpact: number;
}

export interface PismoAccountingRecordWithoutTransactions {
  pismoAccountNumber: number;
  period: string;
}

export interface PismoAggregatedAccountingRecord {
  pismoAccountNumber: number;
  period: string;
  customerName: string;
  fees: number;
  interest: number;
  systemRepayment: number;
  manualRepayment: number;
  redrawWithTheBank: number;
  others: number;
  creditCard: number;
  creditAdjustment: number;
  debitAdjustment: number;
  openingBalance: number;
  closingBalance: number;
  arrearsAmount: number;
  accountStatus: string;
  creationDate: string;
  totalLimit: number;
  rate: number;
  margin: number;
  term: number;
  remainingPeriod: number;
  lineFeeAccrued: number;
  lineFeeAccruedPlusClosingBalance: number;
  paidFacilityFee: number;
}

export type PismoAccountingCategory = 'fees' | 'interest' | 'systemRepayment' | 'manualRepayment' | 'others' | 'redrawWithTheBank' | 'creditCard' | 'creditAdjustment' | 'debitAdjustment';

export type PismoTransactionTypeForOFX = 'FEE' | 'INT' | 'DEP' | 'PAYMENT' | 'DEBIT' | 'CREDIT';


export interface PismoBuiltInTransactionType {
  id: number;
  processing_code: string;
  description: string;
  balance_impact: number;
  transaction_type_id: number;
  transaction_type_description: string;
}

export type PismoGetBuiltInTransactionTypesFn = () => Observable<PismoBuiltInTransactionType[]>;

export type PismoUpdateRequireMonthlyFixedInstallmentFlagFn = (accountId: number, requireMonthlyFixedInstallment: boolean, reason: string) => Observable<ApiResponse>;

export interface UpdateRequireMonthlyFixedInstallmentFlagBody {
  requireMonthlyFixedInstallment: boolean;
  reason: string;
}

export interface PismoUpdateRequireFixedMonthlyInstallmentFlagDialogData {
  currentRequireMonthlyFixedInstallment: boolean;
  accountId: number;
}

export interface PismoUpdateRequireFixedMonthlyInstallmentFlagDialogResult {
  readyForSubmission: boolean;
  requireMonthlyFixedInstallment: boolean;
  reason: string;
  accountId: number;
}

export type PismoInterestAccrualType = 'OVERDUE' | 'REFINANCING' | 'IOF' | 'FINE' | 'OVERDRAFT_INTEREST';
export type PismoInterestAccrualStatus = 'PENDING' | 'PROCESSED' | 'STOP_ACCRUAL' | 'SETTLED';
export interface PismoInterestAccrualSummaryResponse {
  current_page: number;
  per_page: number;
  total_items: number;
  items_on_page: number;
  pages: number;
  is_last_page: number;
  items: PismoInterstAccrualSummary[];
}

export interface PismoInterstAccrualSummary {
  accrual_type: PismoInterestAccrualType;
  status: PismoInterestAccrualStatus;
  accrued_value: number;
  interest_accrual_ids: number[];
}

export interface GeneratePayoutDialogData {
  accountAndOpportunityDetails: PismoAccountAndOpportunityResponse;
  pismoGetPaidFacilityFeeFn: PismoGetPaidFacilityFeeFn;
  pismoGetPendingInterestFn: PismoGetPendingInterestFn;
  getPismoCustomerForAccountFn: GetPismoCustomerForAccountFn;
  pismoSendPayoutNotificationFn: PismoSendPayoutNotificationFn;
  pismoGeneratePayoutFn: PismoGeneratePayoutFn;
  getPendingPaymentsFn: GetPendingPaymentsFn;
  getDefaultRatecardDetailsFn: GetRateCardDetailsFn;
}

export interface GeneratePayoutDialogResult {
  readyForSubmission: boolean;
  adjustment: number;
  reason: string;
  payment: number;
}

export interface PayoutNotificationDialogData {
  pismoAccountDetails: PismoGetAccountResponse;
  getPismoCustomerForAccountFn: GetPismoCustomerForAccountFn;
}

export interface PayoutNotificationDialogResult {
  readyForSubmission: boolean,
  email: string[],
  ccEmail: string[]
}

export type PismoGetPaidFacilityFeeFn = (accountId: number) => Observable<number>;
export type PismoGetPendingInterestFn = (accountId: number) => Observable<number>;
export type PismoSendPayoutNotificationFn = (body: PayoutNotificationBody) => Observable<ApiResponse>;
export type PismoGeneratePayoutFn = (body: GeneratePayoutBody) => Observable<ApiResponse>;
export type GetPendingPaymentsFn = (accountId: number) => Observable<number>;

export interface PayoutNotificationBody {
  email: string[],
  ccEmail: string[],
  unpaidFacilityFee: number,
  outstandingBalance: number,
  pendingInterest: number,
  discount: number,
  discountReason: string,
  totalPayment: number,
  pismoAccountId: number
}

export interface GeneratePayoutBody {
  // email: string[],
  // ccEmail: string[],
  unpaidFacilityFee: number,
  outstandingBalance: number,
  pendingInterest: number,
  discount: number,
  discountReason: string,
  totalPayment: number,
  pismoAccountId: number,
  date: string,
  closeAccount: boolean,
  autoDirectDebit: boolean,
}

export interface NewPayoutData {
  transactionId: number,
  pismoAccountId: number,
  userId: number,
  unpaidFacilityFee: number,
  outstandingBalance: number,
  pendingInterest: number,
  discount: number,
  discountReason: string,
  totalPayment: number,
  date: string,
}

export interface PayoutData extends NewPayoutData {
  id: number,
  createdTime: string,
}

export interface ListAccountInterestRateResponse {
  items: AccountInterestRateItem[];
}

export interface AccountInterestRateItem {
  account_transaction_category_id: number; // Account transaction category ID
  account_id: number; // Account ID
  program_id: number; // Program ID
  transaction_category_id: number; // Transaction category ID
  org_id: string; // Organization ID
  description: string; // Transaction description
  refinancing_rate_after_due_date: number; // Interest rate used in transactions after its statement due date.
  default_rate: number; // Interest charged when payment occurs after statement due date.
  //The value of this field is monthly, meaning it is divided by 30 bbefore being applied to transaction.
  fine_rate: number; // Fine rate, charged only one time, by transaction, when transaction is due.
  overdue_rate_after_due_date: number; // Overdue rate used in transactions after its statement due date. If this value is null, the field refinancing_rate_after_due_date will be used.
  refinancing_rate_after_due_date_multiplier_percent: number; // Multiplier percent to be applied to transaction category refinancing rate after due date. The 1.5 value represents 150% of the transaction rate
  default_rate_multiplier_percent: number; // Multiplier percent to be applied to transaction category default rate. The 1.7 value represents 170% of the transaction rate
  fine_rate_multiplier_percent: number; // Multiplier percent to be applied to transaction category fine rate. The 2 value represents 200% of the transaction rate
  overdue_rate_after_due_date_multiplier_percent: number; // Multiplier percent to be applied to transaction category overdue rate after due date. The 0.7 value represents 70% of the transaction rate
  start_cycle: number; // In which account cycle this interest rate should start.
  start_date: string; // When this interest rate should start.
  updated_at: string; // When this interest rate was updated, format = yyyy-mm-ddThh:mm:ss
  cancelled_at: string; // When this interest rate was cancelled, format = yyyy-mm-ddThh:mm:ss
  created_at: string; // When this interest rate was created, format = yyyy-mm-ddThh:mm:ss
}

export type PismoGetAccountInterestRateFn = (accountId: number) => Observable<number>;

export interface UpdatePismoUserAccessBody {
  RoleAccess?: RoleAccess,
  customerAccessLevel?: CustomerAccessLevel,
}


export interface AllocateUserToPismoAccountData {
  userId: number,
  pismoAccountNumber: number,
  cardType: PismoPhysicalCardType,
  monthlyLimit: number,
  customerAccessLevel: CustomerAccessLevelAndRoleAccessValue,
}

export type AllocateUserToPismoAccountFn = (data: AllocateUserToPismoAccountData) => Observable<ApiResponse>;

export interface InternalAccountsRecord {
  TransactionId: number,
  AccountId: number,
  AccountName: string,
  CreatedAt: string,
  EventDate: string,
  Description: string,
  SoftDescriptor: string,
  Amount: number,
  AutorizationId: number,
  ProcessingCode: string,
  CustomerId: string,
  CardId: number,
  CardName: string,
  CardPrintedName: string,
  MerchantName: string,
  MerchantCity: string,
  MerchantState: string,
  MerchantId: string,
  MerchantType: string,
}

export interface ArrearsReportRecord {
  Id: number,
  AmountDue: number,
  AccountId: number,
  D0: number,
  D30: number,
  D60: number,
  D90: number,
  D120: number,
  D150: number,
  D180: number,
  D210Plus: number,
  DueDate: string,
  LastUpdatedTime: string,
  IsDeleted: boolean,
}

export interface NewArrearsReportRecord {
  AmountDue: number,
  AccountId: number,
  D0: number,
  D30: number,
  D60: number,
  D90: number,
  D120: number,
  D150: number,
  D180: number,
  D210Plus: number,
  DueDate: string,
  IsDeleted: boolean,
}

export interface ArrearsHistoryRecord {
  ArrearsRecordId: number;
  AccountId: number;
  CreatedTime?: string;
  ChangedFields: string;
}

export interface ArrearsReportFileRecord {
  "Account Id": number,
  "Amount Due": number,
  "Current Balance": number,
  "Day 0 - 30": number,
  "Day 30 - 60": number,
  "Day 60 - 90": number,
  "Day 90 - 120": number,
  "Day 120 - 150": number,
  "Day 150 - 180": number,
  "Day 180 - 210": number,
  "Day 210 Plus": number,
  "Due Date": string,
  "Overdue Days": number,
  "Last Updated Time": string,
}

export interface PismoDelinquencyBucket {
  statement_id: number; //Statement ID
  cycle_closing_date: string; //Cycle closing date
  due_date: string; //Due date
  statement_cycle: number; // Statement cycle
  delinquencyBucket_id: number; //Delinquency bucket ID
  calculation_memory: {
    strategy: string;
    // Strategy used to calculate the delinquency amount
    // Defines whether delinquency buckets should be calculated for tracking an account's debt.
    // 0 - Does not calculate
    // 1 - Calculate and amortize buckets from oldest to newest (DEFAULT)
    mad: number; // Minimum amount due
    overdue_amount: number; //Overdue amount
    preivous_bucket_balance: number; //Previous bucket balance
  };
  overdue_amount: number; //Overdue amount
  unexpired_bucket: boolean; // Informs if the bucket is unexpired
  bucket_amount: number; // Bucket amount
  bucket_balance: number; // Bucket balance
  created_at: string; // Bucket creation date in ISO-8601 UTC time
  updated_at: string; // Bucket last updated date in ISO-8601 UTC time
}

export interface PismoGetDelinquencyBucketResponse {
  items: PismoDelinquencyBucket[],
  current_page: number,
  per_page: number,
  total_items: number,
  items_on_page: number,
  pages: number,
  is_last_page: boolean,
}


export interface PismoAccountMappingWithCustomer extends PismoAccountMapping {
  PismoAccountNumber: number,
  SalesforceId: string,
  CustomerType: CustomerType,
  ABN: string,
  ACN?: string | null,
  EntityName: string,
  EntityType: string,
  IsActive: boolean,
}


export type PismoAccountMappingWithCustomerListValue = (PismoAccountMappingWithCustomer) | null;

export interface OverdraftAccountLimitIncreaseDialogData {
  customerId: number;
  overdraftCustomerSfRecordId: string; // sf overdraft customer recordId
  overdraftSfOpportunityStage: string; // sf opportunity stage to show in dropdown
  // // customer: CustomerUser | null;
  // existingPismoAccountMappings: PismoAccountMapping[];
  getOpportunitiesForCustomerFn: GetOpportunitiesForCustomerFn;
  // getPismoProgramDueDate: GetPismoProgramDueDate;
  getAccountDetailsFromSfFn: GetAccountDetailsFromSfFn;
  overdraftAccountLimitIncreaseFn: OverdraftAccountLimitIncreaseFn;
  // createOverdraftAccountFn: CreateOverdraftAccountFn;
  // getPismoProgramsFn: getPismoProgramsFn;
  // syncBankDetailsToSfFn: SyncBankDetailsToSfFn;
  getRbaRateFn: GetRbaRateFn;
  getCustomerFn: GetCustomerFn;
  pismoAccountDetails: PismoGetAccountResponse;
  // getBillerNameFn: GetBillerNameFn;
  // getInstitutionNameFn: GetInstitutionNameFn;
  // getPismoAccountDetailsFn: GetPismoAccountDetailsFn;
}

export interface OverdraftAccountLimitIncreaseDialogResult {
  readyForSubmission: boolean;
}

export interface OverdraftAccountLimitIncreaseBody {
  data: OverdraftAccountLimitIncreaseData;
  pismoAccountId: number;
  pismoAccountDetails: PismoGetAccountResponse;
  opportunityId: string;
  customer: CustomerUser;
}

export interface OverdraftAccountLimitIncreaseResponse {
  facilityEstablishmentFeeStatus: boolean,
  brokerageStatus: boolean,
  documentationFeeStatus: boolean,
  documentationFeeReversalStatus: boolean,
  saveAccountInterestRateStatus: boolean,
  updateAccountLimit: boolean,
  updateAccountMetadata: boolean,
}

export interface OverdraftAccountLimitIncreaseData {
  limitIncrease: number;
  rate: number;
  margin: number;
  docFee: number;
  docFeeFinanced: boolean;
  facilityEstablishmentFee: number;
  brokerage: number;
  customerAccountName: string;
  customerBsb: string;
  customerAccountNumber: string;
  customerFinancialInstitutionName: string;
  brokerName: string;
  brokerAccountName: string;
  brokerBsb: string;
  brokerAccountNumber: string;
  brokerFinancialInstitutionName: string;
}

export type OverdraftAccountLimitIncreaseFn = (body: OverdraftAccountLimitIncreaseBody) => Observable<PayloadApiResponse<OverdraftAccountLimitIncreaseResponse>>;

export interface OverdraftDirectDebitDialogData {
  pismoAccountId: number,
  manualDirectDebitFn: ManualDirectDebitFn,
}

export interface OverdraftDirectDebitDialogResult {
  readyForSubmission: boolean;
}

export interface ManualDirectDebitBody {
  pismoAccountId: number;
  amount: number;
}

export type ManualDirectDebitFn = (body: ManualDirectDebitBody) => Observable<ApiResponse>;

export interface PismoAccountingEventEntry {
  id?: number;
  transaction_id: string,
  amount: number,
  authorization_id: string,
  program_id: number,
  credit_cost_center: string,
  created_at: string,
  credit_account: string,
  description: string,
  accounting_event_id: number,
  debit_account: string,
  debit_cost_center: string,
  authorization_date: string,
  account_id: number,
  installment_number: number,
  event_date: string,
  accounting_entry_id: number,
  entry_type_id: number,
  processing_code: string,
  processing_description: string,
  soft_descriptor: string,
  last_updated_time?: string,
}

export interface PismoGetCardToken {
  key: any,
  token_id: number,
  card_id: number,
  wallet_id: '103',
  account_id: number,
  program_id: number,
  org_id: string,
  payment_app_instance_id: string,
  tracking_id: string,
  status: 'ACTIVE' | 'SUSPENDED' | 'DEACTIVATED'
}


export interface PismoGetCardToken {
  key: any,
  token_id: number,
  card_id: number,
  wallet_id: '103',
  account_id: number,
  program_id: number,
  org_id: string,
  payment_app_instance_id: string,
  tracking_id: string,
  status: 'ACTIVE' | 'SUSPENDED' | 'DEACTIVATED'
}

export interface PismoPerformTokenOperationBody {
  operation_type: 'DELETE' | 'SUSPEND' | 'RESUME' | 'CALL_CENTER_ACTIVATION' | 'CARDHOLDER_STEPUP_APP_TO_APP' | 'TOKEN_DEVICE_BIDING_APPROVE'
  operation_reason: string;
  operator_id?: string;
  activation_code?: string;
}

export type SuspendedCardsAndTokens = { cardId: number, tokens?: number[] }
export interface PismoCardTokenInfo {
  token: PismoGetCardToken,
  network_data: {
    AccountPanSequenceNumber: string,
    CorrelationId: string,
    CurrentStatusCode: string,
    CurrentStatusDateTime: string,
    CurrentStatusDescription: string,
    Device: any,
    DigitizationRequestDateTime: string,
    ExpirationDate: string,
    FinalTokenizationDecision: string,
    LastCommentId: string,
    PaymentAppInstanceId: string,
    PrimaryAccountNumberUniqueReference: string,
    ProvisioningStatusCode: string,
    ProvisioningStatusDescription: string,
    Source: string,
    StorageTechnology: string,
    Suspenders: any,
    TokenActivatedDateTime: string,
    TokenDeletedFromConsumerApp: string,
    TokenRequestorId: string,
    TokenRequestorName: 'APPLE PAY' | string,
    TokenSuffix: string,
    TokenType: string,
    TokenUniqueReference: string,
    WalletId: '103' | '216' // 103 for apple and 216 for google
  }
}

export interface DeviceType {
  [id: string]: string;
}

export interface PismoAccountingEventEntry {
  id?: number;
  transaction_id: string,
  amount: number,
  authorization_id: string,
  program_id: number,
  credit_cost_center: string,
  created_at: string,
  credit_account: string,
  description: string,
  accounting_event_id: number,
  debit_account: string,
  debit_cost_center: string,
  authorization_date: string,
  account_id: number,
  installment_number: number,
  event_date: string,
  accounting_entry_id: number,
  entry_type_id: number,
  processing_code: string,
  processing_description: string,
  soft_descriptor: string,
  last_updated_time?: string,
}

export interface PismoLimitIncrease extends PismoLimitIncreaseBody {
  Id: number,
  LastUpdatedTime: string,
}

export interface PismoLimitIncreaseBody {
  PismoAccountNumber: number,
  OpportunityId: string,
  LimitIncrease: number,
  Brokerage: number,
  DocumentationFee: number,
  FacilityEstablishmentFee: number,
  Margin: number,
  PostBrokerage: boolean,
  PostFacilityEstablishmentFee: boolean,
  PostDocumentationFee: boolean,
  PostDocumentationFeeReversal: boolean,
  SaveAccountInterestRate: boolean,
  LastUpdatedBy: number,
}

export interface CreateAccountNoteBody {
  AccountNumber: number,
  Note: string,
  UserId: number
}

export interface PismoUpdateMonthlyFacilityFeeBody {
  accountId: number;
  monthlyFacilityFeePercentage: number;
  opportunityId: string;
}

export type UpdateMonthlyFacilityFeeFn = (body: PismoUpdateMonthlyFacilityFeeBody) => Observable<ApiResponse>;

export interface PismoCompanyForUser {
  EntityName: string,
  CustomerId: number,
  // Need extra company details 
  ABN?: string;
  ACN?: string;
  AccountName?: string;
  Address?: string; // This can be parsed into an object if needed
  EntityType?: string; // e.g., "Proprietary",

}
export interface PismoCompanyWithAccountsForUser extends PismoCompanyForUser {
  accounts?: PismoGetAccountResponse[]
}

export type StatementFileFormat = 'pdf' | 'csv' | 'ofx';

export interface PismoUpdateAccountParams {
  custom_fields?: Partial<PismoAccountCustomFields>,
  division_id?: number,
  account_name?: string,
  parent_account_id?: number,
}

export type PaymentType = 'OverDraft' | 'BPay' | 'BankTransfer' | 'RedrawFunds' | 'PayByID' | 'PayWithQR' | 'PayWithLink' | 'PayWithCard'
export interface PismoUpdateAmortisedRepaymentBody {
  repayment: number;
  accountId: number;
  comment: string;
}

export type UpdateAmortisedRepaymentFn = (body: PismoUpdateAmortisedRepaymentBody) => Observable<ApiResponse>;

export interface EditAmortisedRepaymentDialogData {
  repayment: number;
  creditLimit: number;
}

export interface EditAmortisedRepaymentDialogResult {
  readyForSubmission: boolean;
  repayment: number;
  comment: string;
}