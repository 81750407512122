import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PpsrService } from 'apps/portal2/src/app/service/ppsr.service'
import {
  ApplicationNotes,
  CreateApplicationNoteFn,
  CustomerAngularEditorComponentValue,
  isInternalUser,
  parseJSON,
  RemoveApplicationNoteByNoteIdFn,
  User, DEFAULT_LIMIT, DEFAULT_OFFSET,
} from '@portal-workspace/grow-shared-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { getUser, PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { FormControl, UntypedFormBuilder, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { GetApplicationAuditLogsFn, AuditLogComponent } from './audit-log.component';
import { GetNotesByApplicationIdFn, NotesComponent } from './notes.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { CustomAngularEditorComponent } from '../custom-angular-editor-component/custom-angular-editor.component';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'asset-notes',
    templateUrl: './asset-notes.component.html',
    styleUrls: ['./asset-notes.component.scss'],
    standalone: true,
    imports: [CustomAngularEditorComponent, FormsModule, ReactiveFormsModule, MatCheckboxModule, MatButtonModule, MatTabsModule, NotesComponent, AuditLogComponent]
})
export class AssetNotesComponent implements OnInit {

  formControlApplicationNotes!: FormControl<CustomerAngularEditorComponentValue>;
  formControlNotesIsVisible : FormControl<boolean>;
  showButton = false;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  isInternalUser = isInternalUser
  loggedInUser: User | null = getUser();
  placeholder = 'New Note';
  @Input({required: false}) applicationNotes: ApplicationNotes[] | [] = [];
  @Input({required: false}) total!: number;
  @Input({required: false}) isAssetUpdated!: Boolean;
  @Input({required: false}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: false}) onlyShowNotes = true;
  @Input({required: false}) showInternalOnlyButton = true;
  @Output() events: EventEmitter<Boolean> = new EventEmitter();
  constructor(private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef ,
    private route: ActivatedRoute,
    private toastService: PortalHotToastService,) {
    this.formControlApplicationNotes = formBuilder.control('', [Validators.required])
    this.formControlNotesIsVisible = formBuilder.control(false, [Validators.required])
  }

  ngOnInit(): void {
    this.reload();
  }

  saveNotes() {
    const applicationId = this.route.snapshot.paramMap.get('applicationId') || '';
    const data = {
      UserId: this.loggedInUser?.UserId!,
      ApplicationId: parseInt(applicationId),
      Note: this.formControlApplicationNotes.value ?? '',
      visibleToInternalStaff: this.formControlNotesIsVisible.value,
      notesFromDscrCalculator: true
    }
    this.createApplicationNoteFn(data).pipe(
      this.toastService.retryableMessage({
        successMessage: 'Notes created',
        errorMessage: 'Failed to retrieve the Notes',
        retryFn: () => {
          this.saveNotes();
        }
      }),
      this.toastService.spinnerObservable(),
      tap(r => {
        this.reload();
      })
    ).subscribe();
    this.showButton = false;
    this.formControlApplicationNotes.reset();
    this.formControlNotesIsVisible.setValue(false);
  }

  show() {
    this.showButton = true;
  }

  cancel() {
    this.formControlApplicationNotes.reset();
    this.showButton = false;
  }

  reload() {
    const applicationId = this.route.snapshot.paramMap.get('applicationId') || '';
    this.getNotesByApplicationIdFn(this.limit, this.offset, parseInt(applicationId)).pipe(
      tap(r => {
        this.total = r.total;
        this.applicationNotes = r.payload;
        this.cd.detectChanges();
      })
    ).subscribe();
  }
}
