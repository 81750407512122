<div class="asset-app-component">
  <mat-card appearance="outlined">
    <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
      <mat-tab label="APPLICANT"><div *ngTemplateOutlet="applicantTab;"></div></mat-tab>
      <mat-tab label={{getOrganisationTypeForTitle(application)}}><div *ngTemplateOutlet="directorsTab"></div></mat-tab>
      <!-- <mat-tab label="DIRECTORS"><div *ngTemplateOutlet="directorsTab"></div></mat-tab> -->
      <mat-tab label="GUARANTORS"><div *ngTemplateOutlet="guarantorTab"></div></mat-tab>
      <mat-tab label="QUOTE"><div *ngTemplateOutlet="quoteTab"></div></mat-tab>
      <mat-tab label="PRIMARY CONTACT"><div *ngTemplateOutlet="primaryContactTab"></div></mat-tab>
      <mat-tab label="REFERENCE"><div *ngTemplateOutlet="referenceTab"></div></mat-tab>
      @if (isInternalUser()) {
        <mat-tab label="PPSR SEARCH"><div *ngTemplateOutlet="PPSRSearchTab"></div></mat-tab>
      }
    </mat-tab-group>
  </mat-card>
</div>


<ng-template #applicantTab>
  <div class="row separator header"
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayoutAlign="space-between center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="center start">
    @if (applicationStatus && applicationStatus==='Under Review') {
      <div class="col-md-3 under-review">
        <status-box type="under-review"></status-box>
      </div>
    } @else {
      <div></div>
    }
    <button mat-flat-button class="inactive ml-2 w-lt-md-100" (click)="onClickApplication()">
      <span class="mdi mdi-content-copy" matPrefix></span>
      Copy This Application
    </button>
  </div>
  <div class="section">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Application ID</div>
          <div body>{{getBrokerApplicationId(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Salesforce ID</div>
          <div body>{{getAppSalesforceId(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Broker name</div>
          <div body>{{getAppInfoBrokerEntityName(application)}}</div>
        </cell>
      </div>
    </div>
    @if (isInternalUser()) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Submitter's name</div>
            <div body>
              <user-selection
                [getFunc]="getUsersInCompanyFn"
                (events)="emitChangeSubmitterEvent($event)"
                [companySalesforceId]="application.AppInfoBrokerSalesforceID"
                [userId]="application.UserId">
              </user-selection>
            </div>
          </cell>
        </div>
        @if (application.AdditionalBrokerCorrespondent) {
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Additional Correspondent's name</div>
              <div body>
                <user-selection
                  [getFunc]="getUsersInCompanyFn"
                  (events)="emitChangeAdditionalCorrespondentEvent($event)"
                  [companySalesforceId]="application.AppInfoBrokerSalesforceID"
                  [userId]="application.AdditionalBrokerCorrespondent.UserId">
                </user-selection>
              </div>
            </cell>
          </div>
        }
      </div>
    }
    <mat-divider [inset]="true"></mat-divider>

    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Interest rate</div>
          <div body>{{getInterestRate(application)  | number : '1.0-2'}}<span class="mdi mdi-percent"></span></div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Amount financed <br>(incl Brokerage)</div>
          <div body><span class="mdi mdi-currency-usd"></span>{{ (paymentChartData.principalAmt || 0) + (paymentChartData.brokerageAmount || 0) | looseCurrency: '':'' }}
        </div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>LVR</div>
        <div body>{{getLVR(application)?((getLVR(application) | percentage)):"N/A"}}</div>
      </cell>
    </div>
    @if (getBrokerFlowId(application)) {
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>BrokerFlow ID </div>
          <div body>{{getBrokerFlowId(application)}}</div>
        </cell>
      </div>
    }
  </div>
  <mat-divider [inset]="true"></mat-divider>
  @if(getProductType(application)){
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Product type</div>
        @if(getProductType(application) === 'FAST DOC'){<div body>Low Doc</div>}@else{
        <div body> {{getProductType(application) | titlecase }} </div>}
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Switch to easy doc</div>
        <div body>{{getSwitchToEasyDoc(application) ? 'Yes' : 'No'}}</div>
      </cell>
    </div>
  </div>
  }
  </div>
  <mat-divider [inset]="true"></mat-divider>

<div class="section">
  <div class="row">
    <div class="col-12">
      <risk-analysis [application]="application"></risk-analysis>
    </div>
  </div>
</div>


<div class="section">
  <div class="row">
    @if (getBrokerContact(application)) {
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Broker Contact</div>
        <div body>{{getBrokerContact(application)}}</div>
      </cell>
    </div>
  }
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Company name</div>
        <div body>{{getCompanyName(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Organisation type</div>
        <div body>{{getOrganisationType(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>ABN</div>
        <div body>{{getAbn(application)}}</div>
      </cell>
    </div>
    @if (getOrganisationType(application)==='Company') {
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>ACN</div>
          <div body>{{getAcn(application)}}</div>
        </cell>
      </div>
    }
  </div>
  <mat-divider [inset]="true"></mat-divider>
  @if (getPreviousBusiness(application)) {
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Previous Business</div>
          <div body>{{getPreviousBusinessName(application)}}</div>
        </cell>
      </div>
      @if (getPreviousBusinessABN(application)) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Previous Business ABN</div>
            <div body>{{getPreviousBusinessABN(application)}}</div>
          </cell>
        </div>
      }
      @if (getPreviousBusinessACN(application)) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Previous Business ACN</div>
            <div body>{{getPreviousBusinessACN(application)}}</div>
          </cell>
        </div>
      }
    </div>
    <mat-divider [inset]="true"></mat-divider>
  }
</div>

<div class="section">
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Finance type</div>
        <div body>{{getFinanceType(application)}}</div>
      </cell>
    </div>
  <!-- </div> -->
  <!-- <div class="row"> -->
  <div class="col-lg-3 col-md-12">
    <cell>
      <div label>Asset category</div>
      <div body>{{getApplicationAssetCategory(application)}}</div>
    </cell>
  </div>
  <div class="col-lg-6 col-md-12">
    <cell>
      <div label>Asset type</div>
      <div body>{{getAssetType(application)}}</div>
    </cell>
  </div>
</div>
<mat-divider [inset]="true"></mat-divider>
<div class="row">
  @if (getAssetMake(application)) {
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Make</div>
        <div body>{{getAssetMake(application)}}</div>
      </cell>
    </div>
  }
  @if (getAssetFamily(application)) {
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Family</div>
        <div body>{{getAssetFamily(application)}}</div>
      </cell>
    </div>
  }
  @if (getAssetVehicle(application)) {
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Model</div>
        <div body>{{getAssetVehicle(application)}}</div>
      </cell>
    </div>
  }
</div>
@if (getAssetVehicle(application)) {
  <mat-divider [inset]="true"></mat-divider>
}
<div class="row">
  @if (getAssetYear(application)) {
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Year</div>
        <div body>{{getAssetYear(application)}}</div>
      </cell>
    </div>
  }
  <div class="col-lg-3 col-md-12">
    <cell>
      <div label>Asset condition</div>
      <div body>{{getAssetCondition(application)}}</div>
    </cell>
  </div>
  @if (getAssetDescription(application)) {
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Description</div>
        <div body>{{getAssetDescription(application)}}</div>
      </cell>
    </div>
  }
</div>
<mat-divider [inset]="true"></mat-divider>
@if (getAssetAvgRetail(application)) {
  <div class="row">
    @if (getAssetAvgRetail(application)) {
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Average Retail</div>
          <div body><span class="mdi mdi-currency-usd"></span>{{getAssetAvgRetail(application) | looseCurrency: '':''}}</div>
        </cell>
      </div>
    }
    @if (getAssetGoodRetail(application)) {
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Good Retail</div>
          <div body><span class="mdi mdi-currency-usd"></span>{{getAssetGoodRetail(application) | looseCurrency: '':''}}</div>
        </cell>
      </div>
    }
  </div>
}
@if (getAssetAvgRetail(application)) {
  <mat-divider [inset]="true"></mat-divider>
}
</div>


<div class="section">
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Invoice amount</div>
        <div body><span class="mdi mdi-currency-usd"></span>{{getInvoiceAmount(application) | looseCurrency:'':''}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Loan term (in months)</div>
        <div body>{{getLoanTerms(application)}}</div>
      </cell>
    </div>
    @if(getRepaymentType(application)){
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Repayment Type</div>
          <div body>{{getRepaymentType(application)}}</div>
        </cell>
      </div>
      }
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Balloon Payment(Residual)</div>
        <div body><span class="mdi mdi-currency-usd"></span>{{getBalloonPayment(application) | looseCurrency:'':''}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Transaction Type </div>
        <div body>{{getTransactionType(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Equifax Score above 600</div>
        <div body>{{getEquifaxScoreAboveThreshold(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Adverse on file</div>
        <div body>{{getAdverseOnFile(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Property owner</div>
        <div body>{{getPropertyOwner(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Brokerage</div>
        <div body><span class="mdi mdi-currency-usd"></span>
      {{getBrokerageAmount(application) ? getBrokerageAmount(application) : getBrokerage(application) * getLoanAmount(application) / 100 | looseCurrency:'':''}}</div>
    </cell>
  </div>
  <div class="col-lg-3 col-md-12">
    <cell>
      <div label>Doc Fee financed</div>
      <div body>{{getDocFeeFinanced(application)}}</div>
    </cell>
  </div>
</div>
<mat-divider [inset]="true"></mat-divider>
@if (getAssetPurpose(application)) {
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Asset Purpose</div>
        <div body>{{getAssetPurpose(application)}}</div>
      </cell>
    </div>
    @if (getAssetPurposeDescription(application)) {
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Asset Purpose Description</div>
          <div body><span title="getAssetPurposeDescription(application)" [innerHTML]="(getAssetPurposeDescription(application) ?? '') | ellipsis"></span></div>
        </cell>
      </div>
    }
    @if (getAssetExistingFinanceCommitment(application)) {
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Asset has an existing finance commitment</div>
          <div body>{{getAssetExistingFinanceCommitment(application)}}</div>
        </cell>
      </div>
    }
  </div>
}
@if (getAssetPurpose(application)) {
  <mat-divider [inset]="true"></mat-divider>
}
<div class="row">
  <div class="col-lg-3 col-md-12">
    <cell>
      <div label>Repayment frequency</div>
      <div body>{{getRepaymentFrequency(application)}}</div>
    </cell>
  </div>
  <div class="col-lg-3 col-md-12">
    <cell>
      <div label>Deposit</div>
      <div body><span class="mdi mdi-currency-usd"></span>{{getDeposit(application) | looseCurrency:'':''}}</div>
    </cell>
  </div>
  <div class="col-lg-3 col-md-12">
    <cell>
      <div label>Loan amount</div>
      <div body><span class="mdi mdi-currency-usd"></span>{{getLoanAmount(application) | looseCurrency: '':''}}</div>
    </cell>
  </div>
</div>
<mat-divider [inset]="true"></mat-divider>
@if (getPropertyOwner(application) === 'No') {
  <div class="row" >
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Any commercial loan in the past 3 years</div>
        <div body>{{getPreviousLoan(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
}
</div>


<div class="section">
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Revenue</div>
        <div body><span class="mdi mdi-currency-usd"></span>{{getRevenue(application) | looseCurrency: '':''}}</div>
      </cell>
    </div>
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Commercial premise</div>
        <div body>{{getOperateInCommercialPremises(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Primary industry</div>
        <div body>{{getApplicationPrimaryIndustry(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Industry sector</div>
        <div body>{{getIndustrySector(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Primary business address</div>
        <div body>{{getPrimaryBusinessAddress(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Business landline</div>
        <div body><!--span>+61</span-->{{getBusinessLandline(application)}}</div>
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>
</div>


@if (getApplicationNotes(application)) {
  <div class="section">
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Application notes</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <cell>
          <div body>{{getApplicationNotes(application)}}</div>
        </cell>
      </div>
    </div>
  </div>
}
<mat-divider [inset]="true"></mat-divider>
</ng-template>

<ng-template #directorsTab>
  <individual-details type="all-non-guarantors" [application]="application"></individual-details>
</ng-template>

<ng-template #guarantorTab>
  <individual-details type="guarantors-only" [application]="application"></individual-details>
</ng-template>

<ng-template #quoteTab>
  <asset-quote [application]="application"
    [amortizationScheduleData]="amortizationScheduleData"
    [repaymentEstimationData]="repaymentEstimationData"
    [paymentChartData]="paymentChartData"
    [amortizationChartData]="amortizationChartData"
    [calculationLog]="calculationLog">
  </asset-quote>
</ng-template>

<ng-template #primaryContactTab>
  <primary-contact-details [application]="application"></primary-contact-details>
</ng-template>

<ng-template #referenceTab>
  <references-details [application]="application"></references-details>
</ng-template>

<ng-template #PPSRSearchTab>
  <div class="row ml-2">
    <div class="mat-h3">PPSR search</div>
  </div>

  <div class="row ml-2">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Company name</div>
        <div body>{{getCompanyName(application)}}</div>
      </cell>
    </div>
    @if (getAbn(application)) {
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>ABN</div>
          <div body>{{getAbn(application)}}</div>
        </cell>
      </div>
    }
    @if (getAcn(application)) {
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>ACN</div>
          <div body>{{getAcn(application)}}</div>
        </cell>
      </div>
    }
    <div class="col-lg-3 col-md-12 mt-3">
      @if (!getAcn(application)) {
        <button mat-flat-button (click)="getSearchGrantors('ABN')">
          <span class="mdi mdi-magnify"></span> PPSR Search
        </button>
      }

      @if (getAcn(application)) {
        <button mat-flat-button (click)="getSearchGrantors('ACN')">
          <span class="mdi mdi-magnify"></span> PPSR Search
        </button>
      }
    </div>
  </div>

  @if (data) {
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="mat-h4-custom">PPSR search result</div>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Account</div>
            <div body>{{grantorDetails && grantorDetails.Account ? grantorDetails.Account : 0}}</div>
          </cell>
        </div>
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>All pap no except</div>
            <div body>{{grantorDetails && grantorDetails.AllPapNoExceptCount ? grantorDetails.AllPapNoExceptCount : '--'}}</div>
          </cell>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-divider [inset]="true"></mat-divider>
        </div>
        <div class="col-lg-6 col-md-12">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Motor vehicle</div>
            <div body>{{grantorDetails && grantorDetails.MotorVehicleCount ? grantorDetails.MotorVehicleCount : '--'}}</div>
          </cell>
        </div>
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Other goods</div>
            <div body>{{grantorDetails && grantorDetails.OtherGoodsCount ? grantorDetails.OtherGoodsCount : '--'}}</div>
          </cell>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-divider [inset]="true"></mat-divider>
        </div>
        <div class="col-lg-6 col-md-12">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Total</div>
            <div body>{{grantorDetails && grantorDetails.Total ? grantorDetails.Total : '--'}}</div>
          </cell>
        </div>
      </div>
    </div>
  }
</ng-template>
