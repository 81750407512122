
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  getAbn,
  getAcn,
  getAdverseOnFile,
  getApplicationNotes,
  getApplicationAssetCategory,
  getAssetCondition,
  getAssetDescription,
  getAssetFamily,
  getAssetMake,
  getAssetType,
  getAssetYear,
  getBrokerage,
  getBrokerApplicationId,
  getBrokerName,
  getBrokerSalesforceId,
  getAppSalesforceId,
  getBusinessLandline,
  getCompanyName,
  getDeposit,
  getDocFeeFinanced,
  getEquifaxScoreAboveThresold,
  getFinanceType,
  getIndustrySector,
  getInterestRate,
  getInvoiceAmount,
  getLoanAmount,
  getAmountFinanced,
  getLoanPurpose,
  getLoanTerms,
  getOperateInCommercialPremises,
  getOrganisationType,
  getPrimaryBusinessAddress,
  getPrimaryIndustry,
  getTransactionType,
  getPropertyOwner,
  getRepaymentFrequency,
  getRevenue,
  getLVR,
  getAssetAvgRetail,
  getAssetGoodRetail,
  getAssetVehicle,
  getPreviousLoan,
  getAppInfoBrokerEntityName,
  getBrokerContact,
  getIdTypes,
  Application,
  getRepaymentType,
} from '@portal-workspace/grow-shared-library';
import {
  AmortisationScheduleEntry,
  AmortizationChartData,
  isInternalUser as utilIsInternalUser, PaymentChartData, RepaymentEstimationEntry,
} from '@portal-workspace/grow-shared-library';
import {
  booleanToYesNo, displaybleDate,
  getApplicationStatus,
  getBrokerageAmount,
  getPaymentType,
  getBalloonPayment
} from '@portal-workspace/grow-shared-library';
import { ConsumerFinanceApplication } from '@portal-workspace/grow-shared-library';
import {GetUsersFunc, UserSelectionComponentEvent,
  applicationToApplicantDob,
  applicationToApplicantEmail,
  applicationToApplicantEmployer,
  applicationToApplicantEmploymentStatus,
  applicationToApplicantFirstName,
  applicationToApplicantGender,
  applicationToApplicantGuarantor,
  applicationToApplicantLastName,
  applicationToApplicantMaritalStatus,
  applicationToApplicantMiddleName,
  applicationToApplicantMobile,
  applicationToApplicantNumberOfDependeants,
  applicationToApplicantPrivacyConsent,
  applicationToApplicantPropertyOwnerWithAddress,
  applicationToApplicantRegularIncome,
  applicationToApplicantResidentialAddress,
  applicationToApplicantTimeInAddress,
  applicationToApplicantTitle,
  getUser,
  applicationToApplicantIdentification,
} from '@portal-workspace/grow-ui-library';
import { LooseCurrencyPipe } from '../../../pipes/loose-currency.pipe';
import {EllipsisPipe} from '../../../pipes/ellipsis.pipe';
import { ReferencesDetailsComponent } from '../references-details.component';
import { ConsumerQuoteComponent } from './consumer-quote.component';
import { IndividualDetailsComponent } from '../individual-details.component';
import { RiskAnalysisComponent } from '../../risk-analysis-component/risk-analysis.component';
import { MatDividerModule } from '@angular/material/divider';
import { UserSelectionComponent } from '../../user-selection-component/user-selection.component';
import { CellComponent } from '../../application-summary-component/cell.component';
import { StatusBoxComponent } from '../../message-box/status-box.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';


@Component({
    selector: 'consumer-app',
    templateUrl: './consumer-app.component.html',
    styleUrls: ['./consumer-app.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatTooltipModule, MatTabsModule, NgTemplateOutlet, StatusBoxComponent, CellComponent, UserSelectionComponent, MatDividerModule, RiskAnalysisComponent, IndividualDetailsComponent, ConsumerQuoteComponent, ReferencesDetailsComponent, LooseCurrencyPipe]
})
export class ConsumerAppComponent {

  getCompanyName = getCompanyName;
  getBrokerApplicationId = getBrokerApplicationId;
  getBrokerSalesforceId = getBrokerSalesforceId;
  getAppSalesforceId = getAppSalesforceId;
  getBrokerName = getBrokerName;
  getAppInfoBrokerEntityName = getAppInfoBrokerEntityName;
  getOrganisationType = getOrganisationType;
  getInterestRate = getInterestRate;
  getLoanAmount = getLoanAmount;
  getAmountFinance = getAmountFinanced;
  getBrokerage = getBrokerage;
  getLVR = getLVR;
  getAbn = getAbn;
  getAcn = getAcn;
  getFinanceType = getFinanceType;
  getApplicationAssetCategory = getApplicationAssetCategory;
  getAssetType = getAssetType;
  getAssetMake = getAssetMake;
  getAssetFamily = getAssetFamily;
  getAssetYear = getAssetYear;
  getAssetDescription = getAssetDescription;
  getAssetVehicle = getAssetVehicle;
  getAssetCondition = getAssetCondition;
  getInvoiceAmount = getInvoiceAmount;
  getLoanTerms = getLoanTerms;
  getTransactionType = getTransactionType;
  getEquifaxScoreAboveThreshold = getEquifaxScoreAboveThresold;
  getAdverseOnFile = getAdverseOnFile;
  getPropertyOwner = getPropertyOwner;
  getDocFeeFinanced = getDocFeeFinanced;
  getRepaymentFrequency = getRepaymentFrequency;
  getDeposit = getDeposit;
  getRevenue = getRevenue;
  getOperateInCommercialPremises = getOperateInCommercialPremises;
  getPrimaryIndustry = getPrimaryIndustry;
  getIndustrySector = getIndustrySector;
  getBusinessLandline = getBusinessLandline;
  getApplicationNotes = getApplicationNotes;
  getPrimaryBusinessAddress = getPrimaryBusinessAddress;
  getAssetAvgRetail = getAssetAvgRetail;
  getAssetGoodRetail = getAssetGoodRetail;
  getLoanPurpose = getLoanPurpose;
  getBrokerContact = getBrokerContact;
  
  getApplicantTitle = applicationToApplicantTitle;
  getApplicantFirstName = applicationToApplicantFirstName;
  getApplicantLastName = applicationToApplicantLastName;
  getApplicantMiddleName = applicationToApplicantMiddleName;
  getApplicantIdentification = applicationToApplicantIdentification;
  getApplicantGender = applicationToApplicantGender;
  getApplicantDob = applicationToApplicantDob;
  getApplicantResidentialAddress = applicationToApplicantResidentialAddress;
  getApplicantTimeInAddress = applicationToApplicantTimeInAddress;
  getApplicantPropertyOwnerWithAddress = applicationToApplicantPropertyOwnerWithAddress;
  getApplicantPrivacyConsent = applicationToApplicantPrivacyConsent;
  getApplicantEmail = applicationToApplicantEmail;
  getApplicantMobile = applicationToApplicantMobile;
  getApplicantMaritalStatus = applicationToApplicantMaritalStatus;
  getApplicantNumberOfDependants = applicationToApplicantNumberOfDependeants;
  getApplicantEmploymentStatus = applicationToApplicantEmploymentStatus;
  getApplicantRegularIncome = applicationToApplicantRegularIncome;
  getApplicantEmployer = applicationToApplicantEmployer;
  getApplicantGuarantor = applicationToApplicantGuarantor;
  getBrokerageAmount = getBrokerageAmount;
  getPaymentType = getPaymentType;
  getBalloonPayment = getBalloonPayment;
  getRepaymentType = getRepaymentType;
  getPreviousLoan = getPreviousLoan;

  displayableDate = displaybleDate;
  booleanToYesNo = booleanToYesNo;

  idTypes = getIdTypes();
  idNumberLabel = 'ID Number'

  @Input({required: true}) application!: ConsumerFinanceApplication;
  @Input({required: true}) getUsersInCompanyFn!: GetUsersFunc;
  @Input({required: false}) amortizationScheduleData!: AmortisationScheduleEntry[];
  @Input({required: true}) amortizationChartData!: AmortizationChartData;
  @Input({required: true}) paymentChartData!: PaymentChartData;
  @Input({required: false}) repaymentEstimationData!: RepaymentEstimationEntry[];
  @Input({required: false}) calculationLog?: {log: string, rate?: number}[];
  @Output() events = new EventEmitter<UserSelectionComponentEvent & {type: 'submitter' | 'additional-correspondent'}>();

  applicationStatus: string | null = null;

  ngOnInit(): void {
    this.applicationStatus = getApplicationStatus(this.application);
    this.updateIdNumberLabel();
  }

  isInternalUser() {
    return utilIsInternalUser(getUser())
  }

  emitChangeSubmitterEvent(event: UserSelectionComponentEvent) {
    this.events.emit({
      ...event,
      type: 'submitter',
    });
  }

  emitChangeAdditionalCorrespondentEvent(event: UserSelectionComponentEvent) {
    this.events.emit({
      ...event,
      type: 'additional-correspondent',
    });
  }

  idTypeValueToLabel(application: Application) {
    const value = this.getApplicantIdentification(application)?.idType ?? ''
    return this.idTypes.find(type => type.value === value)?.label || '';
  }

  updateIdNumberLabel() {
    const value = this.getApplicantIdentification(this.application)?.idType ?? ''
    switch (value) {
      case 'drivers_licence':
        this.idNumberLabel = 'Licence Number';
        break;
      case 'passport':
        this.idNumberLabel = 'Passport Number';
        break;
      default:
        this.idNumberLabel = 'ID Number';
    }
  }
}
